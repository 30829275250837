import React, { useEffect, useState } from 'react'
import DetailsTab from './detailsTab'
import AAdhaarImage from './images/aadharcircle.png'
import LocationsImage from './images/locationCircle.png'
import Bankimage1 from './images/bankImage1.png'
import Bankimage2 from './images/bankImage2.png'
import Bankimage3 from './images/bankImage3.png'
import salaryCheck1 from './images/Ellipse 419 (5).png'
import salaryCheck2 from './images/Ellipse 419 (6).png'
import CrifImage from './images/Ellipse 419 (7).png'
import OrganisationImage from './images/Ellipse 419 (8).png'
import DeviceImage from './images/Ellipse 419 (10).png'
import MobileImage from './images/Ellipse 419 (11).png'
import RupeeImage from './images/ruppecircle.png'
import LegalSms from './images/legalsms.png'
import ScoringDetailsviewMain from './scoring/scoringviewMain'
import EditScoring from './scoring/editmainScoring'
import { ROLE } from '../../../../utils/Constant'
import { saveKycBoxDataApi, getNewInternalDedupeBoxData, getkycBoxApi, getNotesnew, getPersonalAnalysisReport, getSmsAnalysisStatus, kycAnalysisSideApi, similarProfileData, creditDashboardApi, getCrifAnalysisStatus,getCrifAnalysisStatusExperian,getReportHitByAPI, getOgDetailsApi, saveDedupeDataDataApi, getOrganisationAnalysisData, updateLatestStatementOrNot, UpdateNewUserStatus, getAnalysisDataApiNew, organizationVerifyByApi } from '../Analysis/analysisactionCreator'
import moment from 'moment'
import RedCross from './images/Vector (27).png'
import GreenCross from './images/Group 469 (4).png'
import CreditPopup from './categoriesPopup'
import Skeleton from 'react-loading-skeleton'
import { APIS } from '../../../../utils/api-factory'
import SpinnerLoader from '../../../presentationals/Loader/Loader.component'
import ObligationCalculatorPopup from './scoring/ObligationCalculatorPopup'
const CreditDashboard = ({ statementSource, allDetail, admin, internalViewState, changeViewState }) => {
    const [kycdata, setKycDataSide] = useState('')
    const [year, setYear] = useState('')
    const [month, setMonth] = useState('')
    const [locationData, setLocationData] = useState('')
    const [similarProfile, setsimilarProfile] = useState('')
    const [smsAnalysisData, setSmsData] = useState('')
    const [activityData, setActivityData] = useState('')
    const [dashboardData, setDashboardData] = useState('')
    const [salaryDay, setSalaryDay] = useState('')
    const [crifDataDashboard, setCrifData] = useState('')
    const [parseData, setParseData] = useState('')
    const [orgData, setOrganisationData] = useState('')
    const [similarProfileBlank, setsimilarProfileBLank] = useState('')
    const [similarFound, setSimilarFound] = useState(false)
    const [categoriesData, setcategoriesData] = useState(false)
    const [newKycData, setKycDataNew] = useState('')
    const [kycDataToSave, setkycDataToSave] = useState({})
    const [internalDedupe, setNewInternalDedupeData] = useState('')
    const [rejectReasons, setrejectReason] = useState([]);
    const [internaDedupeDataToSave, setinternaDedupeDataToSave] = useState({});
    const [newOrganisationData, setnewOrganisationData] = useState();
    const [latestStatement, setlatestStatement] = useState('');
    const [userStatus, setuserStatus] = useState('');
    const [analysisDataNew, setAnalysisDataNew] = useState('');
    const [repeatUser, setRepeatUser] = useState(false);
    const [valueForOrganization, setValueForOrganization] = useState('');
    const [loaderState, setLoader] = useState(false);
    const [obligationPopup,setObligationPopup] =useState(false)
    const [obligationAPiAgain,setObligationAPiAgain] =useState(false)

    const handleAnalysistabs = () => {
        getKycData()
        personalAnalysisdata()
        getSimilarMatch()
        getSmsAnalysisStatusData()
        getActivityData()
        dashboard()
        crifDataApi()
        getOrganisationData()
        kycDataNew()
        getanalysisData()
        RequestAPiAgain();
        fetch(APIS.CHECK_REPEAT_USER + allDetail.userId)
            .then(res => res.json())
            .then(res => {
                setRepeatUser(res.repeatUser)
            })

            
    }

    const RequestAPiAgain =()=>{
        setObligationAPiAgain(true)
    }

    useEffect(() => {
        getKycData()
        personalAnalysisdata()
        getSimilarMatch()
        getSmsAnalysisStatusData()
        getActivityData()

        dashboard()

        crifDataApi()
        getOrganisationData()
        kycDataNew()
        getanalysisData()
        fetch(APIS.CHECK_REPEAT_USER + allDetail.userId)
            .then(res => res.json())
            .then(res => {
                setRepeatUser(res.repeatUser)
            })
        // internalDedupeApiDataNew()
        // organisationData()
    }, [])
    const savevalueForOrganization = () => {
        setLoader(true)
        organizationVerifyByApi(allDetail.userId, valueForOrganization, callBack => {
            setLoader(false)


        })
    }
    const internalDedupeApiDataNew = () => {
        getNewInternalDedupeBoxData(allDetail.userId, allDetail.loanId, callBack => {
            setNewInternalDedupeData(callBack.userLoanInternalDedupeAnalysis)
            let changeData = Object.assign({}, internaDedupeDataToSave)
            changeData.userId = allDetail.userId
            changeData.loanId = allDetail.loanId
            changeData.underwriterEmail = admin.emailId
            changeData.result = callBack.userLoanInternalDedupeAnalysis.result
            changeData.duplicatePrimaryNumberDecision = callBack.userLoanInternalDedupeAnalysis.duplicatePrimaryNumberDecision
            changeData.duplicatePrimaryEmailDecision = callBack.userLoanInternalDedupeAnalysis.duplicatePrimaryEmailDecision
            changeData.duplicateAddressDecision = callBack.userLoanInternalDedupeAnalysis.duplicateAddressDecision
            changeData.duplicateAltNumberDecision = callBack.userLoanInternalDedupeAnalysis.duplicateAltNumberDecision
            changeData.duplicateOfficialEmailDecision = callBack.userLoanInternalDedupeAnalysis.duplicateOfficialEmailDecision
            setinternaDedupeDataToSave(changeData)
        })
    }
    const organisationData = () => {
        // getOrganisationAnalysisData(allDetail.userId,allDetail.applicationId,callBack=>{
        //     console.log(callBack)
        // })
        let data = {
            "userApplicationOrganisationAnalysis": {
                "userId": "UKrhIZxsmoAz1UEL53YIfDAAiQjRs5B7",
                "applicationId": "23057118",
                "orgname": "VIRCHOW BIOTECH PRIVATE LIMITED",
                "techResult": "Positive",
                "underwriterResult": "underwriterResult underwriterResult",
                "underwriterDecision": "underwriterDecision underwriterDecision",
                "underwriterEmail": "test@gmmail.com"
            }
        }
        setnewOrganisationData(data.userApplicationOrganisationAnalysis)
    }
    const kycDataNew = () => {
        getkycBoxApi(allDetail.userId, allDetail.applicationId, allDetail.loanId, callback => {
            // console.log(callback)
            setKycDataNew(callback.userApplicationKYCAnalysis)
            let changeData = Object.assign({}, kycDataToSave)
            changeData.aadhaarLinkedMobile = callback.userApplicationKYCAnalysis.aadhaarLinkedMobile
            changeData.result = callback.userApplicationKYCAnalysis.result
            changeData.underwriterEmail = admin.emailId
            changeData.userId = allDetail.userId
            changeData.applicationId = allDetail.applicationId
            changeData.loanId = allDetail.loanId

            setkycDataToSave(changeData)
        })
    }
    const checkString = (str) => {
        // Regular expression to match the string "[name]"
        // ^ asserts the start of the string
        // \[ matches the literal '[' character
        // name matches the literal string 'name'
        // \] matches the literal ']' character
        // $ asserts the end of the string
        const regex = /^\[name]$/;

        // Test the string against the regular expression
        return regex.test(str);
    };

    const getOrganisationData = () => {
        getOgDetailsApi(allDetail.userId, callBack => {
            setOrganisationData(callBack)
            setValueForOrganization(callBack.orgEmailVerfiedVia)
        })
    }
    const crifDataApi = () => {
        console.log('crifDataApicalled')
        getReportHitByAPI(allDetail.userId,(callback)=>{
            if(callback === "experian"){
                getCrifAnalysisStatusExperian(allDetail.userId, allDetail.loanId, callback => {
                    if (callback.finBoxDecision !== null) {
                        setCrifData(callback)
                        // if (JSON.parse(callback.finBoxDecision.progress) && JSON.parse(callback.finBoxDecision.progress)['INDV-REPORT-FILE'] && JSON.parse(callback.finBoxDecision.progress)['INDV-REPORT-FILE']['INDV-REPORTS'] && JSON.parse(callback.finBoxDecision.progress)['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] && JSON.parse(callback.finBoxDecision.progress)['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['RESPONSES'] && JSON.parse(callback.finBoxDecision.progress)['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['RESPONSES']['RESPONSE']) {
                        //     setParseData(JSON.parse(callback.finBoxDecision.progress))
                        // }
                        const parser = new DOMParser();
                        const doc = parser.parseFromString(callback.finBoxDecision.progress, 'text/html');
                        console.log(doc)
                        const headerElements = doc.querySelectorAll('.dataHeader1');
                        const valueElements = doc.querySelectorAll('.dataValue1');
        
                        for (let i = 0; i < headerElements.length; i++) {
                            const headerElement = headerElements[i];
                            const valueElement = valueElements[i];
        
                            if (headerElement.textContent.trim() === 'Date of Request:') {
                                const dateOfRequestValue = valueElement.textContent.trim();
                                setParseData(dateOfRequestValue);
                            }
                        }
                    } else {
        
                    }
                })
            }else{
  getCrifAnalysisStatus(allDetail.userId, allDetail.loanId, callback => {
            if (callback.finBoxDecision !== null) {
                setCrifData(callback)
                // if (JSON.parse(callback.finBoxDecision.progress) && JSON.parse(callback.finBoxDecision.progress)['INDV-REPORT-FILE'] && JSON.parse(callback.finBoxDecision.progress)['INDV-REPORT-FILE']['INDV-REPORTS'] && JSON.parse(callback.finBoxDecision.progress)['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] && JSON.parse(callback.finBoxDecision.progress)['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['RESPONSES'] && JSON.parse(callback.finBoxDecision.progress)['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['RESPONSES']['RESPONSE']) {
                //     setParseData(JSON.parse(callback.finBoxDecision.progress))
                // }
                const parser = new DOMParser();
                const doc = parser.parseFromString(callback.finBoxDecision.progress, 'text/html');
                console.log(doc)
                const headerElements = doc.querySelectorAll('.dataHeader1');
                const valueElements = doc.querySelectorAll('.dataValue1');

                for (let i = 0; i < headerElements.length; i++) {
                    const headerElement = headerElements[i];
                    const valueElement = valueElements[i];

                    if (headerElement.textContent.trim() === 'Date of Request:') {
                        const dateOfRequestValue = valueElement.textContent.trim();
                        setParseData(dateOfRequestValue);
                    }
                }
            } else {

            }
        })
            }
        })  
    }
    const getSmsAnalysisStatusData = () => {
        getSmsAnalysisStatus(allDetail.userId, callback => {
            setSmsData(callback)
        })
    }
    const getSimilarMatch = () => {
        similarProfileData(allDetail.loanId, callback => {
            if (callback.matchBy !== "[]") {
                setsimilarProfile(callback.matchBy)
                let checkname = checkString(callback.matchBy)
                setSimilarFound(checkname)

            }
        })
    }
    const personalAnalysisdata = () => {
        getPersonalAnalysisReport(allDetail.userId, allDetail.loanId, callback => {
            setLocationData(callback)
        })
    }
    const getKycData = () => {
        kycAnalysisSideApi(allDetail.userId, callBack => {
            setKycDataSide(callBack)
            const startDate = moment(callBack.aadharXmlDetail.date);
            const endDate = moment(); // current date
            const yearsDiff = endDate.diff(startDate, 'years');
            startDate.add(yearsDiff, 'years'); // Move the start date to the current year
            setYear(yearsDiff);

            const monthsDiff = endDate.diff(startDate, 'months');
            setMonth(monthsDiff);
        })
    }
    const getActivityData = () => {
        getNotesnew(allDetail.loanId, allDetail.userId, '', callBack => {
            setActivityData(callBack)
        })
    }
    const dashboard = () => {
        if (statementSource !== '') {
            let source = statementSource.digitap === true ? 'digitap' : ''
            creditDashboardApi(allDetail.userId, allDetail.loanId, source, callBack => {
                setDashboardData(callBack)
                if (Object.keys(callBack).length > 0 && callBack.salaryDayBankStatement !== null && callBack.salaryDayBankStatement !== undefined) {
                    let date = addSuffixToDay(callBack.salaryDayBankStatement)
                    setSalaryDay(date)
                }
            })
        }
    }
    const addSuffixToDay = (date) => {
        const day = date;
        let suffix;

        if (day >= 11 && day <= 13) {
            suffix = "th";
        } else {
            switch (day % 10) {
                case 1:
                    suffix = "st";
                    break;
                case 2:
                    suffix = "nd";
                    break;
                case 3:
                    suffix = "rd";
                    break;
                default:
                    suffix = "th";
            }
        }

        return day + suffix;
    }
    const closePup = () => {
        setcategoriesData(false)
    }
    const saveKycBoxData = () => {
        saveKycBoxDataApi(kycDataToSave, callBack => {
        })
    }
    const kycChangeHandler = (e, type) => {
        let changeData = Object.assign({}, kycDataToSave)
        changeData[type] = e.target.value
        setkycDataToSave(changeData)

    }
    const Rejectreasoninternaldedue = () => {

    }
    const dedupeChangeHandler = (e, type) => {
        console.log(e.target.value, type)
        let changeData = Object.assign({}, internaDedupeDataToSave)
        changeData[type] = e.target.value
        setinternaDedupeDataToSave(changeData)


    }
    const saveDedupeData = () => {
        console.log(internaDedupeDataToSave)
        saveDedupeDataDataApi(internaDedupeDataToSave, callBack => {
        })
    }
    const saveLatestStatement = () => {
        let data = {
            userId: allDetail.userId,
            loanId: allDetail.loanId,
            status: latestStatement
        }

        updateLatestStatementOrNot(data, callback => {
            console.log(callback)
        })

    }
    const UpdateNewUser = () => {
        let data = {
            userId: allDetail.userId,
            loanId: allDetail.loanId,
            status: userStatus
        }
        UpdateNewUserStatus(data, callback => {
            console.log(callback)
        })

    }
    const getanalysisData = () => {
        getAnalysisDataApiNew(allDetail.userId, callback => {
            if (callback) {
                setAnalysisDataNew(callback)
                setuserStatus(callback.data.treatedAsNewUser)
                setlatestStatement(callback.data.bankStatementLatest)
            }


        })
    }

    const handleShowObligationPopup =(type)=>{
        setObligationPopup(type)
    }


    return (
        <div className='container-fluid credit-module  ' style={{ marginBottom: '50px' }}>
            {loaderState ?
                <SpinnerLoader />
                : ""}
            <div className='row'>
                <div className='col-sm-6 col-xs-12'>
                    <h3><b>Dashboard</b></h3>
                </div>
                {repeatUser ?
                    <div className='col-sm-6 col-xs-12'>
                        <div className='' style={{ marginLeft: '', float: 'right', marginRight: '20px' }}>If Treated as a new customer
                            <p style={{ fontWeight: '700', color: '#00C851', display: 'flex' }}>
                                <select value={userStatus}
                                //  disabled={!newKycData !== '' && newKycData.editable !== undefined ? !newKycData.editable : ""}
                                disabled={(allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110') ? false : true}
                                  onChange={(e) => setuserStatus(e.target.value)} className='form-control' style={{ width: '100px', padding: '5px' }}>
                                    <option value={''}>Select</option>
                                    <option value={'true'}>Yes</option>
                                    <option value={'false'}>No</option>

                                </select>
                                &nbsp;&nbsp;{userStatus !== '' && userStatus !== null ? <button className='btn btn-success' style={{ padding: '3px 14px' }} onClick={() => UpdateNewUser()}>Save</button> : ""}
                            </p>
                        </div>

                    </div>
                    : ""}
            </div>
            <div className='row  animated fadeIn'>
                <div className='col-sm-3 col-xs-12'>
                    <div className='credit-module-card'>
                        <div className='credit-head'>
                            <h5>KYC Analysis</h5>
                        </div>
                        {newKycData !== '' ?
                            <div className='credit-body'>
                                {/* <div style={{ background: '#D7FBEA', display: 'flex', width: '100%', padding: '10px' }}>
                                <div className='' style={{ width: '50%' }}>Result</div>
                                <div className='' style={{ textAlign: 'end', fontWeight: '700', color: '#00C851', width: '50%' }}><b>Approved</b></div>
                            </div>
                            <div style={{ background: '#F1F7FF', display: 'flex', width: '100%', padding: '10px', borderTop: '1px solid rgba(0, 0, 0, 0.25)' }}>
                                <div className='' style={{ width: '50%' }}>{kycdata.aadharDecision}<br /><span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px' }}>{year}&nbsp;Years&nbsp;{month}&nbsp;Months</span></div>
                            </div> */}
                                <div style={{ display: 'flex', width: '100%', padding: '10px', borderBottom: '1px solid #F1F7FF' }}>
                                    <div className='' style={{ width: '15%' }}><img src={AAdhaarImage} width={'100%'} /></div>
                                    <div className='' style={{ width: '85%', marginLeft: '20px' }}>Aadhaar Verification
                                        <span style={{ fontWeight: '700', color: '#00C851' }}> {newKycData && newKycData.aadhaarVerified ? <div className="green-bg-kyc">
                                        Verified By SETU</div> :
                                            // {newKycData.aadhaarComment}</div> :
                                            <div className="green-bg-kyc" style={{ color: '#FF5C5D' }}>
                                                {newKycData.aadhaarComment}</div>
                                        }   </span>
                                        {newKycData && newKycData.aadhaarVerificationDate !== '' && newKycData.aadhaarVerificationDate !== null && newKycData.aadhaarVerificationDate !== undefined ?
                                            <span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px' }}>Verified on - {(newKycData.aadhaarVerificationDate)}</span>
                                            : ""}
                                        <br />
                                        {newKycData && newKycData.aadhaarVerificationAge !== '' && newKycData.aadhaarVerificationAge !== null && newKycData.aadhaarVerificationAge !== undefined ?
                                            <span style={{ color: 'dodgerblue', fontSize: '11px' }}>Verification age - {(newKycData.aadhaarVerificationAge)}</span>
                                            : ""}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', width: '100%', padding: '10px', borderBottom: '1px solid #F1F7FF' }}>
                                    <div className='' style={{ width: '15%' }}><img src={AAdhaarImage} width={'100%'} /></div>
                                    <div className='' style={{ width: '85%', marginLeft: '20px' }}>PAN Verification <br /><span style={{ fontWeight: '700', color: '#00C851' }}>{newKycData && newKycData.panVerified ? <div className="green-bg-kyc">
                                    Verified By SETU</div> :
                                        // {newKycData.panComment}</div> :
                                        <div className="green-bg-kyc" style={{ color: '#FF5C5D' }}>
                                            {newKycData.panComment}</div>
                                    }
                                    </span>

                                        <span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px' }}>Verified on - {(newKycData.panVerificationDate ? newKycData.panVerificationDate : 'NA')}</span>

                                    </div>
                                </div>
                                <div style={{ display: 'flex', width: '100%', padding: '10px', borderBottom: '1px solid #F1F7FF' }}>
                                    <div className='' style={{ width: '15%' }}><img src={AAdhaarImage} width={'100%'} /></div>
                                    <div className='' style={{ width: '85%', marginLeft: '20px' }}>AML Check
                                        {/* <span style={{ fontWeight: '700' }}>
                                            {newKycData && newKycData.amlDecision !== null ?
                                                <div className="green-bg-kyc" style={{ color: newKycData.amlDecision.includes('Not') ? '#FF5C5D' : '#00C851' }}>
                                                    {newKycData.amlDecision}
                                                </div>
                                                : "NA"}
                                        </span> */}
                                        <span style={{ fontWeight: '700' }}>
                                            {newKycData && newKycData.amlDecisionMaker !== null && newKycData.amlDecisionMaker !== undefined ?
                                                <div className="green-bg-kyc" style={{ color: newKycData.amlDecisionMaker.includes('Not') ? '#FF5C5D' : '#00C851' }}>
                                                    {newKycData.amlDecisionMaker}
                                                </div>
                                                : "NA"}
                                        </span>
                                        <span style={{ fontSize: '11px' }}>Action Suggested - <span style={{ color: newKycData.amlDecision === 'Proceed' ? '#00C851' : newKycData.amlDecision === 'Stop' ? 'red' : '#ebb222' }}>{(newKycData.amlDecision ? newKycData.amlDecision : 'NA')}</span></span>


                                    </div>
                                </div>
                                <div style={{ display: 'flex', width: '100%', padding: '10px', borderBottom: '1px solid #F1F7FF' }}>
                                    <div className='' style={{ width: '15%' }}><img src={AAdhaarImage} width={'100%'} /></div>
                                    <div className='' style={{ width: '85%', marginLeft: '20px' }}>Aadhar Linked Mobile number

                                        <p style={{ fontWeight: '700', color: '#00C851' }}>
                                            <select disabled={!newKycData.editable} onChange={(e) => kycChangeHandler(e, 'aadhaarLinkedMobile')} value={kycDataToSave.aadhaarLinkedMobile} className='form-control' style={{ width: '100px', padding: '5px' }}>
                                                <option value={''}>Select</option>
                                                <option value={'yes'}>Yes</option>
                                                <option value={'No'}>No</option>

                                            </select>
                                            {/* <button className='btn btn-success' style={{padding:'3px 14px'}}>Yes</button>&nbsp;<button style={{padding:'3px 14px'}} className='btn btn-danger'>No</button> */}
                                        </p>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', width: '100%', padding: '10px', borderBottom: '1px solid #F1F7FF' }}>
                                    <div className='' style={{ width: '15%' }}><img src={AAdhaarImage} width={'100%'} /></div>
                                    <div className='' style={{ width: '85%', marginLeft: '20px' }}>Aadhaar taken from Digilocker/ XML

                                        <p style={{ fontWeight: '700' }}>
                                            {newKycData.aadhaarSource ? newKycData.aadhaarSource : 'NA'}
                                        </p>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', width: '100%', padding: '10px', borderBottom: '1px solid #F1F7FF' }}>
                                    <div className='' style={{ width: '15%' }}><img src={AAdhaarImage} width={'100%'} /></div>
                                    <div className='' style={{ width: '85%', marginLeft: '20px' }}>Photo Match
                                        <br />
                                        <span style={{ fontSize: '14px', color: newKycData.photoMatchDecisionMaker === 'Karza' ? '#00C851' : '' }}> <span >{newKycData.photoMatchDecisionMaker}</span></span><br />
                                        <span style={{ fontSize: '14px' }}>Match Percentage -  <span >{(newKycData.photoMatchPercent)}%</span></span>
                                        <p style={{ fontWeight: '700' }}>
                                            <span style={{ fontSize: '11px' }}>Tech Decision -  <span style={{ color: newKycData.photoMatchDecision === 'Rejected' ? '#FF5C5D' : '#00C851' }}>{newKycData.photoMatchDecision}</span></span>
                                        </p>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', width: '100%', padding: '10px', borderBottom: '1px solid #F1F7FF' }}>
                                    <div className='' style={{ width: '15%' }}><img src={LocationsImage} width={'100%'} /></div>
                                    <div className='' style={{ width: '85%', marginLeft: '20px' }}> Latitude <span style={{ fontSize: '10px' }}>{'(location to current pincode match)'}</span> <br />
                                        <p style={{ fontWeight: '700', color: newKycData.locationMatchDecision !== null ? newKycData.locationMatchDecision.includes('Not') ? '#FF5C5D' : '#00C851' : '' }}>
                                            {newKycData && newKycData.locationMatchDecision ?
                                                <div className="green-bg-kyc">
                                                    {newKycData.locationMatchDecision}</div> : 'NA'}
                                        </p>
                                        {/* <span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px' }}>Verified on - 20/09/2023</span> */}
                                    </div>
                                </div>
                                {/* <div style={{ display: 'flex', width: '100%', padding: '10px', borderBottom: '1px solid #F1F7FF' }}>
                                <div className='' style={{ width: '15%' }}><img src={AAdhaarImage} width={'100%'} /></div>
                                <div className='' style={{ width: '85%', marginLeft: '20px' }}> Selfie   <br />
                                    <p style={{ fontWeight: '700', color: '#00C851' }}>   {kycdata && kycdata.locationMatchedAadhar ? <div className="green-bg-kyc">
                                        {kycdata.locationDecision}</div> :
                                        <div className="green-bg-kyc" style={{ color: '#FF5C5D' }}>
                                            {kycdata.locationDecision}</div>
                                    }</p>
                                </div>
                            </div> */}
                                <div style={{ display: 'flex', width: '100%', padding: '10px', borderBottom: '1px solid #F1F7FF' }}>
                                    <div className='' style={{ width: '15%' }}><img src={AAdhaarImage} width={'100%'} /></div>
                                    <div className='' style={{ width: '85%', marginLeft: '20px' }}>Final Result

                                        <p style={{ fontWeight: '700', color: '#00C851' }}>
                                            <select disabled={!newKycData.editable} onChange={(e) => kycChangeHandler(e, 'result')} value={kycDataToSave.result} className='form-control' style={{ width: '100px', padding: '5px' }}>
                                                <option>Select</option>
                                                <option value={'pass'}>Pass</option>
                                                <option value={'fail'}>Fail</option>

                                            </select>
                                            {/* <button className='btn btn-success' style={{padding:'3px 14px'}}>Yes</button>&nbsp;<button style={{padding:'3px 14px'}} className='btn btn-danger'>No</button> */}
                                        </p>
                                    </div>
                                </div>
                                {newKycData.editable === true ?
                                    <div className='text-center' style={{ marginTop: '5px', marginBottom: '5px', marginRight: '10px' }}>
                                        <button className='btn btn-success' style={{ padding: '3px 14px', width: '80%' }} onClick={() => saveKycBoxData()}>Save</button>
                                    </div> : ""}
                                {/* <div style={{ display: 'flex', width: '100%', padding: '10px' }}>
                                <div className='' style={{ width: '15%' }}><img src={LocationsImage} width={'100%'} /></div>
                                <div className='' style={{ width: '85%', marginLeft: '20px', marginTop: '10px' }}> Address Match </div>
                            </div>
                            <table className='crif-table-small' style={{ borderRadius: '10px' }}>
                                <thead>
                                    <tr>
                                        <th style={{ borderTopLeftRadius: '6px' }}>CRIF to Current Address Match (%)</th>
                                        <td style={{ borderTopRightRadius: '6px', borderLeft: 'none' }}>{locationData.crifAddressMatchPerc ? (locationData.crifAddressMatchPerc).toFixed(2) : ""}{locationData.crifAddressMatchPerc ? '%' : ""}</td>
                                    </tr>
                                    <tr>
                                        <th style={{ borderBottomLeftRadius: '6px', borderTop: 'none' }} >Live Location to Current Address Pincode Match</th>
                                        <td style={{ borderBottomRightRadius: '6px', borderTop: 'none', borderLeft: 'none', textAlign: 'center' }}>{locationData.locationPincodeFlag ? <img src={GreenCross} width={'18px'} /> : <img src={RedCross} width={'18px'} />}</td>
                                    </tr>
                                </thead>
                            </table> */}

                                <br />



                            </div>
                            : <div className='credit-body' style={{ minHeight: '600px' }}>
                                <Skeleton height={50} count={10} />
                            </div>}
                    </div>
                    <div className='credit-module-card'>
                        <div className='credit-head'>
                            <h5>Bank Statement Analysis</h5>
                        </div>
                        <div className='credit-body'>
                            <div style={{ background: dashboardData && dashboardData.finBitDecision ? '#D7FBEA' : '#FEECEB', display: 'flex', width: '100%', padding: '10px' }}>
                                <div className='' style={{ width: '50%' }}>Result</div>
                                <div className='' style={{ textAlign: 'end', color: dashboardData && dashboardData.finBitDecision ? '#00C851' : "#FF4444", width: '50%' }}><b>{dashboardData && dashboardData.finBitDecision ? dashboardData.finBitDecision : ""}</b></div>
                            </div>

                            <div style={{ display: 'flex', width: '100%', padding: '10px', borderBottom: '1px solid #F1F7FF' }}>
                                <div className='' style={{ width: '15%' }}><img src={Bankimage1} width={'100%'} /></div>
                                <div className='' style={{ width: '85%', marginLeft: '20px' }}>Counts of Fraud Record <br /><span >{dashboardData && dashboardData.fraudAnalyticsTransactionCount ? dashboardData.fraudAnalyticsTransactionCount : "0"} </span><br /><span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px' }}>Fetched on - {dashboardData && dashboardData ? moment(dashboardData.finbitDate).format('DD-MM-YYYY') : "NA"}</span></div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', padding: '10px', borderBottom: '1px solid #F1F7FF' }}>
                                <div className='' style={{ width: '15%' }}><img src={Bankimage1} width={'100%'} /></div>
                                <div className='' style={{ width: '85%', marginLeft: '20px' }}>Counts of Bounces<br /><span >{dashboardData && dashboardData.bounceTransactionsCount ? dashboardData.bounceTransactionsCount : "0"} </span><br /><span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px' }}>Fetched on - {dashboardData && dashboardData ? moment(dashboardData.finbitDate).format('DD-MM-YYYY') : "NA"}</span></div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', padding: '10px', borderBottom: '1px solid #F1F7FF' }}>
                                <div className='' style={{ width: '15%' }}><img src={Bankimage2} width={'100%'} /></div>
                                <div className='' style={{ width: '85%', marginLeft: '20px' }}> Name Match with Customer Bank Statement <br />{dashboardData && dashboardData.bankNameMatch ? <span style={{ fontWeight: '700', color: '#00C851' }}>MATCHED </span> : ""}{dashboardData && dashboardData.bankNameMatch === false ? <span style={{ fontWeight: '700', color: '#FF4444' }}>Not Matched </span> : ""}<br /><span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px' }}>Verified on - {dashboardData && dashboardData ? moment(dashboardData.finbitDate).format('DD-MM-YYYY') : ""}</span></div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', padding: '10px' }}>
                                <div className='' style={{ width: '15%' }}><img src={Bankimage3} width={'100%'} /></div>
                                <div className='' style={{ width: '85%', marginLeft: '20px', marginTop: '10px' }}> Salary Day
                                    <br /><span >{salaryDay ? salaryDay : ""} {salaryDay ? <span>day of every Month</span> : "NA"} </span><br /><span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px' }}>Fetched on - {dashboardData && dashboardData ? moment(dashboardData.finbitDate).format('DD-MM-YYYY') : ""}</span>
                                </div>
                            </div>

                            {/* <div className='' style={{ width: '85%', marginLeft: '20px' }}>Is the Bank Statement latest or not
                                <p style={{ fontWeight: '700', color: '#00C851', display: 'flex' }}>
                                    <select value={latestStatement} disabled={!newKycData !== '' && newKycData.editable !== undefined ? !newKycData.editable : ""} onChange={(e) => setlatestStatement(e.target.value)} className='form-control' style={{ width: '100px', padding: '5px' }}>
                                        <option>Select</option>
                                        <option value={'yes'}>Yes</option>
                                        <option value={'No'}>No</option>

                                    </select>
                                    &nbsp;&nbsp;{latestStatement !== '' && latestStatement !== null && newKycData.editable ? <button className='btn btn-success' style={{ padding: '3px 14px' }} onClick={() => saveLatestStatement()}>Save</button> : ""}
                                </p>
                            </div> */}
                            <div style={{ display: 'flex', width: '90%', padding: '10px', background: '#D7FBEA', marginLeft: 'auto', marginRight: 'auto', borderRadius: '6px', borderBottom: '1px solid #00C851', }}>
                                <div className='' style={{ width: '35%' }}>Our Decision</div>
                                <div className='' style={{ textAlign: 'end', fontWeight: '700', color: '#00C851', width: '65%', fontSize: '11px', textAlign: 'end' }}><b>{dashboardData && dashboardData.finBitOurDecision ? dashboardData.finBitOurDecision : ""}</b>
                                    <br /><span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px' }}>Verified on - {dashboardData && dashboardData ? moment(dashboardData.finbitDate).format('DD-MM-YYYY') : "NA"}</span>
                                </div>
                            </div>

                            <br />



                        </div>
                    </div>
                    <div className='credit-module-card' style={{ minHeight: '229px', maxHeight: '229px' }}>
                        <div className='credit-head'>
                            <h5>Activity/Comments</h5>
                        </div>
                        <div style={{ overflow: 'auto', maxHeight: '300px' }}>
                            {activityData !== '' && activityData.length > 0 ? activityData.map((data, i) => {
                                return (
                                    <div key={i} style={{ display: 'flex', width: '100%', padding: '10px', borderBottom: '1px solid #F1F7FF' }}>
                                        <div className='' style={{ width: '15%', textAlign: 'center' }}><img src={Bankimage3} width={'70%'} /></div>
                                        <div className='' style={{ width: '85%', marginLeft: '20px', fontSize: '12px' }}>{data.notes}<br />
                                            <span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px', float: 'right' }}> {data.addedOn ? 'Verified on -' + moment(data.addedOn).format('DD-MM-YYYY') : ""}</span>
                                        </div>
                                    </div>
                                )
                            }) : ""}
                        </div>
                        {/* <div style={{ display: 'flex', width: '100%', padding: '10px', borderBottom: '1px solid #F1F7FF' }}>
                            <div className='' style={{ width: '15%', textAlign: 'center' }}><img src={Bankimage3} width={'50%'} /></div>
                            <div className='' style={{ width: '85%', marginLeft: '20px', fontSize: '12px' }}>This comment is for testing purposes only.<br />
                                <span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px', float: 'right' }}>Verified on - 20/09/2023</span>
                            </div>
                        </div>
                        <div style={{ display: 'flex', width: '100%', padding: '10px', borderBottom: '1px solid #F1F7FF', }}>
                            <div className='' style={{ width: '15%', textAlign: 'center' }}><img src={Bankimage3} width={'50%'} /></div>
                            <div className='' style={{ width: '85%', marginLeft: '20px', fontSize: '12px' }}>This comment is for testing purposes only.<br />
                                <span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px', float: 'right' }}>Verified on - 20/09/2023</span>
                            </div>
                        </div>
                        <div style={{ display: 'flex', width: '100%', padding: '10px', borderBottom: '1px solid #F1F7FF', }}>
                            <div className='' style={{ width: '15%', textAlign: 'center' }}><img src={Bankimage3} width={'50%'} /></div>
                            <div className='' style={{ width: '85%', marginLeft: '20px', fontSize: '12px' }}>This comment is for testing purposes only.<br />
                                <span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px', float: 'right' }}>Verified on - 20/09/2023</span>
                            </div>
                        </div>
                        <div style={{ display: 'flex', width: '100%', padding: '10px' }}>
                            <div className='' style={{ width: '15%', textAlign: 'center' }}><img src={Bankimage3} width={'50%'} /></div>
                            <div className='' style={{ width: '85%', marginLeft: '20px', fontSize: '12px' }}>This comment is for testing purposes only.<br />
                                <span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px', float: 'right' }}>Verified on - 20/09/2023</span>
                            </div>
                        </div> */}

                    </div>
                </div>
                <div className='col-sm-5 col-xs-12'>
                    <div className='credit-module-card' style={{ minHeight: '360px' }}>
                        <div className='credit-head'>
                            <h5>SMS Analysis</h5>
                        </div>
                        <div className='credit-body'>
                            <div style={{ background: dashboardData && dashboardData.resultSms === 'Found' ? '#FEECEB' : 'rgb(215, 251, 234)', display: 'flex', width: '100%', padding: '10px' }}>
                                <div className='' style={{ width: '50%' }}>Result</div>
                                <div className='' style={{ textAlign: 'end', color: dashboardData && dashboardData.resultSms === 'Found' ? '#F44' : '#00C851', fontWeight: '700', width: '50%' }}><b>{dashboardData && dashboardData.resultSms ? dashboardData.resultSms : ''}</b></div>
                            </div>
                            <div className='' style={{ background: '#fff', display: 'flex', width: '100%', }}>
                                <div className='' style={{ width: '50%', minHeight: '110px', borderRight: '1px solid #DBE9FF', borderBottom: '1px solid #DBE9FF' }}>

                                    <div style={{ width: '100%', display: 'flex' }}>
                                        <div style={{ marginTop: '20px', width: '20%', marginLeft: '10px' }}>
                                            <img src={salaryCheck1} width={'70%'} />&nbsp;
                                        </div>
                                        <div style={{ width: '80%' }}>
                                            <div style={{ marginTop: '10px' }}>
                                                <div style={{ display: 'flex' }}> Overdue SMS{dashboardData && dashboardData.overDueMsg && dashboardData.overDueMsg.length > 0 ? <span style={{ color: '#1eb0d2', marginLeft: window.innerWidth > 769 ? '12%' : '2%' }} ><b>{dashboardData.overDueMsg.length}</b>&nbsp;Found</span> : ""}</div>
                                                <div> ₹ {dashboardData !== '' && dashboardData.overDueSum ? dashboardData.overDueSum : '0'} </div>
                                                <div>{dashboardData !== '' && dashboardData.overDue ? <span style={{ fontWeight: '700', color: '#FF4444' }}>FAIL</span> : <span style={{ fontWeight: '700', color: '#00C851' }}>PASS</span>}</div>
                                                <div>
                                                    <span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px', }}>Verified on - {dashboardData && dashboardData.smsDate ? moment(dashboardData.smsDate).format('DD-MM-YYYY') : 'NA'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className='' style={{ width: '50%', minHeight: '110px', borderBottom: '1px solid #DBE9FF' }}>
                                    <div className='' style={{ marginTop: '5px' }}>
                                        <div style={{ width: '100%', display: 'flex' }}>

                                            <div style={{ marginTop: '20px', width: '20%', marginLeft: '10px' }}>
                                                <img src={LegalSms} width={'70%'} />&nbsp;
                                            </div>
                                            <div style={{ width: '80%' }}>

                                                <div style={{ marginTop: '10px' }}>
                                                    <div style={{ display: 'flex' }}> Legal SMS{dashboardData.legalMsg && dashboardData.legalMsg.length > 0 ? <span style={{ color: '#1eb0d2', marginLeft: window.innerWidth > 769 ? '12%' : '2%' }} ><b>{dashboardData.legalMsg.length}</b>&nbsp;Found</span> : ""}</div>

                                                    <div> ₹ {dashboardData !== '' && dashboardData.legalSum ? dashboardData.legalSum : '0'} </div>
                                                    <div>{dashboardData !== '' && dashboardData.legal ? <span style={{ fontWeight: '700', color: '#FF4444' }}>FAIL</span> : <span style={{ fontWeight: '700', color: '#00C851' }}>PASS</span>}</div>
                                                    <div>
                                                        <span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px', }}>Verified on - {dashboardData && dashboardData.smsDate ? moment(dashboardData.smsDate).format('DD-MM-YYYY') : 'NA'}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='' style={{ background: '#fff', display: 'flex', width: '100%', }}>
                                <div className='' style={{ width: '50%', minHeight: '110px', borderRight: '1px solid #DBE9FF', borderBottom: '1px solid #DBE9FF' }}>

                                    <div style={{ width: '100%', display: 'flex', marginTop: '5px' }}>
                                        <div style={{ marginTop: '20px', width: '20%', marginLeft: '10px' }}>
                                            <img src={Bankimage3} width={'70%'} />&nbsp;
                                        </div>
                                        <div style={{ width: '80%' }}>

                                            <div style={{ marginTop: '10px' }}>
                                                <div style={{ display: 'flex' }}> Salary SMS{dashboardData.salaryMsg && dashboardData.salaryMsg.length > 0 ? <span style={{ color: '#1eb0d2', marginLeft: window.innerWidth > 769 ? '12%' : '2%' }} ><b>{dashboardData.salaryMsg.length}</b>&nbsp;Found</span> : ""}</div>

                                                <div> ₹ {dashboardData !== '' && dashboardData.salary ? dashboardData.salary : '0'} </div>
                                                <div>{dashboardData !== '' && dashboardData.salaryMsg && dashboardData.salaryMsg.length > 0 ? <span style={{ fontWeight: '700', color: '#00C851' }}>PASS</span> : <span style={{ fontWeight: '700', color: '#FF4444' }} >FAIL</span>}</div>
                                                <div>
                                                    <span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px', }}>Verified on - {dashboardData && dashboardData.smsDate ? moment(dashboardData.smsDate).format('DD-MM-YYYY') : 'NA'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='' style={{ width: '50%', minHeight: '110px', borderBottom: '1px solid #DBE9FF' }}>

                                    <div style={{ width: '100%', display: 'flex', marginTop: '5px' }}>
                                        <div style={{ marginTop: '20px', width: '20%', marginLeft: '10px' }}>
                                            <img src={salaryCheck1} width={'70%'} />&nbsp;
                                        </div>
                                        <div style={{ width: '80%' }}>

                                            <div style={{ marginTop: '10px' }}>
                                                <div style={{ display: 'flex' }}> Emi SMS{dashboardData && dashboardData.emiMsg && dashboardData.emiMsg.length > 0 ? <span style={{ color: '#1eb0d2', marginLeft: window.innerWidth > 769 ? '12%' : '2%' }} ><b>{dashboardData && dashboardData.emiMsg && dashboardData.emiMsg.length > 0 ? dashboardData.emiMsg.length : ""}</b>&nbsp;Found</span> : ""}</div>

                                                <div> ₹ {dashboardData !== '' && dashboardData.totalEmiAmt ? dashboardData.totalEmiAmt : '0'} </div>
                                                <div>{dashboardData !== '' && dashboardData.emiMsg && dashboardData.emiMsg.length > 0 ? <span style={{ fontWeight: '700', color: '#FF4444' }}>FAIL</span> : <span style={{ fontWeight: '700', color: '#00C851' }}>PASS</span>}</div>
                                                <div>
                                                    <span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px', }}>Verified on - {dashboardData && dashboardData.smsDate ? moment(dashboardData.smsDate).format('DD-MM-YYYY') : 'NA'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='' style={{ background: '#fff', display: 'flex', width: '100%', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                                <div className='' style={{ width: '50%', minHeight: '110px', borderRight: '1px solid #DBE9FF' }}>

                                    <div style={{ width: '100%', display: 'flex', marginTop: '5px' }}>
                                        <div style={{ marginTop: '20px', width: '20%', marginLeft: '10px' }}>
                                            <img src={RupeeImage} width={'70%'} />&nbsp;
                                        </div>
                                        <div style={{ width: '80%' }}>

                                            <div style={{ marginTop: '15px' }}>
                                                <div> Credit Transactions</div>
                                                <div style={{ marginTop: '15px' }}> ₹ {dashboardData !== '' && dashboardData.creditSum ? dashboardData.creditSum : '0'} </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='' style={{ width: '50%', minHeight: '110px' }}>
                                    <div style={{ width: '100%', display: 'flex', marginTop: '5px' }}>
                                        <div style={{ marginTop: '20px', width: '20%', marginLeft: '10px' }}>
                                            <img src={RupeeImage} width={'70%'} />&nbsp;
                                        </div>
                                        <div style={{ width: '80%' }}>

                                            <div style={{ marginTop: '15px' }}>
                                                <div> Debit Transactions</div>
                                                <div style={{ marginTop: '15px' }}> ₹ {dashboardData !== '' && dashboardData.debitSum ? dashboardData.debitSum : '0'} </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                    <div className='credit-module-card' style={{ minHeight: '300px' }}>
                        <div className='credit-head'>
                            <h5>Internal Dedupe</h5>
                        </div>

                        <div style={{ background: !similarFound ? '#FEECEB' : '#E1F6EC', display: 'flex', width: '100%', padding: '10px' }}>
                            <div className='' style={{ width: '50%' }}>Result</div>
                            <div className='' style={{ textAlign: 'end', color: '#F44', fontWeight: '700', width: '50%' }}>{!similarFound ? <b>Found</b> : <b style={{ color: '#4CC78C' }}>Not Found</b>}</div>
                        </div>

                        <div className='text-center' style={{ background: '#fff', display: 'flex', width: '100%', }}>
                            <div className='' style={{ width: '33.33%', minHeight: '110px', border: '1px solid #DBE9FF', borderTop: 'none', borderLeft: 'none' }}>
                                <div style={{ marginTop: '23px' }}>
                                    <img src={AAdhaarImage} width={'40px'} />&nbsp; KYC Data
                                    <div style={{ marginTop: '-5px', marginLeft: '30px' }}>
                                        {similarProfile && similarProfile.length > 0 && (similarProfile.includes('aadhaar') || similarProfile.includes('pan')) ?
                                            <span style={{ color: '#F44', fontWeight: '700', background: '#FFEFF2', borderRadius: '4px', padding: '2px 10px', marginRight: '5px', fontSize: '11px', marginTop: '20px' }}>Found</span> :
                                            <span style={{ color: '#4CC78C', fontWeight: '700', background: '#E1F6EC', borderRadius: '4px', padding: '2px', fontSize: '11px', marginRight: '5px', marginTop: '20px' }}>Not Found</span>}
                                    </div>
                                </div>
                            </div>
                            <div className='' style={{ width: '33.33%', minHeight: '110px', border: '1px solid #DBE9FF', borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}>

                                <div style={{ marginTop: '23px' }}>
                                    <img src={LocationsImage} width={'40px'} />&nbsp; Address
                                    <div style={{ marginTop: '-5px', marginLeft: '30px' }}>
                                        {similarProfile && similarProfile.length > 0 && similarProfile.includes('address') ?
                                            <span style={{ color: '#F44', fontWeight: '700', background: '#FFEFF2', borderRadius: '4px', padding: '2px 10px', marginRight: '5px', fontSize: '11px', marginTop: '20px', marginLeft: '10px' }}>Found</span> :
                                            <span style={{ color: '#4CC78C', fontWeight: '700', background: '#E1F6EC', borderRadius: '4px', padding: '2px', fontSize: '11px', marginRight: '5px', marginTop: '20px', marginLeft: '10px' }}>Not Found</span>}
                                    </div>
                                </div>
                            </div>
                            <div className='' style={{ width: '33.33%', minHeight: '110px', border: '1px solid #DBE9FF', borderTop: 'none', borderRight: 'none' }}>

                                <div style={{ marginTop: '23px' }}>
                                    <img src={DeviceImage} width={'40px'} />&nbsp;  Device Id
                                    <div style={{ marginTop: '-5px', marginLeft: '30px' }}>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className='text-center' style={{ background: '#fff', display: 'flex', width: '100%', borderRadius: '10px' }}>
                            <div className='' style={{ width: '50%', minHeight: '120px', borderRight: '1px solid #DBE9FF' }}>
                                <div style={{ marginTop: '23px' }}>
                                    <img src={MobileImage} width={'40px'} />&nbsp; Mobile Numbers
                                    <div style={{ marginTop: '-5px', marginLeft: '0px' }}>
                                        {similarProfile && similarProfile.length > 0 && similarProfile.includes('mobileNo') ?
                                            <span style={{ color: '#F44', fontWeight: '700', background: '#FFEFF2', borderRadius: '4px', padding: '2px 10px', marginRight: '5px', fontSize: '11px', marginTop: '20px' }}>Found</span> :
                                            <span style={{ color: '#4CC78C', fontWeight: '700', background: '#E1F6EC', borderRadius: '4px', padding: '2px', fontSize: '11px', marginRight: '5px', marginTop: '20px' }}>Not Found</span>}
                                    </div>
                                </div>
                            </div>
                            <div className='' style={{ width: '50%', minHeight: '120px', }}>

                                <div style={{ marginTop: '23px' }}>
                                    <img src={Bankimage2} width={'40px'} />&nbsp; Account Numbers
                                    <div style={{ marginTop: '-5px', marginLeft: '-4px' }}>
                                        {similarProfile && similarProfile.length > 0 && similarProfile.includes('accountNo') ?
                                            <span style={{ color: '#F44', fontWeight: '700', background: '#FFEFF2', borderRadius: '4px', padding: '2px 10px', marginRight: '5px', fontSize: '11px', marginTop: '20px' }}>Found</span> :
                                            <span style={{ color: '#4CC78C', fontWeight: '700', background: '#E1F6EC', borderRadius: '4px', padding: '2px', fontSize: '11px', marginRight: '5px', marginTop: '20px' }}>Not Found</span>}
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    {/* {internalDedupe !== '' ?
                        <div className='credit-module-card' style={{ minHeight: '300px' }}>
                            <div className='credit-head'>
                                <h5>Internal Dedupe</h5>
                            </div>

                            <div style={{ background: !similarFound ? '#FEECEB' : '#E1F6EC', display: 'flex', width: '100%', padding: '10px' }}>
                                <div className='' style={{ width: '50%' }}>Result</div>
                                <div className='' style={{ textAlign: 'end', color: '#F44', fontWeight: '700', width: '50%' }}>{!similarFound ? <b>Found</b> : <b style={{ color: '#4CC78C' }}>Not Found</b>}</div>
                            </div>
                            <div style={{ background: !similarFound ? '#FEECEB' : '#E1F6EC', display: 'flex', width: '100%', padding: '10px', paddingTop: '6px' }}>
                                <div className='' style={{ width: '50%' }}>Reject Reason</div>
                                <div className='' style={{ textAlign: 'end', fontWeight: '400', width: '50%' }}>{Rejectreasoninternaldedue()}</div>
                            </div>
                            <div className='text-center' style={{ background: '#fff', display: 'flex', width: '100%' }}>
                                <div className='' style={{ width: '33.33%', minHeight: '110px', border: '1px solid #DBE9FF', borderTop: 'none', borderLeft: 'none' }}>
                                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                        <img src={DeviceImage} width={'40px'} />&nbsp; Device ID
                                        <div style={{ marginTop: '-5px', marginLeft: '30px' }}>
                                            {internalDedupe && internalDedupe.duplicateDeviceIdFound ?
                                                <span style={{ color: '#F44', fontWeight: '700', background: '#FFEFF2', borderRadius: '4px', padding: '2px 10px', marginRight: '5px', fontSize: '11px', marginTop: '20px' }}>Found</span> :
                                                <span style={{ color: '#4CC78C', fontWeight: '700', background: '#E1F6EC', borderRadius: '4px', padding: '2px', fontSize: '11px', marginRight: '5px', marginTop: '20px' }}>Not Found</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className='' style={{ width: '33.33%', minHeight: '110px', border: '1px solid #DBE9FF', borderTop: 'none', borderLeft: 'none' }}>
                                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                        <img src={Bankimage2} width={'40px'} />&nbsp; Account Number
                                        <div style={{ marginLeft: '30px' }}>
                                            {internalDedupe && internalDedupe.duplicateAccountFound ?
                                                <span style={{ color: '#F44', fontWeight: '700', background: '#FFEFF2', borderRadius: '4px', padding: '2px 10px', marginRight: '5px', fontSize: '11px', marginTop: '20px' }}>Found</span> :
                                                <span style={{ color: '#4CC78C', fontWeight: '700', background: '#E1F6EC', borderRadius: '4px', padding: '2px', fontSize: '11px', marginRight: '5px', marginTop: '20px' }}>Not Found</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className='' style={{ width: '33.33%', minHeight: '110px', border: '1px solid #DBE9FF', borderTop: 'none', borderLeft: 'none' }}>
                                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                        <img src={AAdhaarImage} width={'40px'} />&nbsp; Aadhar number
                                        <div style={{ marginLeft: '30px' }}>
                                            {internalDedupe && internalDedupe.duplicateAadhaarFound ?
                                                <span style={{ color: '#F44', fontWeight: '700', background: '#FFEFF2', borderRadius: '4px', padding: '2px 10px', marginRight: '5px', fontSize: '11px', marginTop: '20px' }}>Found</span> :
                                                <span style={{ color: '#4CC78C', fontWeight: '700', background: '#E1F6EC', borderRadius: '4px', padding: '2px', fontSize: '11px', marginRight: '5px', marginTop: '20px' }}>Not Found</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='text-center' style={{ background: '#fff', display: 'flex', width: '100%' }}>
                                <div className='' style={{ width: '33.33%', minHeight: '110px', border: '1px solid #DBE9FF', borderTop: 'none', borderLeft: 'none' }}>
                                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                        <img src={AAdhaarImage} width={'40px'} />&nbsp; Pan number
                                        <div style={{ marginLeft: '30px' }}>
                                            {internalDedupe && internalDedupe.duplicatePanFound ?
                                                <span style={{ color: '#F44', fontWeight: '700', background: '#FFEFF2', borderRadius: '4px', padding: '2px 10px', marginRight: '5px', fontSize: '11px', marginTop: '20px' }}>Found</span> :
                                                <span style={{ color: '#4CC78C', fontWeight: '700', background: '#E1F6EC', borderRadius: '4px', padding: '2px', fontSize: '11px', marginRight: '5px', marginTop: '20px' }}>Not Found</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className='' style={{ width: '33.33%', minHeight: '110px', border: '1px solid #DBE9FF', borderTop: 'none', borderLeft: 'none' }}>
                                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                        <img src={MobileImage} width={'40px'} />&nbsp; Mobile number
                                        <div style={{ marginLeft: '30px' }}>
                                            {internalDedupe && internalDedupe.duplicatePrimaryNumberFound ?

                                                <span style={{ color: '#F44', fontWeight: '700', background: '#FFEFF2', borderRadius: '4px', padding: '2px 10px', marginRight: '5px', fontSize: '11px', marginTop: '20px' }}>Found</span> :
                                                <span style={{ color: '#4CC78C', fontWeight: '700', background: '#E1F6EC', borderRadius: '4px', padding: '2px', fontSize: '11px', marginRight: '5px', marginTop: '20px' }}>Not Found</span>}
                                        </div>
                                        <div style={{ marginTop: '10px', textAlign: 'right', marginRight: '5px' }}>
                                            <select disabled={!internalDedupe.editable} value={internaDedupeDataToSave.duplicatePrimaryNumberDecision} onChange={(e) => dedupeChangeHandler(e, 'duplicatePrimaryNumberDecision')} style={{ marginBottom: '10px', border: 'none', backgroundColor: '#dbe9ff', borderRadius: '6px', padding: '6px 10px', fontSize: '10px', width: '108px' }} >
                                                <option value="">Select Option</option>
                                                <option value="Checked Primary No. match with other Primary Number">Checked Primary No. match with other Primary Number</option>
                                                <option value="Checked Primary No. match with other Alternate Number">Checked Primary No. match with other Alternate Number</option>
                                                <option value="Checked Primary No. does not match with other Primary or Alternate Number">Checked Primary No. does not match with other Primary or Alternate Number</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='' style={{ width: '33.33%', minHeight: '110px', border: '1px solid #DBE9FF', borderTop: 'none', borderLeft: 'none' }}>
                                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                        <img src={MobileImage} width={'40px'} />&nbsp; Primary Email
                                        <div style={{ marginLeft: '30px' }}>
                                            {internalDedupe && internalDedupe.duplicatePrimaryEmailFound ?
                                                <span style={{ color: '#F44', fontWeight: '700', background: '#FFEFF2', borderRadius: '4px', padding: '2px 10px', marginRight: '5px', fontSize: '11px', marginTop: '20px' }}>Found</span> :
                                                <span style={{ color: '#4CC78C', fontWeight: '700', background: '#E1F6EC', borderRadius: '4px', padding: '2px', fontSize: '11px', marginRight: '5px', marginTop: '20px' }}>Not Found</span>}
                                        </div>
                                        <div style={{ marginTop: '10px', textAlign: 'right', marginRight: '5px' }}>
                                            <select disabled={!internalDedupe.editable} value={internaDedupeDataToSave.duplicatePrimaryEmailDecision} style={{ marginBottom: '10px', border: 'none', backgroundColor: '#dbe9ff', borderRadius: '6px', padding: '6px 10px', fontSize: '10px', width: '108px' }} onChange={(e) => dedupeChangeHandler(e, 'duplicatePrimaryEmailDecision')}>
                                                <option value="">Select Option</option>
                                                <option value="Checked Primary mail ID match with other Primary Mail ID">Checked Primary mail ID match with other Primary Mail ID</option>
                                                <option value="Checked Primary mail ID match with other Official Mail ID">Checked Primary mail ID match with other Official Mail ID</option>
                                                <option value="Checked Primary mail ID does not match with other Primary Mail ID or Official Mail ID">Checked Primary mail ID does not match with other Primary Mail ID or Official Mail ID</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='text-center' style={{ background: '#fff', display: 'flex', width: '100%' }}>
                                <div className='' style={{ width: '33.33%', minHeight: '110px', border: '1px solid #DBE9FF', borderTop: 'none', borderLeft: 'none' }}>
                                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                        <img src={LocationsImage} width={'40px'} />&nbsp; Address
                                        <div style={{ marginTop: '-5px', marginLeft: '30px' }}>
                                            {internalDedupe && internalDedupe.duplicateAddressFound ?
                                                <span style={{ color: '#F44', fontWeight: '700', background: '#FFEFF2', borderRadius: '4px', padding: '2px 10px', marginRight: '5px', fontSize: '11px', marginTop: '20px' }}>Found</span> :
                                                <span style={{ color: '#4CC78C', fontWeight: '700', background: '#E1F6EC', borderRadius: '4px', padding: '2px', fontSize: '11px', marginRight: '5px', marginTop: '20px' }}>Not Found</span>}
                                        </div>
                                        <div style={{ marginTop: '10px', textAlign: 'right', marginRight: '5px' }}>
                                            <select disabled={!internalDedupe.editable} value={internaDedupeDataToSave.duplicateAddressDecision} style={{ marginBottom: '10px', border: 'none', backgroundColor: '#dbe9ff', borderRadius: '6px', padding: '6px 10px', fontSize: '10px', width: '108px' }} onChange={(e) => dedupeChangeHandler(e, 'duplicateAddressDecision')}>
                                                <option value="">Select Option</option>
                                                <option value="Checked Current Address match with other Current Address">Checked Current Address match with other Current Address</option>
                                                <option value="Checked Current Address does not match with other Current Address">Checked Current Address does not match with other Current Address</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='' style={{ width: '33.33%', minHeight: '110px', border: '1px solid #DBE9FF', borderTop: 'none', borderLeft: 'none' }}>
                                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                        <img src={MobileImage} width={'40px'} />&nbsp; Alternate Number
                                        <div style={{ marginLeft: '30px' }}>
                                            {internalDedupe && internalDedupe.duplicateAltNumberFound ?
                                                <span style={{ color: '#F44', fontWeight: '700', background: '#FFEFF2', borderRadius: '4px', padding: '2px 10px', marginRight: '5px', fontSize: '11px', marginTop: '20px' }}>Found</span> :
                                                <span style={{ color: '#4CC78C', fontWeight: '700', background: '#E1F6EC', borderRadius: '4px', padding: '2px', fontSize: '11px', marginRight: '5px', marginTop: '20px' }}>Not Found</span>}
                                        </div>
                                        <div style={{ marginTop: '10px', textAlign: 'right', marginRight: '5px' }}>
                                            <select disabled={!internalDedupe.editable} value={internaDedupeDataToSave.duplicateAltNumberDecision} style={{ marginBottom: '10px', border: 'none', backgroundColor: '#dbe9ff', borderRadius: '6px', padding: '6px 10px', fontSize: '10px', width: '108px' }} onChange={(e) => dedupeChangeHandler(e, 'duplicateAltNumberDecision')}>
                                                <option value="">Select Option</option>
                                                <option value="Checked Alternate No. match with other Primary Number">Checked Alternate No. match with other Primary Number</option>
                                                <option value="Checked Alternate No. match with other Alternate Number">Checked Alternate No. match with other Alternate Number</option>
                                                <option value="Checked Alternate No. does not match with other Primary or Alternate Number">Checked Alternate No. does not match with other Primary or Alternate Number</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='' style={{ width: '33.33%', minHeight: '110px', border: '1px solid #DBE9FF', borderTop: 'none', borderLeft: 'none' }}>
                                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                        <img src={MobileImage} width={'40px'} />&nbsp; Official Email
                                        <div style={{ marginLeft: '30px' }}>
                                            {internalDedupe && internalDedupe.duplicateOfficialEmailFound ?
                                                <span style={{ color: '#F44', fontWeight: '700', background: '#FFEFF2', borderRadius: '4px', padding: '2px 10px', marginRight: '5px', fontSize: '11px', marginTop: '20px' }}>Found</span> :
                                                <span style={{ color: '#4CC78C', fontWeight: '700', background: '#E1F6EC', borderRadius: '4px', padding: '2px', fontSize: '11px', marginRight: '5px', marginTop: '20px' }}>Not Found</span>}
                                        </div>
                                        <div style={{ marginTop: '10px', textAlign: 'right', marginRight: '5px' }}>
                                            <select disabled={!internalDedupe.editable} style={{ marginBottom: '10px', border: 'none', backgroundColor: '#dbe9ff', borderRadius: '6px', padding: '6px 10px', fontSize: '10px', width: '108px' }} onChange={(e) => dedupeChangeHandler(e, 'duplicateOfficialEmailDecision')} value={internalDedupe.duplicateOfficialEmailDecision}>
                                                <option value="">Select Option</option>
                                                <option value="Checked Official mail ID match with other Official Mail ID">Checked Official mail ID match with other Official Mail ID</option>
                                                <option value="Checked Official mail ID match with other Primary Mail ID">Checked Official mail ID match with other Primary Mail ID</option>
                                                <option value="Checked Official mail ID does not match with other Primary Mail ID or Official Mail ID">Checked Official mail ID does not match with other Primary Mail ID or Official Mail ID</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ background: '#fff', display: 'flex', padding: '10px 15px', justifyContent: 'space-between', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                                <div className='' style={{ width: '50%' }}>
                                    <h5>Final Result</h5>
                                    <select disabled={!internalDedupe.editable} value={internaDedupeDataToSave.result} style={{ marginBottom: '10px', border: 'none', backgroundColor: '#dbe9ff', borderRadius: '6px', padding: '6px 10px', fontSize: '10px', width: '108px' }} onChange={(e) => dedupeChangeHandler(e, 'result')}>
                                        <option value="">Select Option</option>
                                        <option value="Pass">Pass</option>
                                        <option value="Fail">Fail</option>
                                    </select>
                                </div>
                                {internalDedupe.editable ?
                                    <div className='' style={{ width: '50%', textAlign: 'end', margin: 'auto 0px' }}>
                                        <button className="btn btn-success" style={{ padding: '3px 14px' }} onClick={() => saveDedupeData()}>Save</button>
                                    </div>
                                    : ""}
                            </div>

                        </div>
                        :
                        <div className='credit-module-card' style={{ minHeight: '567px' }}>
                            <div className='credit-head'>
                                <h5>Internal Dedupe</h5>
                            </div>
                            
                            <div className='text-center' style={{ background: '#fff', display: 'flex', width: '100%' }}>
                                <div className='' style={{ width: '33.33%', minHeight: '110px', border: '1px solid #DBE9FF', borderTop: 'none', borderLeft: 'none' }}>
                                    <div style={{ padding:'10px'}}>
                                    <Skeleton height={100} />
                                    </div>
                                </div>
                                <div className='' style={{ width: '33.33%', minHeight: '110px', border: '1px solid #DBE9FF', borderTop: 'none', borderLeft: 'none' }}>
                                    <div style={{ padding:'10px'}}>
                                    <Skeleton height={100} />
                                    </div>
                                </div>
                                <div className='' style={{ width: '33.33%', minHeight: '110px', border: '1px solid #DBE9FF', borderTop: 'none', borderLeft: 'none' }}>
                                    <div style={{ padding:'10px'}}>
                                    <Skeleton height={100} />
                                    </div>
                                </div>
                            </div>
                            <div className='text-center' style={{ background: '#fff', display: 'flex', width: '100%' }}>
                                <div className='' style={{ width: '33.33%', minHeight: '110px', border: '1px solid #DBE9FF', borderTop: 'none', borderLeft: 'none' }}>
                                    <div style={{ padding:'10px'}}>
                                    <Skeleton height={100} />
                                    </div>
                                </div>
                                <div className='' style={{ width: '33.33%', minHeight: '110px', border: '1px solid #DBE9FF', borderTop: 'none', borderLeft: 'none' }}>
                                    <div style={{ padding:'10px'}}>
                                    <Skeleton height={100} />
                                    </div>
                                </div>
                                <div className='' style={{ width: '33.33%', minHeight: '110px', border: '1px solid #DBE9FF', borderTop: 'none', borderLeft: 'none' }}>
                                    <div style={{ padding:'10px'}}>
                                    <Skeleton height={100} />
                                    </div>
                                </div>
                            </div>
                            <div className='text-center' style={{ background: '#fff', display: 'flex', width: '100%' }}>
                                <div className='' style={{ width: '33.33%', minHeight: '110px', border: '1px solid #DBE9FF', borderTop: 'none', borderLeft: 'none' }}>
                                    <div style={{ padding:'10px'}}>
                                    <Skeleton height={100} />
                                    </div>
                                </div>
                                <div className='' style={{ width: '33.33%', minHeight: '110px', border: '1px solid #DBE9FF', borderTop: 'none', borderLeft: 'none' }}>
                                    <div style={{ padding:'10px'}}>
                                    <Skeleton height={100} />
                                    </div>
                                </div>
                                <div className='' style={{ width: '33.33%', minHeight: '110px', border: '1px solid #DBE9FF', borderTop: 'none', borderLeft: 'none' }}>
                                    <div style={{ padding:'10px'}}>
                                    <Skeleton height={100} />
                                    </div>
                                </div>
                            </div>
                            <div className='text-center' style={{ background: '#fff', display: 'flex', width: '100%' }}>
                                <div className='' style={{ width: '33.33%', minHeight: '110px', border: '1px solid #DBE9FF', borderTop: 'none', borderLeft: 'none' }}>
                                    <div style={{ padding:'10px'}}>
                                    <Skeleton height={100} />
                                    </div>
                                </div>
                                <div className='' style={{ width: '33.33%', minHeight: '110px', border: '1px solid #DBE9FF', borderTop: 'none', borderLeft: 'none' }}>
                                    <div style={{ padding:'10px'}}>
                                    <Skeleton height={100} />
                                    </div>
                                </div>
                                <div className='' style={{ width: '33.33%', minHeight: '110px', border: '1px solid #DBE9FF', borderTop: 'none', borderLeft: 'none' }}>
                                    <div style={{ padding:'10px'}}>
                                    <Skeleton height={100} />
                                    </div>
                                </div>
                            </div>
                        </div>} */}


                    <div className='row'>
                        <div className='col-sm-6 col-xs-12'>
                            <div className='credit-module-card' style={{ minHeight: '280px' }}>
                                <div className='credit-head'>
                                    <h5>CRIF Analysis</h5>
                                </div>
                                <div className='credit-body'>
                                    <div style={{ background: crifDataDashboard !== '' && crifDataDashboard.finBoxDecision !== undefined && crifDataDashboard.finBoxDecision.creditUnderWriterDecision !== undefined && crifDataDashboard.finBoxDecision.creditUnderWriterDecision === 'Approved' ? 'rgb(215, 251, 234)' : '#FEECEB', display: 'flex', width: '100%', padding: '10px' }}>
                                        <div className='' style={{ width: '50%' }}>Underwriter Decision</div>
                                        <div className='' style={{ textAlign: 'end', color: crifDataDashboard !== '' && crifDataDashboard.finBoxDecision !== undefined && crifDataDashboard.finBoxDecision.creditUnderWriterDecision !== undefined && crifDataDashboard.finBoxDecision.creditUnderWriterDecision === 'Approved' ? 'rgb(0, 200, 81)' : '#F44', width: '50%' }}><b>{crifDataDashboard !== '' && crifDataDashboard.finBoxDecision !== undefined && crifDataDashboard.finBoxDecision.creditUnderWriterDecision !== undefined ? crifDataDashboard.finBoxDecision.creditUnderWriterDecision : ""}</b></div>
                                    </div>
                                    <div style={{ background: '#F1F7FF', display: 'flex', width: '100%', padding: '10px' }}>
                                        <div className='' style={{ width: '50%' }}>Our Decision </div>
                                        <div className='' style={{ textAlign: 'end', width: '50%' }}><b>{crifDataDashboard !== '' && crifDataDashboard.finBoxDecision !== undefined && crifDataDashboard.finBoxDecision.finalDecision !== undefined ? crifDataDashboard.finBoxDecision.finalDecision : ""}  </b></div>
                                    </div>
                                    <div style={{ display: 'flex', width: '100%', padding: '10px' }}>
                                        <div className='' style={{ width: '15%', textAlign: 'center' }}><img src={CrifImage} width={'100%'} /></div>
                                        <div className='' style={{ width: '85%', marginLeft: '20px', fontSize: '12px' }}>CRIF Score<br />
                                            <b style={{ fontSize: '15px' }}>{crifDataDashboard !== '' && crifDataDashboard.finBoxDecision !== undefined && crifDataDashboard.finBoxDecision.score !== undefined ? crifDataDashboard.finBoxDecision.score : ""}</b>
                                            <br />
                                            <span style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '11px', float: 'left', marginTop: '10px' }}>
                                            {parseData ? <div style={{ fontSize: '10px', color: 'rgba(0, 0, 0, 0.40' }}>Fetched on -
                                                    {parseData}
                                                </div> : ""}
                                                {/* {parseData &&
                                                    parseData['INDV-REPORT-FILE'] &&
                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                                    parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['HEADER'] ? <div style={{ fontSize: '10px', color: 'rgba(0, 0, 0, 0.40' }}>Fetched on -
                                                    {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['HEADER']['DATE-OF-REQUEST']}
                                                </div> : ""} */}
                                                </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6 col-xs-12'>
                            <div className='credit-module-card' style={{ minHeight: '280px' }}>
                                <div className='credit-head'>
                                    <h5>Organization Check</h5>
                                </div>
                                <div className='credit-body'>
                                    <div style={{ background: orgData && orgData.result && orgData.result === 'Negative' ? '#FEECEB' : 'rgb(215, 251, 234)', display: 'flex', width: '100%', padding: '10px' }}>
                                        <div className='' style={{ width: '50%' }}>Result</div>
                                        <div className='' style={{ textAlign: 'end', color: orgData && orgData.result && orgData.result === 'Negative' ? '#F44' : 'rgb(0, 200, 81)', width: '50%' }}><b>{orgData && orgData.result ? orgData.result : ""}</b></div>
                                    </div>
                                    <div style={{ display: 'flex', width: '100%', padding: '10px' }}>
                                        <div className='' style={{ width: '15%', textAlign: 'center' }}><img src={OrganisationImage} width={'100%'} /></div>
                                        <div className='' style={{ width: '85%', marginLeft: '20px', fontSize: '14px' }}>Organization Name<br />
                                            <b style={{ fontSize: '12px' }}>{orgData && orgData.organizationName ? orgData.organizationName : ""}</b><br />
                                            <span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px', }}>Designation :</span> {orgData && orgData.designation ? orgData.designation : ""}<br />
                                            <span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px', }}>Current Experience : </span> {orgData && orgData.currentExp ? orgData.currentExp : ""}<br />
                                            <span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px', }}>Found on - {orgData && orgData.date ? moment(orgData.date).format('DD-MM-YYYY') : ""}</span>

                                        </div>
                                    </div>
                                    {console.log(valueForOrganization)}
                                    <label style={{ marginLeft: '5px' }}>Organisation Verification</label>
                                    <div style={{ display: 'flex', marginLeft: '5px' }}>
                                        {(allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110') ?
                                            <select disabled={(allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110') ? false : true} value={valueForOrganization} onChange={(e) => setValueForOrganization(e.target.value)} className='form-control' style={{ width: '120px', padding: '5px' }}>
                                                <option value={''}>Select value</option>
                                                <option value={'Manual Email Verification'}>Manual Email Verification</option>
                                                <option value={'Via Pay Slip'}>Via Pay Slip</option>
                                                <option value={'Via Bank Statement'}>Via Bank Statement</option>

                                            </select>
                                            : <b style={{ marginLeft: '10px' }}>{valueForOrganization}</b>}
                                        &nbsp;&nbsp;{valueForOrganization !== '' && valueForOrganization !== null && (allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110') ? <button className='btn btn-success' style={{ padding: '3px 14px' }} onClick={() => savevalueForOrganization()}>Save</button> : ""}
                                    </div>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6 col-xs-12'>
                            <div className='credit-module-card' style={{ minHeight: window.innerWidth > 1900 ? '667px' : '428px' }}>
                                <div className='credit-head'>
                                    <h5>Salary Check</h5>
                                </div>
                                <div className='credit-body'>
                                    <div style={{ background: dashboardData.salaryMatchWithSms === 'Not Matched' ? '#FEECEB' : '#D7FBEA', display: 'flex', width: '100%', padding: '10px' }}>
                                        <div className='' style={{ width: '50%' }}>Result</div>
                                        <div className='' style={{ textAlign: 'end', color: dashboardData.salaryMatchWithSms === 'Not Matched' ? '#F44' : '#00C851', width: '50%' }}><b>{dashboardData && dashboardData.salaryMatchWithSms === 'Not Matched' ? 'Reject' : 'Pass'}</b></div>
                                    </div>

                                    <div style={{ display: 'flex', width: '100%', padding: '10px', borderBottom: '1px solid #F1F7FF' }}>
                                        <div className='' style={{ width: '15%' }}><img src={salaryCheck1} width={'100%'} /></div>
                                        <div className='' style={{ width: '85%', marginLeft: '20px' }}>Sms <br /><span >₹ {dashboardData && dashboardData.msgSalary ? dashboardData.msgSalary : '0'}  </span><br /><span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px' }}>Verified on - {dashboardData ? moment(dashboardData.smsDate).format('DD-MM-YYYY') : ""}</span></div>
                                    </div>
                                    <div style={{ display: 'flex', width: '100%', padding: '10px', borderBottom: '1px solid #F1F7FF' }}>
                                        <div className='' style={{ width: '15%' }}><img src={salaryCheck2} width={'100%'} /></div>
                                        <div className='' style={{ width: '85%', marginLeft: '20px' }}>Bank Statement <br /><span >₹ {dashboardData && dashboardData.finBitSalary ? dashboardData.finBitSalary : '0'}  </span><br /><span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px' }}>Verified on - {dashboardData ? moment(dashboardData.finbitDate).format('DD-MM-YYYY') : ""}</span></div>
                                    </div>
                                    <div style={{ display: 'flex', width: '100%', padding: '10px', borderBottom: '1px solid #F1F7FF' }}>
                                        <div className='' style={{ width: '15%' }}><img src={Bankimage2} width={'100%'} /></div>
                                        <div className='' style={{ width: '85%', marginLeft: '20px' }}>User Input <br /><span >₹ {dashboardData && dashboardData.userSalary ? dashboardData.userSalary : '0'}  </span><br /><span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px' }}>Verified on - {dashboardData ? moment(dashboardData.userSalariedDate).format('DD-MM-YYYY') : ""}</span></div>
                                    </div>
                                    <div style={{ display: 'flex', width: '100%', padding: '10px' }}>
                                        <div className='' style={{ width: '15%' }}><img src={Bankimage3} width={'100%'} /></div>
                                        <div className='' style={{ width: '85%', marginLeft: '20px' }}> Salary Amount with SMS
                                            <br /><span style={{ color: dashboardData && dashboardData.salaryMatchWithSms === 'Not Matched' ? '#FF4444' : '#00C851' }}>{dashboardData && dashboardData.salaryMatchWithSms ? dashboardData.salaryMatchWithSms : ""} </span><br /><span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px' }}>Fetched on - {dashboardData ? moment(dashboardData.smsDate).format('DD-MM-YYYY') : ""}</span>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        {console.log(window.innerWidth)}
                        <div className='col-sm-6 col-xs-12'>
                            <div className='credit-module-card' style={{ minHeight: window.innerWidth > 1900 ? '667px' : '428px' }}>
                                <div className='credit-head'>
                                    <h5>Obligation Amount</h5>
                                </div>
                                <div className='credit-body'>
                                    <div style={{ background: '#FEECEB', display: 'flex', width: '100%', padding: '10px' }}>
                                        <div className='' style={{ width: '50%' }}>Result</div>
                                        <div className='' style={{ textAlign: 'end', color: '#F44', fontWeight: '700', width: '50%' }}><b>

                                            Obligation Found

                                        </b></div>
                                    </div>

                                    <div style={{ marginTop: '7px', display: 'flex', width: '100%', padding: '10px', borderBottom: '1px solid #F1F7FF' }}>
                                        <div className='' style={{ width: '15%' }}><img src={salaryCheck1} width={'100%'} /></div>
                                        <div className='' style={{ width: '85%', marginLeft: '20px' }}>Sms <br /><span >₹ {dashboardData !== '' && dashboardData.totalEmiAmt ? dashboardData.totalEmiAmt : '0'}  </span><br />
                                            <span style={{ background: dashboardData !== '' && dashboardData.emiMsg && dashboardData.emiMsg.length > 0 ? "#FEECEB" : '#D7FBEA', color: dashboardData !== '' && dashboardData.emiMsg && dashboardData.emiMsg.length > 0 ? "#F44" : '#00C851', fontSize: '10px', padding: '4px', borderRadius: '5px', fontWeight: '700' }}> {dashboardData && dashboardData.emiMsg && dashboardData.emiMsg.length > 0 ? 'Found' : 'Not Found'}
                                            </span><br /><span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px' }}>Fetched on -  {dashboardData && dashboardData.smsDate ? moment(dashboardData.smsDate).format('DD-MM-YYYY') : 'NA'}</span>

                                        </div>
                                    </div>
                                    <div style={{ marginTop: '7px', display: 'flex', width: '100%', padding: '10px', borderBottom: '1px solid #F1F7FF' }}>
                                        <div className='' style={{ width: '15%' }}><img src={salaryCheck2} width={'100%'} /></div>
                                        <div className='' style={{ width: '85%', marginLeft: '20px' }}>Bank Statement <br /><span >₹ {dashboardData && dashboardData.bankObligation ? dashboardData.bankObligation : "0"} </span><br />
                                            <span style={{ background: dashboardData && !dashboardData.bankObligation ? "#D7FBEA" : '#FEECEB', color: dashboardData && !dashboardData.bankObligation ? "#00C851" : '#F44', fontSize: '10px', padding: '4px', borderRadius: '5px', fontWeight: '700' }}> {dashboardData && !dashboardData.bankObligation ? 'Not Found' : 'Found'}  </span><br /><span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px' }}>Fetched on -  {dashboardData && dashboardData.smsDate ? moment(dashboardData.smsDate).format('DD-MM-YYYY') : 'NA'}</span>
                                        </div>
                                    </div>

                                    <div style={{ marginTop: '7px', display: 'flex', width: '100%', padding: '10px' }}>
                                        <div className='' style={{ width: '15%' }}><img src={Bankimage3} width={'100%'} /></div>
                                        <div className='' style={{ width: '85%', marginLeft: '20px' }}> Credit Bureau
                                            <br /><span >₹ {crifDataDashboard && crifDataDashboard.obligationData && crifDataDashboard.obligationData.emiSum ? Math.round(crifDataDashboard.obligationData.emiSum) : "0"} </span>
                                            <br />
                                            <span style={{ background: crifDataDashboard && !crifDataDashboard.obligationData.emiSum ? "#D7FBEA" : '#FEECEB', color: crifDataDashboard && !crifDataDashboard.obligationData.emiSum ? "#00C851" : '#F44', fontSize: '10px', padding: '4px', borderRadius: '5px', fontWeight: '700' }}> {crifDataDashboard && !crifDataDashboard.obligationData.emiSum ? 'Not Found' : 'Found'}  </span><br />
                                            <span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px' }}>
                                                {crifDataDashboard.finBoxDecision ? <> Fetched on - {crifDataDashboard.finBoxDecision.crifFetchDate ? moment(crifDataDashboard.finBoxDecision.crifFetchDate).format('DD-MM-YYYY') : ''}</> : ""}
                                            </span>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='col-sm-4 col-xs-12'>
                    <div className='credit-module-card' style={{ minHeight: window.innerWidth > 1900 ? '1719px' : '1480px' }}>
                        <div className='credit-head'>
                            <div className='row'>
                                <div className='col-sm-10 col-xs-10'>
                                    <h5>Internal Scoring</h5>
                                </div>
                                <div className='col-sm-2 col-xs-2 text-right'>
                                    {admin != null && admin.rolelist.indexOf(ROLE.Cibil_Scoring) >= 0 && (allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110') ?
                                        <i className={internalViewState === false ? 'fa fa-edit' : 'fa fa-eye'} style={{ marginTop: '10px', cursor: 'pointer' }} onClick={() => changeViewState()}></i>
                                        : ""}
                                    {/* {admin != null && admin.rolelist.indexOf(ROLE.Cibil_Scoring) >= 0 ? <i className={internalViewState === false ? 'fa fa-edit' : 'fa fa-eye'} style={{ marginTop: '10px', cursor: 'pointer' }} onClick={() => changeViewState(!internalViewState)}></i> : ""} */}
                                </div>
                            </div>
                        </div>
                        {internalViewState === false ?
                            <ScoringDetailsviewMain setcategoriesData={setcategoriesData} allDetail={allDetail} handleShowObligationPopup={handleShowObligationPopup}  obligationAPiAgain={obligationAPiAgain} setObligationAPiAgain={setObligationAPiAgain}/>
                            : ""}
                        {internalViewState === true ?
                            <EditScoring repeatUser={repeatUser} userStatus={userStatus} allDetail={allDetail} admin={admin} />
                            : ""}
                    </div>
                </div>
            </div>
            {
                obligationPopup == true ?
                <ObligationCalculatorPopup  allDetail={allDetail}  handleShowObligationPopup={handleShowObligationPopup}/>
            :''}
            <DetailsTab crifDataApi={crifDataApi} statementSource={statementSource} crifDataDashboard={crifDataDashboard} kycdata={kycdata} admin={admin} allDetail={allDetail} handleAnalysistabs={handleAnalysistabs}/>
            {
                categoriesData ?
                    <CreditPopup admin={admin} allDetail={allDetail} close={closePup} />
                    : ""
            }
        </div >
    )
}
export default CreditDashboard