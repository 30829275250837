import React, { useEffect, useState } from "react";
import Popup from '../../../../../presentationals/Popup/Popup.component';
import { saveExpenseCategory } from "../../targetactioncreator";
import Loader from '../../../../../presentationals/Loader/Loader.component';

const EditCategory = ({ editData }) => {
    const [loader, setLoader] = useState(false);
    const [employeeData, setEmployeeData] = useState({
        categoryName: "",
        categoryType: "",
        categoryDescription: "",
    });

    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [errors, setErrors] = useState({});

    const closePopup = () => {
        setPopup(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmployeeData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!employeeData.categoryName) {
            formErrors.categoryName = "Category Name is required";
            isValid = false;
        }
        if (!employeeData.categoryType) {
            formErrors.categoryType = "Category Type is required";
            isValid = false;
        }
        if (!employeeData.categoryDescription) {
            formErrors.categoryDescription = "Category Description is required";
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoader(true)
            console.log("Category Data Saved", employeeData);
            saveExpenseCategory(employeeData, callback => {
                setLoader(false)
                if (callback.message === 'success') {
                    setPopup(true);
                    setPopupStatus('Data Saved Successfully!');
                } else {
                    setPopup(true);
                    setPopupStatus('Please try again later!');
                }
                removePopup();
            });
        }
    };

    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    useEffect(() => {
        if (editData && editData.id) {
            setEmployeeData({
                id:editData.id || '',
                categoryName: editData.categoryName || "",
                categoryType: editData.categoryType || "",
                categoryDescription: editData.categoryDescription || "",
            });
        }else{
            setEmployeeData({
                categoryName: "",
                categoryType: "",
                categoryDescription: "",
            })
        }
    }, [editData]);

    return (
        <div className="container">
            {sidePopup && <Popup popupStatus={popupStatus} closePopup={closePopup} />}
            {loader ? <Loader/> :''}
            <div className="target-box">
                <h3>Add/Update Category Information</h3>
                <hr style={{ borderTop: '1px solid #000' }} />
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Category Name <span style={{color:'red'}}>*</span></label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="text"
                                        name="categoryName"
                                        value={employeeData.categoryName}
                                        onChange={handleChange}
                                    />
                                    {errors.categoryName && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.categoryName}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Category Type <span style={{color:'red'}}>*</span></label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="text"
                                        name="categoryType"
                                        value={employeeData.categoryType}
                                        onChange={handleChange}
                                    />
                                    {errors.categoryType && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.categoryType}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Category Description <span style={{color:'red'}}>*</span></label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="text"
                                        name="categoryDescription"
                                        value={employeeData.categoryDescription}
                                        onChange={handleChange}
                                    />
                                    {errors.categoryDescription && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.categoryDescription}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center" style={{ marginTop: '40px' }}>
                        <button type="submit" className="btn btn-primary">Save Category Data</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditCategory;
