import React, { useState,useEffect } from "react";
// import ExpensesList from "./list";
import ExpensesList from "./listNew";
import moment from "moment";
import TuneIcon from '@mui/icons-material/Tune';
import {getAllExpenseCategory} from '../../targetactioncreator'
import Loader from '../../../../../presentationals/Loader/Loader.component';
const differenceDate = () => {
    let d = new Date()
    let todaysDate = d.getDate();
    return todaysDate - 1
}

const InitalDate = (type) => {
    const FirstDay = moment().subtract(differenceDate(), 'days');
    if (type === 'fromDate') {
        const fromdate = FirstDay.format('YYYY-MM-DD');
        return fromdate
    } else if (type === 'toDate') {
        const todate = moment(new Date).format('YYYY-MM-DD')
        return todate
    }
}

const MainTarget = () => {
    const [loader,setLoader] =useState(false)
    const [categorySelected, setCategorySelected] = useState('all');
    const [filterType, setFilterType] = useState('date')
    const [viewType, setviewType] = useState('list')
    const [editData, seteditData] = useState('')
    const [ActiveMonthFilter, setActiveMonthFilter] = useState('3month')
    const [fromDate, setFromDate] = useState(InitalDate('fromDate'))
    const [toDate, setToDate] = useState(InitalDate('toDate'))
    const [clearbtnShow, setClearBtnShow] = useState(false);
    const [applyFilter, setapplyFilter] = useState(true);
    const [clearFilter, setclearFilter] = useState(false);
    const [categoryData,setcategoryData] =useState([])
    const editHandler = (data) => {
        console.log(data)
        if (data.id) {
            seteditData(data)
            setviewType('add')
        }
    }

    useEffect(()=>{
        CategorydataAPI(fromDate,toDate)
    },[])

    const CategorydataAPI = (start,end)=>{
        const formattedFromDate = moment(start).format('DD-MM-YYYY');
        const formattedToDate = moment(end).format('DD-MM-YYYY');
        setLoader(true)
        setcategoryData([])
        setCategorySelected("all")
        getAllExpenseCategory("dateRequired",formattedFromDate,formattedToDate,callback => {
            setLoader(false)
            if(callback && callback?.status == 200 && callback?.data?.length > 0){
                setcategoryData(callback.data)
            }else{
                setcategoryData([])
            }
        })
    }

    const TabwiseDate = (type) => {
        const today = moment();
        let fromDate, toDate;

        // toDate = today.endOf('month').format('YYYY-MM-DD');
        toDate = today.subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
        if (type == '3month') {
            fromDate = today.subtract(2, 'months').startOf('month').format('YYYY-MM-DD');
        } else if (type == '6month') {
            fromDate = today.subtract(5, 'months').startOf('month').format('YYYY-MM-DD');
        } else if (type == '1year') {
            fromDate = today.subtract(11, 'months').startOf('month').format('YYYY-MM-DD');
        }
        setFromDate(fromDate);
        setToDate(toDate);
        CategorydataAPI(fromDate,toDate)
    }
    const ChangeScreen = (type) => {
        seteditData('')
        setviewType(type)
    }
    const handlemonthFilter = (type) => {
        setActiveMonthFilter(type)
        TabwiseDate(type)
        setClearBtnShow(true)
        setapplyFilter(true);
    }

    const handleDate = (e, type) => {
        if (type === 'fromDate') {
            setFromDate(e.target.value)
            setClearBtnShow(true)

        } else if (type === 'toDate') {
            setToDate(e.target.value)
            setClearBtnShow(true)

        } else if (type === 'applyFilter') {
            setapplyFilter(true);
            CategorydataAPI(fromDate,toDate)
        } else if (type === 'clearFilter') {
            setclearFilter(true)
            setClearBtnShow(false)
            setapplyFilter(true);
            CategorydataAPI(InitalDate('fromDate'),InitalDate('toDate'))
            setActiveMonthFilter('3month')
            setFromDate(InitalDate('fromDate'))
            setToDate(InitalDate('toDate'))
        }
    }

    const applyFilterState = () => {
        setapplyFilter(false);
    }

    const handlefilterType = (e) => {
        setFilterType(e.target.value)
        setclearFilter(true)
        setClearBtnShow(false)
        setapplyFilter(true);
        if (e.target.value == 'month') {
            setActiveMonthFilter('3month')
            TabwiseDate('3month')
        } else if (e.target.value == 'date') {
            setFromDate(InitalDate('fromDate'))
            setToDate(InitalDate('toDate'))
        }
    }

    const handleChnage = (e) => {
        setCategorySelected(e.target.value);
        setapplyFilter(true);
    }
    return (
        <div className="container-fluid expense-config" style={{ marginTop: '-3%' }}>
{loader ?
    <Loader />
    :''
}
            <div className="customfilterbtn Filterations" style={{ marginTop: '0px' }}>
                <div className="row">
                    <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12" style={{ marginTop: '10px' }}>
                        <div className="inputgrp">
                            <label htmlFor="" className='inputlabel'><TuneIcon style={{ fontSize: '18px', marginBottom: '-4px' }} />&nbsp;Filter Type</label>
                            <select className='inputtag' value={filterType} onChange={(e) => handlefilterType(e)}>
                                <option value="date">Date Wise</option>
                                <option value="month">Month Wise</option>
                            </select>
                        </div>
                    </div>
                    {
                        filterType === 'date' ?
                            <>

                                <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12" style={{ marginTop: '10px' }}>
                                    <div className="inputgrp">
                                        <label htmlFor="fromDate" className='inputlabel'>From</label>
                                        <input type="date" id='customDateInput' className='inputtag' value={fromDate} onChange={(e) => handleDate(e, 'fromDate')} />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12" style={{ marginTop: '10px' }}>
                                    <div className="inputgrp">
                                        <label htmlFor="toDate" className='inputlabel'>To</label>
                                        <input type="date" id='customDateInput' className='inputtag' value={toDate} min={fromDate} onChange={(e) => handleDate(e, 'toDate')} />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12" style={{ marginTop: '10px' }}>
                                    <button className='filterbtn' style={{ marginTop: '36px', marginRight: '20px' }} onClick={(e) => handleDate(e, 'applyFilter')} >Apply Filter</button>
                                    {
                                        clearbtnShow ?
                                            <button className='filterbtnReverse' style={{ marginTop: '36px' }} onClick={(e) => handleDate(e, 'clearFilter')}>Clear Filter</button>
                                            : ''}
                                </div>
                            </>
                            : ''}
                    {
                        filterType === 'month' ?
                            <div className="col-lg-6 col-md-6 col-xs-12" style={{ marginTop: '10px' }}>
                                <div className="inputgrp" style={{ marginTop: '25px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <button className={`filterbtn2 ${ActiveMonthFilter === '3month' ? 'active' : ''}`} onClick={() => handlemonthFilter('3month')} style={{ width: '100%' }} >last 3 Month</button>
                                        <button className={`filterbtn2 ${ActiveMonthFilter === '6month' ? 'active' : ''}`} onClick={() => handlemonthFilter('6month')} style={{ width: '100%' }} >last 6 Month</button>
                                        <button className={`filterbtn2 ${ActiveMonthFilter === '1year' ? 'active' : ''}`} onClick={() => handlemonthFilter('1year')} style={{ width: '100%' }}>last 1 Year</button>

                                    </div>

                                </div>
                            </div>
                            : ''}
                    {
                         categoryData && categoryData?.length > 0 ?
                         <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12" style={{ marginTop: '10px', float: 'right' }}>
                        <div className="inputgrp">
                            <label htmlFor="" style={{ marginBottom: '5px', fontWeight: '400' }}>Category</label>
                            <select className='form-control' value={categorySelected} onChange={(e) => handleChnage(e)}>
                                {console.log("categoryDatacategoryData",categoryData)}
                                <option value="all">All Category</option>
                                {
                                    categoryData?.map((value,index)=>{
                                        return(
                                            <option value={value?.categoryId}>{value?.categoryName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                                :''}


                </div>

            </div>
            <hr />
            {/* <ExpensesList ActiveMonthFilter={ActiveMonthFilter} applyFilter={applyFilter} fromDate={fromDate} toDate={toDate} applyFilterState={applyFilterState} /> */}
            <ExpensesList ActiveMonthFilter={ActiveMonthFilter} fromDate={fromDate} toDate={toDate}  categorySelected={categorySelected} applyFilter={applyFilter} applyFilterState={applyFilterState}/>

        </div>
    )
}
export default MainTarget
