import React, { useEffect, useState } from "react";
import { getAllEmployeesAttendanceApi } from "../../targetactioncreator";
import NodataImage from '../../../../../../images/nodata.png';

const months = [
    { value: 'JANUARY', label: 'JANUARY' },
    { value: 'FEBRUARY', label: 'FEBRUARY' },
    { value: 'MARCH', label: 'MARCH' },
    { value: 'APRIL', label: 'APRIL' },
    { value: 'MAY', label: 'MAY' },
    { value: 'JUNE', label: 'JUNE' },
    { value: 'JULY', label: 'JULY' },
    { value: 'AUGUST', label: 'AUGUST' },
    { value: 'SEPTEMBER', label: 'SEPTEMBER' },
    { value: 'OCTOBER', label: 'OCTOBER' },
    { value: 'NOVEMBER', label: 'NOVEMBER' },
    { value: 'DECEMBER', label: 'DECEMBER' },
]

const handlecurrentFilterValues = (type)=>{
    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth();
    if(type == "month"){
        return month === 0 ? months[11]["value"] : months[month - 1]["value"];
    }else if(type == "year"){
        return year
    }
}


const AllAttendence = () => {
    const [allData, setAllData] = useState('');
    const [expandedDepartment, setExpandedDepartment] = useState(null);
    const [filterData, setfilterData] = useState({
        year: handlecurrentFilterValues("year"),
        month: handlecurrentFilterValues("month")
    });

    // Fetch data from API on component mount
    useEffect(() => {
        getAllEmployeesAttendanceApi(handlecurrentFilterValues("month"),handlecurrentFilterValues("year"),callback => {
            if(callback && callback?.status == 200 && callback?.message == "success"){

                setAllData(callback.data);
            }else{
                setAllData('')
            }
        });
    }, []);

    const toggleAccordion = (month) => {
        setExpandedDepartment(expandedDepartment === month ? null : month);
    };

    const handleChange = (e, type) => {
        setfilterData((prev) => ({
            ...prev,
            [type]: e.target.value
        }))
    }

    const handleApply =()=>{
        let monthName = filterData.month;
        if(filterData.month == "all"){
            monthName = ""
        }
        getAllEmployeesAttendanceApi(monthName,filterData?.year,callback => {
            if(callback && callback?.status == 200 && callback?.message == "success"){

                setAllData(callback.data);
            }else{
                setAllData('')
            }
            
        });
    }



    return (
        <div className="department-main" style={{marginTop:'5%'}}>
        <div className="container">
        <div className="row" style={{marginBottom:'30px'}}>
                    <div className="col-sm-2 col-xs-12">
                        <div>
                            <label style={{ marginTop: '10px' }}>Month</label>
                        </div>
                        <div    >
                            <select className="form-control" name="month" value={filterData.month} onChange={(e) => handleChange(e, "month")}>
                                <option value="">Select Month</option>
                                <option value="all">All</option>
                                {months.map(m => (
                                    <option key={m.value} value={m.value}>{m.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-sm-2 col-xs-12">
                        <div >
                            <label style={{ marginTop: '10px' }}>Year</label>
                        </div>
                        <div >
                            <select className="form-control" name="year" value={filterData.year} onChange={(e) => handleChange(e, "year")}>
                                <option value="">Select Year</option>
                                {Array.from({ length: new Date().getFullYear() - 2021 }, (_, index) => {
                                    const year = 2022 + index;
                                    return (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-sm-2 col-xs-12" style={{ marginTop: '34px' }}>
                        <button className="btn btn-primary" style={{ width: '40px', height: '40px' }} onClick={() => handleApply()}><i className="fa fa-filter"></i></button>
                    </div>
                </div>
            {/* {allData && Object.keys(allData).map((month) => (
                <div key={month} className="month-table">
                    <h3>{month}</h3>
                    <div className="phocket-table-new">
                        <table>
                            <thead>
                                <tr>
                                    <th>Employee Name</th>
                                    <th>Present</th>
                                    <th>Absent</th>
                                    <th>Half Day</th>
                                    <th>Paid Leave</th>
                                    <th>Earn Leave</th>
                                    <th>Total Working Days</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allData[month].map((attendance) => (
                                    <tr key={attendance.id}>
                                        <td>{attendance.employeeName}</td>
                                        <td>{attendance.present}</td>
                                        <td>{attendance.noAbsent}</td>
                                        <td>{attendance.noHalfDay}</td>
                                        <td>{attendance.noPaidLeave}</td>
                                        <td>{attendance.noEarnLeave}</td>
                                        <td>{attendance.totalWorkingDays || 'N/A'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ))} */}
            {allData && Object.keys(allData).length > 0 ? (
                <div className="accords openaccord">
                    {
                        Object.keys(allData).map((month) => {
                            return (
                                <div key={month} className="accordion-item" style={{ background: '#f2f2f2', marginBottom: '10px', borderRadius: '5px', cursor: 'pointer' }}>
                                    <div
                                        className="accordion-header"
                                        style={{ cursor: 'pointer', padding: '10px', background: 'rgb(219, 233, 255)', color: '#000', borderRadius: '5px' }}
                                        onClick={() => toggleAccordion(month)}
                                    >
                                        <div className="row">
                                            <div className="col-sm-11 col-xs-12">
                                                {/* <label style={{ color: 'grey' }}>Month</label> <br /><h4>{month || 'N/A'}</h4> */}
                                                <h4 style={{fontSize:'24px',marginTop:'14px '}}>{month || 'N/A'}</h4>
                                            </div>
                                            <div className="col-sm-1 col-xs-12 text-right" style={{ fontSize: '40px' }}>
                                                <b>{expandedDepartment === month ? '-' : '+'}</b>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ overflow: 'auto' }} className={`accordion-body ${expandedDepartment === month ? 'expanded' : ''}`}>

                                        <h5>Employee Attendence</h5>

                                        <div className="phocket-table-new" style={{ overflowX: 'auto', marginTop: '0px', borderRadius: '16px', }}>
                                            <table className="table table-responsive table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Employee Name</th>
                                                        <th>Present</th>
                                                        <th>Absent</th>
                                                        <th>Half Day</th>
                                                        <th>Paid Leave</th>
                                                        <th>Earn Leave</th>
                                                        <th>Total Working Days</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allData[month].map((attendance) => (
                                                        <tr key={attendance.id}>
                                                            <td>{attendance.employeeName}</td>
                                                            <td>{attendance.present}</td>
                                                            <td>{attendance.noAbsent}</td>
                                                            <td>{attendance.noHalfDay}</td>
                                                            <td>{attendance.noPaidLeave}</td>
                                                            <td>{attendance.noEarnLeave}</td>
                                                            <td>{attendance.totalWorkingDays || 'N/A'}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                        </div>


                                    </div>
                                </div>
                            )
                        })

                    }
                </div>
            ) : (
                <div className="text-center" style={{ marginTop: '50px' }}>
                    <img src={NodataImage} alt="No Data" />
                </div>
            )}
            </div>
        </div>
    );
};

export default AllAttendence;
