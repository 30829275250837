import React, { useState, useEffect } from 'react'
import moment from "moment";
import Pagination from '@mui/material/Pagination';
import Loader from '../../../../presentationals/Loader/Loader.component';
import NodataImage from '../../../../../images/nodata.png';
import InfoIcon from '@mui/icons-material/Info';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { getAllSettlmentsByFilterAPI, getAllSettlmentsReportByFilterAPI, downloadAllSettlementsDataByFilter, downloadAllSettlementsReportDataByFilter, getSettlmentsStatsByFilter, getSettlmentsReportStatsByFilter } from '../../AdminActionCreator.component';

export default function SettleMents() {
    const type = "processed";
    const [loader, setLoader] = useState(false)
    const [view, setView] = useState("list")
    // const [view, setView] = useState("detail")
    const [startDateToShow, setStartDateToShow] = useState(moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD'));
    const [endDateToShow, setendDateToShow] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [countData, setcountData] = useState({});
    const [objectData, setobjectData] = useState([]);
    const [selectedObjectData, setselectedObjectData] = useState([]);
    const [selectedItem, setselectedItem] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPage2, setCurrentPage2] = useState(1);
    const [totalItem, setTotalItems] = useState("")
    const [SettlementStats, setSettlementStats] = useState({})
    const [SettlementReportStats, setSettlementReportStats] = useState({})
    const [searchData, setSearchData] = useState({
        utrNumber: '',
        settlementId: ''
    })
    const itemsPerPage = 10;

    const handleDate = (value, type) => {
        console.log(value)
        if (type === 'start') {
            setStartDateToShow(value)
        }
        if (type === 'end') {
            setendDateToShow(value)
        }
    }

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        getAllSettlmentsByFilterFunction(startDateToShow, endDateToShow, value)
    };
    const handlePageChange2 = (event, value) => {
        setCurrentPage2(value);
        getAllSettlmentsReportByFilterFunction(startDateToShow, endDateToShow, value, selectedItem)
    };


    const handleSearch = (value, type) => {
        if (type === "utrNumber") {
            setSearchData((prev) => ({
                ...prev,
                "utrNumber": value
            }))
        } else {
            setSearchData((prev) => ({
                ...prev,
                "settlementId": value
            }))
        }
    }


    const clearfilterData = () => {
        setSearchData({
            utrNumber: '',
            settlementId: ''
        })
        filterData(moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
    }

    const HandleViewData = (obj) => {
        setselectedItem(obj)
        getAllSettlmentsReportByFilterFunction(startDateToShow, endDateToShow, currentPage2, obj)
        setView("detail")
    }

    const handleBack = () => {
        setView("list")
    }


    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)

    };

    const filterData = (start, end) => {
        setCurrentPage(1)
        getAllSettlmentsByFilterFunction(start, end, 1)
    };

    useEffect(() => {
        getAllSettlmentsByFilterFunction(startDateToShow, endDateToShow, currentPage)
    }, [])


    const getAllSettlmentsByFilterFunction = (start, end, currentPageValue) => {
        const data = {
            nbfcId: "",
            settlementStartDate: moment(start).format('DD-MM-YYYY'),
            settlementEndDate: moment(end).format('DD-MM-YYYY'),
            settlementId: searchData.settlementId,
            utrNo: searchData.utrNumber,
            pageNo: currentPageValue,
            size: (searchData.settlementId !== "" && searchData.settlementId !== null && searchData.settlementId !== undefined) || (searchData.utrNumber !== "" && searchData.utrNumber !== null && searchData.utrNumber !== undefined) ? 0 : itemsPerPage
        }
        const data2 = {
            nbfcId: "",
            settlementStartDate: moment(start).format('DD-MM-YYYY'),
            settlementEndDate: moment(end).format('DD-MM-YYYY'),
            settlementId: searchData.settlementId,
            utrNo: searchData.utrNumber,
            pageNo: 0,
            size:0
        }
        setLoader(true)
        getAllSettlmentsByFilterAPI(data, (callback) => {
            setLoader(false)
            console.log(callback)
            if (callback && callback?.message == "success" && callback?.status == 200) {
                setTotalItems(callback?.data?.count)
                setobjectData(callback?.data?.data)
            } else {
                setobjectData([])
                setTotalItems(0)
            }
        })
        getSettlmentsStatsByFilter(data2, (callback) => {
            setLoader(false)
            console.log(callback)
            if (callback && callback?.message == "success" && callback?.status == 200) {
                setSettlementStats(callback?.data)
            } else {
                setSettlementStats(0)
            }
        })
    }

    const getAllSettlmentsReportByFilterFunction = (start, end, currentPageValue, item) => {
        let sId = "";
        let utrn = "";
        if (Object.keys(item)?.length > 0) {
            sId = item.settlementId
            utrn = item.utr
        }
        const data = {
            nbfcId: "",
            settlementStartDate: moment(start).format('DD-MM-YYYY'),
            settlementEndDate: moment(end).format('DD-MM-YYYY'),
            settlementId: sId,
            utrNo: utrn,
            pageNo: currentPageValue,
            size: (searchData.settlementId !== "" && searchData.settlementId !== null && searchData.settlementId !== undefined) || (searchData.utrNumber !== "" && searchData.utrNumber !== null && searchData.utrNumber !== undefined) ? 0 : itemsPerPage
        }
        const data2 = {
            nbfcId: "",
            settlementStartDate: moment(start).format('DD-MM-YYYY'),
            settlementEndDate: moment(end).format('DD-MM-YYYY'),
            settlementId: sId,
            utrNo: utrn,
            pageNo: 0,
            size: 0
        }
        setLoader(true)
        getAllSettlmentsReportByFilterAPI(data, (callback) => {
            setLoader(false)
            console.log(callback)
            if (callback && callback?.message == "success" && callback?.status == 200) {
                setselectedObjectData(callback?.data)
            } else {
                setselectedObjectData([])
            }
        })
        getSettlmentsReportStatsByFilter(data2, (callback) => {
            setLoader(false)
            console.log(callback)
            if (callback && callback?.message == "success" && callback?.status == 200) {
                setSettlementReportStats(callback?.data)
            } else {
                setSettlementReportStats([])
            }
        })
    }

    const DownloadReport = (start, end) => {
        const data = {
            nbfcId: "",
            settlementStartDate: moment(start).format('DD-MM-YYYY'),
            settlementEndDate: moment(end).format('DD-MM-YYYY'),
            settlementId: searchData.settlementId,
            utrNo: searchData.utrNumber,
            pageNo: 1,
            size: (searchData.settlementId !== "" && searchData.settlementId !== null && searchData.settlementId !== undefined) || (searchData.utrNumber !== "" && searchData.utrNumber !== null && searchData.utrNumber !== undefined) ? 0 : itemsPerPage
        }
        setLoader(true)
        downloadAllSettlementsDataByFilter(data, (callback) => {
            setLoader(false)
        })
    }

    const DownloadSettlmentReport = (start, end) => {
        let sId = "";
        let utrn = "";
        if (Object.keys(selectedItem)?.length > 0) {
            sId = selectedItem.settlementId
            utrn = selectedItem.utr
        }
        const data = {
            nbfcId: "",
            settlementStartDate: moment(start).format('DD-MM-YYYY'),
            settlementEndDate: moment(end).format('DD-MM-YYYY'),
            settlementId: sId,
            utrNo: utrn,
            pageNo: 1,
            size: (searchData.settlementId !== "" && searchData.settlementId !== null && searchData.settlementId !== undefined) || (searchData.utrNumber !== "" && searchData.utrNumber !== null && searchData.utrNumber !== undefined) ? 0 : itemsPerPage
        }
        setLoader(true)
        downloadAllSettlementsReportDataByFilter(data, (callback) => {
            setLoader(false)
        })
    }

    return (
        <>
            <div className="container-fluid" style={{ paddingBottom: '20px', paddingTop: '10px' }}>
                {
                    loader ? <Loader /> : ''
                }

                {
                    view === "list" ?
                        <>
                          
                            <div className="row">
                                <div className="col-sm-4">
                                <h3><b>Settlement's</b></h3>
                                </div>
                                <div className="col-sm-4 col-xs-12" style={{float:'right'}}>
                                {
                                        SettlementStats ?
                                        <div className='count-div'>
                                            <div className='row'>
                                                <div className='col-sm-6 col-xs-6'>

                                                    <button className="btn-count-new" ><div style={{ fontSize: '14px', color: '#1D3557', fontWeight: '700' }}>SETTLEMENT<br /> COUNT <br /><br /></div> <span style={{ color: '#2B78FF', fontSize: '20px', fontWeight: 700 }}>{(SettlementStats?.count || 0)?.toLocaleString("en-US")}</span></button>

                                                </div>
                                                <div className='col-sm-6 col-xs-6'>

                                                    <button className="btn-count-new"><div style={{ fontSize: '14px', color: '#1D3557', fontWeight: '700' }}>SETTLEMENT<br /> AMOUNT <br /><br /></div><span style={{ color: '#2B78FF', fontSize: '20px', fontWeight: 700 }}> {'₹ ' + (Number(SettlementStats?.totalSettlement || 0)?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}</span></button>

                                                </div>
                                            </div>

                        </div>
                                  
                                    : ''}
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-sm-2 col-xs-12">
                                    <label>Start Date </label>
                                    <input
                                        type="date"
                                        id='customDateInput'
                                        className="form-control"
                                        value={startDateToShow}
                                        onChange={(e) => handleDate(e.target.value, 'start')}
                                    />
                                </div>
                                <div className="col-sm-2 col-xs-12">
                                    <label>End Date </label>
                                    <input
                                        type="date"
                                        id='customDateInput'
                                        className="form-control"
                                        value={endDateToShow}
                                        onChange={(e) => handleDate(e.target.value, 'end')}
                                    />
                                </div>
                                <div className="col-sm-2 col-xs-12">
                                    <label>UTR number</label>
                                    <input
                                        style={{ padding: '11px 10px' }}
                                        type="text"
                                        id='customDateInput'
                                        className="form-control"
                                        value={searchData.utrNumber}
                                        onChange={(e) => handleSearch(e.target.value, 'utrNumber')}
                                    />
                                </div>
                                <div className="col-sm-2 col-xs-12">
                                    <label>Settlement ID</label>
                                    <input
                                        style={{ padding: '11px 10px' }}
                                        type="text"
                                        id='customDateInput'
                                        className="form-control"
                                        value={searchData.settlementId}
                                        onChange={(e) => handleSearch(e.target.value, 'settlementId')}
                                    />
                                </div>
                                <div className="col-sm-4 col-xs-12">
                                    <button className="btn btn-primary" style={{ marginTop: '22px', padding: '10px', marginRight: '10px' }} onClick={() => filterData(startDateToShow, endDateToShow)}>
                                        Filter
                                    </button>
                                    <button className="btn btn-primary" style={{ marginTop: '22px', padding: '10px', background: '#fff', color: '#337ab7', marginRight: '15px' }} onClick={clearfilterData}>
                                        Clear
                                    </button>
                                    {
                                        objectData && objectData?.length > 0 ?
                                            <button className="btn btn-primary" style={{ marginTop: '22px', padding: '10px', marginRight: '10px' }} onClick={() => DownloadReport(startDateToShow, endDateToShow)}>
                                                Download Report
                                            </button>
                                            : ''}
                                    
                            </div>
                        </div>

                {/* {
                    SettlementStats ? */}
                        {/* // <div className="paymentInsightsMain" style={{ margin: '20px 0px 40px 0px' }}>
                        //     <div className="paymentOverviewSection outerBoxWithPadding" style={{ padding: '20px' }}>
                        //         <div className="heading" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        //             <h4>Overview</h4>

                        //         </div>
                        //         <div className="PaymentBoxes">
                        //             <div className="tabs" style={{ width: "25%" }}>
                        //                 <div className="subtabs" style={{ backgroundColor: '#ffe2e6', height: window.innerWidth < 1200 ? '170px' : '156px' }}>
                        //                     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                        //                         <div className="circlesIcon" style={{ backgroundColor: '#fa5a7d' }}><i class="fa fa-money" aria-hidden="true"></i></div>
                        //                         <h5 className="bolder-font">{(SettlementStats?.count || 0)?.toLocaleString("en-US")}</h5>

                        //                     </div>
                        //                     <h4>{'₹ ' + (Number(SettlementStats?.totalSettlement || 0)?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}</h4>

                        //                     <h5>Total Settlements</h5>
                        //                 </div>
                        //             </div>
                        //             {/* <div className="tabs" style={{ width: "25%" }}>
                        //                 <div className="subtabs" style={{ backgroundColor: '#fff4de', height: window.innerWidth < 1200 ? '170px' : '156px' }}>
                        //                     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                        //                         <div className="circlesIcon" style={{ backgroundColor: '#ff947a' }}><i class="fa fa-money" aria-hidden="true"></i></div>
                        //                         <h5 className="bolder-font">{(0)?.toLocaleString("en-US")}</h5>

                        //                     </div>
                        //                     <h4>{'₹ ' + (Number(countData?.settlementDueDate || 0)?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}</h4>

                        //                     <h5>Settlement Due Today</h5>
                        //                 </div>
                        //             </div>
                        //             <div className="tabs" style={{ width: "25%" }}>
                        //                 <div className="subtabs" style={{ backgroundColor: '#fff1c1', height: window.innerWidth < 1200 ? '170px' : '156px' }}>
                        //                     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                        //                         <div className="circlesIcon" style={{ backgroundColor: '#dbb328' }}><i class="fa fa-money" aria-hidden="true"></i></div>
                        //                         <h5 className="bolder-font">{(0)?.toLocaleString("en-US")}</h5>

                        //                     </div>
                        //                     <h4>{'₹ ' + (Number(countData?.previousSettlement || 0)?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}</h4>

                        //                     <h5>Previous Settlements</h5>
                        //                 </div>
                        //             </div>
                        //             <div className="tabs" style={{ width: "25%" }}>
                        //                 <div className="subtabs" style={{ backgroundColor: '#dcfce7', height: window.innerWidth < 1200 ? '170px' : '156px' }}>
                        //                     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                        //                         <div className="circlesIcon" style={{ backgroundColor: '#3dd856' }}><i class="fa fa-credit-card-alt" aria-hidden="true"></i></div>
                        //                         <h5 className="bolder-font">{(0)?.toLocaleString("en-US")}</h5>
                        //                     </div>
                        //                     <h4>{'₹ ' + (Number(countData?.upcomingSettlement || 0)?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}</h4>

                        //                     <h5>Upcoming Settlements</h5>
                        //                 </div>
                        //             </div> */}

                        {/* //         </div> */}


                        {/* //     </div> */}
                         {/* </div> */}
                         {/* <div className="paymentInsightsMain" style={{ margin: '20px 0px 40px 0px' }}>
                        //     <div className="PaymentBoxes" style={{ float: 'right' }}>
                        //         <div className="tabs" style={{ width: "100%" }}>
                        //             <div className="subtabs" style={{ backgroundColor: '#ffe2e6', height: window.innerWidth < 1200 ? '170px' : '156px' }}>
                        //                 <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                        //                     <div className="circlesIcon" style={{ backgroundColor: '#fa5a7d' }}><i class="fa fa-money" aria-hidden="true"></i></div>
                        //                     <h5 className="bolder-font">{(SettlementStats?.count || 0)?.toLocaleString("en-US")}</h5>

                        //                 </div>
                        //                 <h4>{'₹ ' + (Number(SettlementStats?.totalSettlement || 0)?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}</h4>

                        //                 <h5>Total Settlements</h5>
                        //             </div>
                        //         </div>
                        //     </div>
                        </div>  */}
                        {/* : ''} */}

                {objectData && objectData?.length > 0 ? (
                    <div className="phocket-table-new">
                        <table>
                            <thead>
                                <tr>

                                    <th className="text-center"><InfoIcon onClick={() => HandleViewData("")} style={{ cursor: "pointer" }} /></th>
                                    <th className="text-center">Created On</th>
                                    <th className="text-center">Settlement ID</th>
                                    <th className="text-center">UTR Number</th>
                                    <th className="text-center">Net Settlement</th>
                                    <th className="text-center">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {objectData && objectData?.map((data, index) => (
                                    <tr key={index}>
                                        <td className="text-center"><InfoIcon onClick={() => HandleViewData(data)} style={{ cursor: "pointer" }} /></td>
                                        <td className="text-center">{data?.createdAtStr ?? "-"}</td>
                                        <td className="text-center">{data?.settlementId ?? "-"}</td>
                                        <td className="text-center">{data?.utr ?? "-"}</td>
                                        <td className="text-center">{(data?.amount)?.toLocaleString("en-In") ?? "-"}</td>
                                        <td className="text-center">{data?.status ?? "-"}</td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div style={{ float: 'right', marginTop: '20px' }}>
                            <Pagination
                                count={Math.ceil(totalItem / itemsPerPage)}
                                page={currentPage}
                                onChange={handlePageChange}
                                color="primary"
                            />
                        </div>
                    </div>
                ) : (
                    <p style={{ marginTop: '100px' }} className="text-center">
                        <img src={NodataImage} style={{ width: '30%' }} alt="No data available" />
                    </p>
                )}
            </>
            : ''
                }


            {
                view === "detail" ?

                    <div className="SettlementsDetailsSection">
                        <div className="Details borderSection">
                            <div className="heading">
                                <h5 onClick={() => handleBack()} style={{ cursor: 'pointer' }}><ArrowBackIosNewIcon />&nbsp; Details</h5>
                            </div>
                            <div className="MainText">
                                {
                                    selectedItem && Object.keys(selectedItem)?.length > 0 ?
                                        <>
                                            <div className="row">
                                                <div className="col-sm-2 col-xs-12">
                                                    <h6>Settlement ID</h6>
                                                </div>
                                                <div className="col-sm-10 col-xs-12">
                                                    <h6 style={{ display: 'flex' }}><b>{selectedItem?.settlementId}</b> &nbsp;<ContentCopyIcon style={{ cursor: 'pointer', color: '#267dff' }} onClick={() => handleCopy(selectedItem?.settlementId)} /></h6>
                                                </div>
                                            </div>
                                            <hr style={{ margin: '3px 0px', borderTop: '1px solid var(--line-color, rgba(0, 0, 0, 0.25))' }} />
                                            <div className="row">
                                                <div className="col-sm-2 col-xs-12">
                                                    <h6>UTR number</h6>
                                                </div>
                                                <div className="col-sm-10 col-xs-12">
                                                    <h6 style={{ display: 'flex' }}>{selectedItem?.utr} &nbsp;<ContentCopyIcon style={{ cursor: 'pointer', color: '#267dff' }} onClick={() => handleCopy(selectedItem?.utr)} /></h6>
                                                </div>
                                            </div>
                                            <hr style={{ margin: '3px 0px', borderTop: '1px solid var(--line-color, rgba(0, 0, 0, 0.25))' }} />
                                        </> : ''}
                                <div className="row">
                                    <div className="col-sm-2 col-xs-12">
                                        <h6>Report</h6>
                                    </div>
                                    <div className="col-sm-10 col-xs-12">
                                        {
                                            selectedObjectData && selectedObjectData?.data && selectedObjectData?.data?.length > 0 ?
                                                <button className="btn btn-primary" style={{ padding: '3px 12px', margin: '3px 0' }} onClick={() => DownloadSettlmentReport(startDateToShow, endDateToShow)}>
                                                    Download
                                                </button>

                                                : "-"}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='borderSection' style={{ marginTop: '30px' }}>
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <div class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>

                                            <div className='AmountText'>
                                            {
                                    selectedItem && Object.keys(selectedItem)?.length > 0 ?
                                                <div className="squareBox" style={{ background: SettlementReportStats?.statusList == "processed" ? "#17ff1733"  : "#f003" }}>
                                                    {
                                                        SettlementReportStats?.statusList == "processed" ?
                                                            <DoneIcon style={{ color: 'green' }} />
                                                                :
                                                                <CloseOutlinedIcon style={{ color: 'red' }} />
                                                    }
                                                </div>
                                                :''}
                                                <div className="text">
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <h3>₹ {SettlementReportStats?.totalSettledAmount || ""}</h3>
                                                        {
                                    selectedItem && Object.keys(selectedItem)?.length > 0 ?
                                                        <h5 style={{ background: SettlementReportStats?.statusList == "processed" ? "#17ff1733" :  "#f003", color:  SettlementReportStats?.statusList == "processed" ? "green" : "red" }}>{SettlementReportStats?.statusList || ""}</h5>
                                                        :''}
                                                    </div>
                                                    {
                                    selectedItem && Object.keys(selectedItem)?.length > 0 ?
                                                    <h6 style={{ margin: '5px 0px',textAlign:'left' }}>Created On {SettlementReportStats?.dateList}</h6>
                                                    :""}
                                                </div>
                                            </div>
                                            <div style={{ padding: '20px' }}>
                                                <KeyboardArrowDownOutlinedIcon style={{ fontSize: '20px' }} />
                                            </div>

                                        </button>
                                    </div>

                                    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample" style={{ width: '100%' }}>
                                        <div class="accordion-body">
                                            <div className="AmountData ">
                                                <div className="blocks">
                                                    <h4 className='blockName'><b>Gross Settlements</b></h4>
                                                    <h4 className='blockvalue' style={{ color: 'green' }}><b>₹ {SettlementReportStats?.grossAmount || "0"}</b></h4>
                                                </div>
                                                <div className="blocks">
                                                    <h4 className='blockName' style={{ paddingLeft: '15px' }}>Payment</h4>
                                                    <h4 className='blockvalue'><b>₹ {SettlementReportStats?.grossAmount || "0"}</b></h4>
                                                </div>
                                                <hr style={{ margin: '8px 0px', borderTop: '1px solid var(--line-color, rgba(0, 0, 0, 0.25))' }} />
                                                <div className="blocks">
                                                    <h4 className='blockName'><b>Deductions</b></h4>
                                                    <h4 className='blockvalue' style={{ color: 'red' }}><b>₹ {SettlementReportStats?.deductions || "0"}</b></h4>
                                                </div>
                                                <div className="blocks">
                                                    <h4 className='blockName' style={{ paddingLeft: '15px' }}>Ondemand Settlement</h4>
                                                    <h4 className='blockvalue'>₹ {SettlementReportStats?.onDemandSettlement || "0"}</h4>
                                                </div>
                                                <div className="blocks">
                                                    <h4 className='blockName' style={{ paddingLeft: '15px' }}>Tax</h4>
                                                    <h4 className='blockvalue'>₹ {SettlementReportStats?.tax || "0"}</h4>
                                                </div>
                                                <div className="blocks">
                                                    <h4 className='blockName' style={{ paddingLeft: '15px' }}>Fee</h4>
                                                    <h4 className='blockvalue'>₹ {SettlementReportStats?.fee || "0"}</h4>
                                                </div>
                                                <hr style={{ margin: '8px 0px', borderTop: '1px solid var(--line-color, rgba(0, 0, 0, 0.25))' }} />
                                                <div className="blocks">
                                                    <h4 className='blockName'><b>Net Settlements</b></h4>
                                                    <h4 className='blockvalue'><b>₹ {SettlementReportStats?.netSettlement || "0"}</b></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="borderSection" style={{ marginTop: '30px' }}>
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingTwo" style={{ margin: '0px', background: '#f5f3f3' }}>
                                        <button class="TableSection accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '5px 10px' }}>

                                            <div className="heading">
                                                <h5>Gross Settlements</h5> &nbsp;&nbsp;&nbsp;
                                                <h6 style={{ color: 'blue', background: '#b4b2b2', borderRadius: '8px', padding: '3px 10px' }}>{selectedObjectData?.count}</h6>
                                            </div>
                                            <div >
                                                <KeyboardArrowDownOutlinedIcon style={{ fontSize: '20px' }} />
                                            </div>
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" style={{ width: '100%' }}>
                                        <div class="accordion-body">
                                            {selectedObjectData && selectedObjectData?.data && selectedObjectData?.data?.length > 0 ? (
                                                <div className="phocket-table-new">
                                                    <table>
                                                        <thead>
                                                            <tr>

                                                                <th className="text-center">order Id</th>
                                                                <th className="text-center">Created On</th>
                                                                <th className="text-center">Settlement ID</th>
                                                                <th className="text-center">UTR Number</th>
                                                                <th className="text-center">Net Settlement</th>
                                                                <th className="text-center">Fee</th>
                                                                <th className="text-center">Tax</th>
                                                                <th className="text-center">Debit</th>
                                                                <th className="text-center">Credit</th>
                                                                <th className="text-center">On Hold</th>
                                                                <th className="text-center">Settled</th>
                                                                {/* <th className="text-center">Status</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {selectedObjectData && selectedObjectData?.data && selectedObjectData?.data?.map((data, index) => (
                                                                <tr key={index}>
                                                                    <td className="text-center">{data?.orderId || "-"}</td>
                                                                    <td className="text-center">{data?.createdAtStr ?? "-"}</td>
                                                                    <td className="text-center">{data?.settlementId ?? "-"}</td>
                                                                    <td className="text-center">{data?.settlementUtr ?? "-"}</td>
                                                                    <td className="text-center">{data?.amount ?? "-"}</td>
                                                                    <td className="text-center">{data?.fee ?? "-"}</td>
                                                                    <td className="text-center">{data?.tax ?? "-"}</td>
                                                                    <td className="text-center">{data?.debit ?? "-"}</td>
                                                                    <td className="text-center">{data?.credit ?? "-"}</td>
                                                                    <td className="text-center">{data?.onHold ? "Yes" : "No"}</td>
                                                                    <td className="text-center">{data?.settled ? "Yes" : "No"}</td>
                                                                    {/* <td className="text-center">{data?.status ?? "-"}</td> */}

                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    <div style={{ float: 'right', marginTop: '20px' }}>
                                                        <Pagination
                                                            count={Math.ceil(selectedObjectData?.count / itemsPerPage)}
                                                            page={currentPage2}
                                                            onChange={handlePageChange2}
                                                            color="primary"
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                <p style={{ marginTop: '100px' }} className="text-center">
                                                    <img src={NodataImage} style={{ width: '30%' }} alt="No data available" />
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    : ''}
        </div >
        </>
    )
}
