import axios from "axios";
import { APIS } from "../../../../utils/api-factory";

export const getAllEmployees = (month, year, email,isAllowed, callBack, error) => {
    axios.get(APIS.GET_ALL_EMPLOYEES + month + '&year=' + year + "&managerId=" + email + "&allowed=" + isAllowed)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const getAllExpense = (sDate, eDate, callBack, error) => {
    axios.get(APIS.GET_ALL_EXPENSE + sDate + "&eDate=" + eDate)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            // console.log(e);
            // error(e.response?.data);
        });
};

export const getAllExpenseCategory = (type, sDate, eDate, callBack, error) => {
    if (type == 'dateRequired') {

        axios.get(APIS.GET_ALL_EXPENSE_CATEGORY + "?sDate=" + sDate + "&eDate=" + eDate)
            .then(res => {
                callBack(res.data);
            })
            .catch(e => {
                console.log(e);
                error(e.response?.data);
            });
    } else if (type == 'notRequired') {
        axios.get(APIS.GET_ALL_EXPENSE_CATEGORY)
            .then(res => {
                callBack(res.data);
            })
            .catch(e => {
                console.log(e);
                error(e.response?.data);
            });
    }
};

export const getAllExpenseSubCategory = (type, sDate, eDate,categoryId, callBack, error) => {
    if (type == 'dateRequired') {
        axios.get(APIS.GET_ALL_EXPENSE_SUB_CATEGORY + "?sDate=" + sDate + "&eDate=" + eDate )
            .then(res => {
                callBack(res.data);
            })
            .catch(e => {
                console.log(e);
                error(e.response?.data);
            });
    } else if (type == 'notRequired') {
        axios.get(APIS.GET_ALL_EXPENSE_SUB_CATEGORY + "?categoryId=" + categoryId)
            .then(res => {
                callBack(res.data);
            })
            .catch(e => {
                console.log(e);
                error(e.response?.data);
            });
    }
};

export const getAllDescription = (type, sDate, eDate, callBack, error) => {
    if (type == 'dateRequired') {

        axios.get(APIS.GET_ALL_DESCRIPTION + "?sDate=" + sDate + "&eDate=" + eDate)
            .then(res => {
                callBack(res.data);
            })
            .catch(e => {
                console.log(e);
                error(e.response?.data);
            });
    } else if (type == 'notRequired') {
        axios.get(APIS.GET_ALL_DESCRIPTION)
            .then(res => {
                callBack(res.data);
            })
            .catch(e => {
                console.log(e);
                error(e.response?.data);
            });
    }
};

export const getExpenseTarget = (sDate, eDate, callBack, error) => {
    axios.get(APIS.GET_EXPENSE_TARGET + sDate + "&eDate=" + eDate)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const saveEmployeeDataForTarget = (data, callBack, error) => {
    axios.post(APIS.SAVE_EMPLOYEE_DATA_FOR_TARGET, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const saveExpenseData = (data, callBack, error) => {
    axios.post(APIS.SAVE_EXPENSE_DATA, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const saveExpenseCategory = (data, callBack, error) => {
    axios.post(APIS.SAVE_EXPENSE_CATEGORY, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const saveExpenseSubCategory = (data, callBack, error) => {
    axios.post(APIS.SAVE_EXPENSE_SUB_CATEGORY, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const saveExpenseDescription = (data, callBack, error) => {
    axios.post(APIS.SAVE_EXPENSE_DESCRIPTION, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const saveExpenseTarget = (data, callBack, error) => {
    axios.post(APIS.SAVE_EXPENSE_TARGET, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const getAllBusiness = (callBack, error) => {
    axios.get(APIS.GET_ALL_BUSINESS)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const saveBusinessPlanData = (data, callBack, error) => {
    axios.post(APIS.SAVE_BUSINESS_DATA, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};
export const updateBusinessPlanData = (data, callBack, error) => {
    axios.post(APIS.UPDATE_BUSINESS_DATA, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};
export const getAllBusinessReturn = (callBack, error) => {
    axios.get(APIS.GET_ALL_BUSINESS_RETURN)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};
export const saveBusinessReturnData = (data, callBack, error) => {
    axios.post(APIS.SAVE_BUSINESS_RETURN_DATA, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};
export const updateBusinessReturnData = (data, callBack, error) => {
    axios.post(APIS.UPDATE_BUSINESS_RETURN_DATA, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const getAllBusinessTargetCategory = (callBack, error) => {
    axios.get(APIS.GET_ALL_BUSINESS_TARGET_CATEGORY)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};
export const saveBusinessTargetCategoryData = (data, callBack, error) => {
    axios.post(APIS.SAVE_BUSINESS_TARGET_CATEGORY_DATA, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};
export const updateBusinessTargetCategoryData = (data, callBack, error) => {
    axios.post(APIS.UPDATE_BUSINESS_TARGET_CATEGORY_DATA, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const getAllBusinessTargetSubCategory = (callBack, error) => {
    axios.get(APIS.GET_ALL_BUSINESS_TARGET_SUBCATEGORY)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};
export const saveBusinessTargetSubCategoryData = (data, callBack, error) => {
    axios.post(APIS.SAVE_BUSINESS_TARGET_SUBCATEGORY_DATA, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};
export const updateBusinessTargetSubCategoryData = (data, callBack, error) => {
    axios.post(APIS.UPDATE_BUSINESS_TARGET_SUBCATEGORY_DATA, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const getAllBusinessTarget = (callBack, error) => {
    axios.get(APIS.GET_ALL_BUSINESS_TARGET)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};
export const saveBusinessTargetData = (data, callBack, error) => {
    axios.post(APIS.SAVE_BUSINESS_TARGET_DATA, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};
export const updateBusinessTargetData = (data, callBack, error) => {
    axios.post(APIS.UPDATE_BUSINESS_TARGET_DATA, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const deleteEmployeeById = (id, callBack, error) => {
    axios.get(APIS.DELETE_EMPLOYEE_BY_ID + id)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const deleteExpenseById = (id, callBack, error) => {
    axios.get(APIS.DELETE_EXPENSE_BY_ID + id)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const deleteExpenseCategoryById = (id, callBack, error) => {
    axios.get(APIS.DELETE_EXPENSE_CATEGORY_BY_ID + id)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            callBack(e.response?.data);
        });
};

export const deleteExpenseSubCategoryById = (id, callBack, error) => {
    axios.get(APIS.DELETE_EXPENSE_SUBCATEGORY_BY_ID + id)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            callBack(e.response?.data);
        });
};

export const deleteExpenseDescriptionById = (id, callBack, error) => {
    axios.get(APIS.DELETE_EXPENSE_DESCRIPTION_BY_ID + id)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            callBack(e.response?.data);
        });
};

export const deleteExpenseTargetById = (id, callBack, error) => {
    axios.get(APIS.DELETE_EXPENSE_TARGET_BY_ID + id)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const getAllDepartmentList = (email,isAllowed, callBack, error) => {
    axios.get(APIS.GET_ALL_DEPARTEMENT_LIST + email + "&allowed=" + isAllowed)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const SaveDepartmentList = (data, callBack, error) => {
    axios.post(APIS.SAVE_DEPARTMENT_DATA, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};
export const UpdateDepartmentList = (data, callBack, error) => {
    axios.post(APIS.UPDATE_DEPARTMENT_DATA, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const DeleteDepartmentData = (id, callBack, error) => {
    axios.get(APIS.DELETE_DEPARTMENT_DATA + id)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};


export const saveAttendenceApi = (data, callBack, error) => {
    axios.post(APIS.SAVE_EMP_ATTENDATNCE, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};
export const getAttendenceApi = (data, month, year, callBack, error) => {
    axios.get(APIS.GET_ATTENTENCE_API + data + '&month=' + month + '&year=' + year)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};
export const getSavedSalaryTargetDataApi = (id,isAllowed, callBack, error) => {
    axios.get(APIS.GET_SET_TARGET + id + "&allowed=" + isAllowed)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};
export const deleteSavedSalaryTargetDataApi = (id, callBack, error) => {
    axios.delete(APIS.DELETE_SALARY_TARGET_DATA + id)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};
export const saveSalaryTargetsapi = (data, callBack, error) => {
    axios.post(APIS.SAVE_SALARY_TARGET_API, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};


export const getAllDepartmentListOld = (callBack, error) => {
    axios.get(APIS.GET_ALL_DEPARTEMENT_LIST_OLD)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const getAllEmployeesAttendanceApi = (month,year,callBack, error) => {
    axios.get(APIS.GET_ATTENDANCE_ALL_EMP_MONTH_WISE + month + "&year=" + year)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const getAllEmployeesForReportingApi = (callBack, error) => {
    axios.get(APIS.GET_ALL_EMPLOYEE_FOR_REPORTING)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const getLineGraphDataApi = (catId, sDate, eDate, filterType, callBack, error) => {
    axios.get(APIS.GET_LINE_GRAPH_DATA_BY_CAT_ID + catId + "&sDate=" + sDate + "&eDate=" + eDate + '&filterType=' + filterType)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            // console.log(e);
            // error(e.response?.data);
        });
};
export const getBarGraphDataApi = (catId, sDate, eDate, filterType, callBack, error) => {
    axios.get(APIS.GET_BAR_GRAPH_DATA_BY_CAT_ID + catId + "&sDate=" + sDate + "&eDate=" + eDate + '&filterType=' + filterType)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            // console.log(e);
            // error(e.response?.data);
        });
};
export const getTxnDtaApi = (catId, orderBy, orderKey, callBack, error) => {
    axios.get(APIS.GET_TRANSACTION_DATA_BY_CAT_ID + catId + '&orderBy=' + orderBy + '&orderKey=' + orderKey)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        // console.log(e);
        // error(e.response?.data);
    });
};

export const downloadReportApi = (data, month, year, callBack, error) => {
    const url = `${APIS.DOWNLOAD_EMP_REPORT_API}${data}&month=${month}&year=${year}`;
    
    axios.get(url, { responseType: 'blob' })
    .then(res => {
        const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        
        const link = document.createElement('a');
        const downloadUrl = URL.createObjectURL(blob);
        link.href = downloadUrl;
        link.download = `report_${month}_${year}.xlsx`; 
        
        link.click();
        
        URL.revokeObjectURL(downloadUrl);
    })
    .catch(e => {
        console.log(e);
        error(e.response?.data);
    });
};

export const getcategoryTargetbydateRangeApi = (catId, sDate, eDate, callBack, error) => {
    axios.get(APIS.GET_CATEGORY_TARGET_BY_DATE_RANGE + catId + '&sDate=' + sDate + '&eDate=' + eDate)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            // console.log(e);
            // error(e.response?.data);
        });
};

export const saveUpdateVendorMaster = (data, callBack, error) => {
    axios.post(APIS.SAVE_UPDATE_VENDOR_MASTER ,data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            // console.log(e);
            // error(e.response?.data);
        });
};
export const GetVendorMasterDetails = (start,end, callBack, error) => {
    axios.get(APIS.GET_VENDOR_MASTER_DETAILS + start + "&eDate=" + end )
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            callBack("")
            // console.log(e);
            // error(e.response?.data);
        });
};
export const deleteVendorMaster = (id, callBack, error) => {
    axios.delete(APIS.DELETE_VENDOR_MASTER + id)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            // console.log(e);
            // error(e.response?.data);
        });
};
export const getVendorDetailsForExpense = ( callBack, error) => {
    axios.get(APIS.GET_VENDOR_DETAILS_FOR_EXPENSE )
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            // console.log(e);
            // error(e.response?.data);
        });
};

export const getAllExpenseSubcategoryBycategory = (categoryId,startDate,endDate, callBack, error) => {
    axios.get(APIS.GET_ALL_EXPENSE_SUB_CATEGORY_BY_CATEGORYID  + categoryId + "&sDate=" + startDate + "&eDate=" + endDate)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            // console.log(e);
            // error(e.response?.data);
        });
};

export const getExpenseProvisionDetails = ( sDate, eDate, callBack, error) => {
    
        axios.get(APIS.GET_EXPENSE_PROVISION_DETAILS + sDate + "&eDate=" + eDate )
            .then(res => {
                callBack(res.data);
            })
            .catch(e => {
                console.log(e);
                error(e.response?.data);
            });
   
};

export const saveProvisionData = (data, callBack, error) => {
    axios.post(APIS.SAVE_EXPENSE_PROVISION_DETAILS, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const getExpenseProvisionDetailsBycategoryId = (categoryId,orderBy,orderKey, callBack, error) => {
    axios.get(APIS.GET_EXPENSE_PROVISION_DETAILS_BY_CATEGORYID + categoryId + '&orderBy=' + orderBy + '&orderKey=' + orderKey)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};