import { APIS } from "../../../../utils/api-factory";
import axios from "axios";

export const getNBFCList = (callBack,error) =>{
    axios.get(APIS.GET_LIST_OF_NBFC)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        callBack(e.response?.data);
    });
}

export const getMonthWiseOverdueStatsAPI = (sDate,eDate,nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_MONTH_WISE_OVERDUE_STATS + sDate + "&eDate=" + eDate  +"&nbfcId=" + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        callBack(e.response?.data);
    });
}

export const getMonthWiseOverdueStatsDetailedAPI = (sDate,eDate,month,nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_MONTH_WISE_OVERDUE_STATS_DETAILED + sDate + "&eDate=" + eDate + "&monthName=" +month  +"&nbfcId=" + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        callBack(e.response?.data);
    });
}

export const getDueOverDueCountsAPI = (sDate,eDate,nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_DUE_OVERDUE_COUNTS + sDate + "&eDate=" + eDate +"&nbfcId=" + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        error(e.response?.data);
    });
}

export const getInvoicedPaidAmountMonthlyAPI = (sDate,eDate,nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_INVOICED_PAID_AMOUNT_MONTHLY + sDate + "&eDate=" + eDate +"&nbfcId=" + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        callBack(e.response?.data);
    });
}

export const getOverDueAgeSummaryAPI = (sDate,eDate,nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_OVERDUE_AGE_SUMMARY + sDate + "&eDate=" + eDate +"&nbfcId=" + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        error(e.response?.data);
    });
}


export const getKPILoanDashboardStatsAPI = (nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_KPI_LOAN_DASHBOARD_STATS + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        callBack(e.response?.data);
    });
}

export const getLoanAmountTrendsGraphAPI = (nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_LOAN_AMOUNT_TRENDS_GRAPH + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        error(e.response?.data);
    });
}

export const getYearWiseLoanAmountTrendsAPI = (nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_YEAR_WISE_LOAN_AMOUNT_GRAPH + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        error(e.response?.data);
    });
}

export const getUpcomingDuePaymentAPI = (nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_UPCOMING_DUE_PAYMENTS + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        error(e.response?.data);
    });
}

export const getLoanStatusWiseCountsAPI = (nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_LOAN_STATUS_WISE_COUNT + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        error(e.response?.data);
    });
}

export const getOverdueEMIPortfolioAPI = (nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_OVERDUE_EMI_PORTFOLIO + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        error(e.response?.data);
    });
}


export const getPaymentStatsAPI = (sDate,eDate,nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_PAYMENTS_STATS + sDate + '&eDate=' + eDate +'&nbfcId=' + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        error(e.response?.data);
    });
}

export const getPaymentSModesBreakDownAPI = (sDate,eDate,nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_PAYMENTS_MODES_BREAKDOWN + sDate + '&eDate=' + eDate +'&nbfcId=' + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        error(e.response?.data);
    });
}

export const getPaymentReceivedByDateAPI = (sDate,eDate,nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_PAYMENTS_RECEIVED_BY_DATE + sDate + '&eDate=' + eDate +'&nbfcId=' + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        error(e.response?.data);
    });
}

export const getPaymentReceivedByDPDAPI = (sDate,eDate,nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_PAYMENTS_RECEIVED_BY_DPD + sDate + '&eDate=' + eDate +'&nbfcId=' + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        callBack(e.response?.data);
    });
}


export const downloadOverDueStatsReport = (sDate,eDate,nbfcId,productId,
    callBack
  ) => {
    fetch(APIS.DOWNLOAD_OVERDUE_STATS_REPORT + sDate + "&eDate=" + eDate + "&nbdcId=" + nbfcId + "&productId=" + productId )
      // .then(res => res.json())
      // .then(json => {
      //   callBack(json)
      // })
    
       .then(res => res.blob())
      .then(data => {
        var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
        var excelBlob = new Blob(
          [data],
          { type: 'application/vnd.ms-excel' },
          'excel.xls'
        )
        var link = window.URL.createObjectURL(excelBlob)
        window.location = link
        var downloadUrl = URL.createObjectURL(blob)
        var a = document.createElement('a')
        a.href = downloadUrl
        a.download = 'OverDueReport.xls'
        document.body.appendChild(a)
        a.click()
        callBack(data)
      })
      .catch((e) => {
        callBack('')
      })
  }
