import React, { useEffect, useState } from "react";
import { getAllDescription,deleteExpenseDescriptionById } from "../../targetactioncreator";
import NodataImage from '../../../../../../images/nodata.png'
import Loader from '../../../../../presentationals/Loader/Loader.component';
import Popup from '../../../../../presentationals/Popup/Popup.component';
import moment from "moment";
const SubCategoryList = ({ editHandler,applyFilter,fromDate,toDate, ActiveMonthFilter,applyFilterState }) => {
    // const expenseData = {
    //     expenseId: "EXP12345",
    //     categoryId: "CAT67890",
    //     subCategoryId: "SUB12345",
    //     subCategoryType: "Office Supplies",
    //     subCategoryDescription: "Purchase of office supplies including pens, paper, and notebooks",
    //     isActive: true,
    //     expenseCycle: "Monthly",
    //     id: "2"
    // };
    const [expenseData, setExepensedata] = useState('')  
    const [loader, setLoader] = useState(false);
    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');

    useEffect(() => {
        if(applyFilter == true || ActiveMonthFilter !== ''){
        getDescription()
        }

    }, [applyFilter,ActiveMonthFilter])

    const getDescription =() =>{
        const formattedFromDate = moment(fromDate).format('DD-MM-YYYY');
        const formattedToDate = moment(toDate).format('DD-MM-YYYY');
        setLoader(true)
        getAllDescription('dateRequired',formattedFromDate,formattedToDate,callback => {
            setLoader(false)
            if(callback && callback?.status == 200 && callback?.data?.length > 0){
                setExepensedata(callback.data)
            }else{
                setExepensedata([])
            }
        })
        applyFilterState()
    }

    const handleDelete = (data) => {
        setLoader(true)
        deleteExpenseDescriptionById(data.id,(callback)=>{
            setLoader(false)
            if(callback?.status == 200){
                getDescription()
            }else if(callback?.status === 200 && callback?.message === "failure"){
                setPopup(true);
                setPopupStatus("Entry Can't be Deleted");
                removePopup();
            }else{
                setPopup(true);
                setPopupStatus('Please try again later!');
                removePopup();
            }
        })
    };

    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    const closePopup = () => {
        setPopup(false);
    };

    return (
        <div className="container Executor">
            {loader ? <Loader /> : ''}
                {sidePopup ?
                <Popup popupStatus={popupStatus} closePopup={closePopup} />
                : ""}
                 {expenseData !== '' && expenseData.length > 0 ?
            <div
                style={{
                    background: '#f2f2f2',
                    padding: '10px',
                    margin: '50px auto 0',
                    borderRadius: '5px',
                    overflow: 'auto',
                    maxWidth: '1000px'
                }}
            >
                <div>
                    {console.log(expenseData)}
                    <table className='table-logs' style={{ marginBottom: '10px', width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th>Expense Description</th>
                                <th>Expense Cycle</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {expenseData !== '' && expenseData.length > 0 ? expenseData.map((data, i) => {
                                return (
                                    <tr style={{ background: '#fff', padding: '5px', borderRadius: '5px' }}>
                                        <td>{data.expenseDescription}</td>
                                        <td>{data.expenseCycle}</td>
                                        <td>
                                            <i
                                                className="fa fa-pencil-square-o fa-lg"
                                                aria-hidden="true"
                                                onClick={() => editHandler(data)}
                                                style={{ cursor: 'pointer' }}
                                                aria-label="Edit Expense"
                                                title="Edit Expense"
                                            ></i>
                                        </td>
                                        <td>
                                            <i
                                                className="fa fa-trash-o fa-lg"
                                                aria-hidden="true"
                                                onClick={() => handleDelete(data)}
                                                style={{ cursor: 'pointer' }}
                                                aria-label="Delete Expense"
                                                title="Delete Expense"
                                            ></i>
                                        </td>
                                    </tr>
                                )
                            }) : ""}

                        </tbody>
                    </table>
                </div>
            </div>
            :
            <div className="text-center" style={{marginTop: '50px'}}>
            <img src={NodataImage}  width={'40%'}/>
            </div>
            }
        </div>
    );
};

export default SubCategoryList;
