import React, { useEffect, useState } from "react";
import Popup from '../../../../../presentationals/Popup/Popup.component';
import {  getAllExpenseCategory, saveExpenseDescription,getAllExpenseSubCategory } from "../../targetactioncreator";
import Loader from '../../../../../presentationals/Loader/Loader.component';

const EditCategory = ({ editData }) => {
    const [loader, setLoader] = useState(false);
    const [categoryData, setCategoryData] = useState({
        expenseId: "",
        categoryId: "",
        subCategoryId: "",
        expenseDescription: "",
        active: false,
        expenseCycle: "",
    });

    // const categoryOptions = [{
    //     "categoryId": "SWXaW31",
    //     "subCategoryType": "Sample1",
    //     "expenseDescription": "Sample1",
    //     "id": "2"
    // }];
    const [expenseList, setExpenseList] = useState('')

    const [categoryOptions, setcategoryOptions] = useState('')
    const [subcategoryOptions, setsubcategoryOptions] = useState('')

    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [errors, setErrors] = useState({});

    const closePopup = () => {
        setPopup(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name !== 'id') {
            setCategoryData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
            if(name === "categoryId"){
                setLoader(true)
                getAllExpenseSubCategory('notRequired','','',value,(callback) =>{
                    setLoader(false)
                    if(callback && callback?.status == 200){
                        setsubcategoryOptions(callback.data)
                    }else{
                        setPopup(true);
                        setPopupStatus('Please Try Again later!')
                        removePopup();
                    }
                })
            }
        }
    };

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!categoryData.categoryId) {
            formErrors.categoryId = "category Type is required";
            isValid = false;
        }
        if (!categoryData.subCategoryId) {
            formErrors.subCategoryId = "Subcategory Type is required";
            isValid = false;
        }
        if (!categoryData.expenseDescription) {
            formErrors.expenseDescription = "Description is required";
            isValid = false;
        }
        if (!categoryData.expenseCycle) {
            formErrors.expenseCycle = "Expense Cycle is required";
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        console.log(e)
        e.preventDefault();
        if (validateForm()) {
             setLoader(true)
             console.log("Category Data Saved", categoryData);
             saveExpenseDescription(categoryData, callback => {
                setLoader(false)
                if (callback.message === 'success') {
                    setPopup(true);
                    setPopupStatus('Data Saved Successfully!');
                } else {
                    setPopup(true);
                    setPopupStatus('Please try again later!');
                }
                removePopup();
            });
        }
    };

    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    useEffect(() => {
        if (editData.id) {
            setCategoryData({
                expenseId: editData.expenseId || "",
                categoryId: editData.categoryId || "",
                subCategoryId: editData.subCategoryId || "",
                expenseDescription: editData.expenseDescription || "",
                active: editData.active || true,
                expenseCycle: editData.expenseCycle || "",
                id: editData.id
            });
        }else{
            setCategoryData({
                expenseId: "",
                categoryId: "",
                subCategoryId: "",
                expenseDescription: "",
                active: false,
                expenseCycle: "",
            })
        }
    }, [editData]);
    useEffect(() => {
        getAllExpenseCategory("notRequired",'','',callback => {
            if(callback && callback?.status == 200){
                setcategoryOptions(callback.data)
            }else{
                setPopup(true);
                setPopupStatus('Please Try Again later!')
                removePopup();
            }
        })
      
    }, [])
    return (
        <div className="container">
            {sidePopup && <Popup popupStatus={popupStatus} closePopup={closePopup} />}
            {loader ? <Loader/> :''}
            <div className="target-box">
                <h3>Add/Update Description Information</h3>
                <hr style={{ borderTop: '1px solid #000' }} />
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        {/* <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Expense</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <select
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        name="expenseId"
                                        value={categoryData.expenseId}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Expense</option>
                                        {expenseList?expenseList.map((category) => (
                                            <option key={category.id} value={category.id}>
                                                {category.expenseAmount}
                                            </option>
                                        )):""}
                                    </select>
                                    {errors.expenseId && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.expenseId}</span>
                                    )}
                                </div>
                            </div>
                        </div> */}
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Category <span style={{color:'red'}}>*</span></label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <select
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        name="categoryId"
                                        value={categoryData.categoryId}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Category</option>
                                        {categoryOptions ? categoryOptions.map((category) => (
                                            <option key={category.id} value={category.categoryId}>
                                                {category.categoryName}
                                            </option>
                                        )) : ""}
                                    </select>
                                    {errors.categoryId && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.categoryId}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Sub-Category <span style={{color:'red'}}>*</span></label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <select
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        name="subCategoryId"
                                        value={categoryData.subCategoryId}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Sub-Category</option>
                                        {subcategoryOptions ? subcategoryOptions.map((value) => (
                                            <option key={value.id} value={value.subCategoryId}>
                                                {value.subCategoryName}
                                            </option>
                                        )) : ""}
                                    </select>
                                    {errors.subCategoryId && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.subCategoryId}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Expense Cycle <span style={{color:'red'}}>*</span></label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <select
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="text"
                                        name="expenseCycle"
                                        value={categoryData.expenseCycle}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Expense cycle</option>
                                        <option value="oneTime">One Time</option>
                                        <option value="monthly">Monthly</option>
                                         <option value="quarterly">Quarterly</option>
                                        <option value="halfYearly">half Yearly</option>
                                        <option value="yearly">Yearly</option>
                                        </select>
                                    {errors.expenseCycle && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.expenseCycle}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Is Active</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        type="checkbox"
                                        name="active"
                                        checked={categoryData.active}
                                        style={{marginTop:'12px'}}
                                        onChange={(e) => setCategoryData(prevData => ({
                                            ...prevData,
                                            active: e.target.checked
                                        }))}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-xs-12">
                            <div style={{ display: '', marginTop: '20px' }}>
                                <div style={{ width: '' }}>
                                    <label style={{ marginTop: '10px' }}>Description <span style={{color:'red'}}>*</span></label>
                                </div>
                                <div style={{ width: '' }}>
                                    <textarea
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="text"
                                        name="expenseDescription"
                                        value={categoryData.expenseDescription}
                                        onChange={handleChange}
                                    />
                                    {errors.expenseDescription && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.expenseDescription}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center" style={{ marginTop: '40px' }}>
                        <button type="submit" className="btn btn-primary">Save Description Data</button>
                    </div>
                </form>
            </div >
        </div >
    );
};

export default EditCategory;
