import React, { useEffect, useState } from "react";
import Popup from '../../../../../presentationals/Popup/Popup.component';
import { getAllDepartmentListOld, getAllDescription, saveProvisionData } from "../../targetactioncreator";
import Loader from '../../../../../presentationals/Loader/Loader.component';
import moment from "moment";
const EditCategory = ({ editData ,admin}) => {
    const [loader, setLoader] = useState(false);
    const [categoryData, setCategoryData] = useState({
        provisionAmount: "",
        provisionDate: moment(new Date).format('YYYY-MM-DD'),
        departmentId: "",
        expenseDescriptionId: "",
        provisionType: "",
        comment: "",
    });

    const [categoryOptions, setcategoryOptions] = useState('')

    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [errors, setErrors] = useState({});
    const [departmentList, setDepartmentList] = useState([]);
    const [descriptionData, setDescriptionData] = useState([])

    useEffect(() => {
        setLoader(true)
        getAllDepartmentListOld((callback) => {

            if (callback && callback?.status == 200) {
                setDepartmentList(callback?.data)
            } else {
                setDepartmentList([])
            }
        })
        getAllDescription('notRequired', '', '', callback => {
            setLoader(false)
            if (callback && callback?.status == 200) {
                setDescriptionData(callback.data)
            } else {
                setPopup(true);
                setPopupStatus('Please try again later!');
                removePopup();
            }
        })
    }, [])

    const closePopup = () => {
        setPopup(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCategoryData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;
        // provisionAmount: "",
        // provisionDate: moment(new Date).format('YYYY-MM-DD'),
        // departmentId: "",
        // expenseDescriptionId: "",
        // provisionType: "",
        // comment: "",
        
        if (!categoryData.provisionAmount) {
            formErrors.provisionAmount = "Provision Amount is required";
            isValid = false;
        }
        if (!categoryData.provisionDate) {
            formErrors.provisionDate = "Provision Date is required";
            isValid = false;
        }
        if (!categoryData.departmentId) {
            formErrors.departmentId = "Department Name is required";
            isValid = false;
        }
        if (!categoryData.expenseDescriptionId) {
            formErrors.expenseDescriptionId = "Expense Description is required";
            isValid = false;
        }
        if (!categoryData.provisionType) {
            formErrors.provisionType = "Provision Type is required";
            isValid = false;
        }
        

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoader(true)
            console.log("Category Data Saved", categoryData);
            const date= moment(categoryData?.provisionDate).format('DD-MM-YYYY')
            let keyName="";
            if(editData?.id){
                keyName="updatedBy"
            }   else{
                keyName="addedBy"
            } 
            const obj = {...categoryData,provisionDate:date,[keyName]:admin.emailId}
            saveProvisionData(obj, callback => {
                setLoader(false)
                if (callback.message === 'success') {
                    setPopup(true);
                    setPopupStatus('Data Saved Successfully!');
                } else {
                    setPopup(true);
                    setPopupStatus('Please try again later!');
                }
                removePopup();
            });
        }
    };

    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    useEffect(() => {
        console.log("editDataeditData",editData)
        if (editData.id) {
            setCategoryData({
                id: editData.id || '',
                provisionAmount: editData.provisionAmount || "",
                provisionDate:moment(editData.provisionDate , 'DD-MM-YYYY').format('YYYY-MM-DD')|| "",
                departmentId: editData.departmentId || "",
                expenseDescriptionId: editData.expenseDescriptionId || "",
                provisionType: editData.provisionType || "",
                comment: editData.comment || "",
            });
        } else {
            setCategoryData({
                provisionAmount: "",
                provisionDate: moment(new Date).format('YYYY-MM-DD'),
                departmentId: "",
                expenseDescriptionId: "",
                provisionType: "",
                comment: "",
            })
        }

    }, [editData]);


    return (
        <div className="container">
            {sidePopup && <Popup popupStatus={popupStatus} closePopup={closePopup} />}
            {loader ? <Loader /> : ''}
            <div className="target-box">
                <h3>Add/Update Provision Information</h3>
                <hr style={{ borderTop: '1px solid #000' }} />
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Provision Amount <span style={{ color: 'red' }}>*</span></label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="number"
                                        min={0}
                                        name="provisionAmount"
                                        value={categoryData.provisionAmount}
                                        onChange={handleChange}
                                        onWheel={(e) => e.target.blur()}
                                    />

                                    {errors.provisionAmount && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.provisionAmount}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Provision Date <span style={{ color: 'red' }}>*</span></label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="date"
                                        name="provisionDate"
                                        value={categoryData.provisionDate}
                                        onChange={handleChange}
                                    />
                                    {errors.provisionDate && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.provisionDate}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ marginTop: '20px' }}>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Department Name <span style={{ color: 'red' }}>*</span></label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <select className="form-control" name="departmentId" value={categoryData.departmentId} onChange={handleChange}>
                                        <option value="">Select Department</option>
                                        {
                                            departmentList && departmentList?.length > 0 ?
                                                departmentList?.map((value, index) => {
                                                    return (
                                                        <option key={index} value={value?.departmentId}>{value?.departmentName}</option>
                                                    )
                                                })
                                                : ''}
                                    </select>
                                    {errors.departmentId && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.departmentId}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Expense Head <span style={{ color: 'red' }}>*</span></label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <select className="form-control" name="expenseDescriptionId" value={categoryData.expenseDescriptionId} onChange={handleChange}>
                                        <option value=''>select Option</option>
                                        {
                                            descriptionData && descriptionData?.map((value, index) => {
                                                return (
                                                    <option value={value.expenseDescriptionId} key={index}>{value.expenseDescription}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    {errors.expenseDescriptionId && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.expenseDescriptionId}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Provision Type <span style={{ color: 'red' }}>*</span></label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <select className="form-control" name="provisionType" value={categoryData.provisionType} onChange={handleChange}>
                                        <option value=''>select Option</option>
                                        <option value="Add">Add</option>
                                        <option value="Reverse">Reverse</option>
                                    </select>
                                    {errors.provisionType && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.provisionType}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-xs-12" style={{ marginTop: '20px' }}>
                            <div style={{ width: '' }}>
                                <label style={{ marginTop: '10px' }}>Comment</label>
                            </div>
                            <div style={{ width: '' }}>
                                <textarea
                                    style={{ background: '#fff', border: '1px solid' }}
                                    className="form-control"
                                    type="text"
                                    name="comment"
                                    value={categoryData.comment}
                                    onChange={handleChange}
                                />
                                {errors.comment && (
                                    <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.comment}</span>
                                )}
                            </div>
                        </div>

                    </div>

                    <div className="text-center" style={{ marginTop: '40px' }}>
                        <button type="submit" className="btn btn-primary">Save Provision Data</button>
                    </div>
                </form>
            </div>
        </div>

    );
};

export default EditCategory;
