import React, { useEffect, useState } from "react";
import Popup from '../../../../presentationals/Popup/Popup.component';
import { saveExpenseData, getAllDescription, getAllDepartmentListOld, getVendorDetailsForExpense } from "../targetactioncreator";
import moment from "moment";
import Loader from '../../../../presentationals/Loader/Loader.component';

const defaultDate = () => {
    const d = new Date();
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

const EditExpence = ({ editData, admin, setViewType }) => {
    const [loader, setLoader] = useState(false);
    const [departmentList, setDepartmentList] = useState([]);
    const [vendorList, setVendorList] = useState([])
    const [employeeData, setEmployeeData] = useState({
        expenseAmount: '',
        updatedBy: "",
        expenseDescriptionId: '',
        expenseDate: defaultDate(),
        dueDate: defaultDate(),
        gstAmount: 0,
        isGSTInclusive: false,
        verified: false,
        varifiedBy: admin.emailId,
        spendingType: '',
        paymentStatus: "",
        departmentId: '',
        vendorId: '',
        // expenseLedger: '',
        iGST: 0,
        cGST: 0,
        sGST: 0,
        comment: '',
        transactionNumber: '',
        invoiceNumber: '',
        paymentMode: ''


    });
    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [errors, setErrors] = useState({});
    const [descriptionData, setDescriptionData] = useState([])

    const closePopup = () => {
        setPopup(false);
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const updatedValue = type === "checkbox" ? checked : value;
    
        setEmployeeData((prevData) => {
            const updatedData = { ...prevData, [name]: updatedValue };

            

            if (name === "cGST") {
                updatedData.sGST = updatedValue; 
                updatedData.gstAmount = prevData.iGST + updatedValue*2
            } else if (name === "sGST") {
                updatedData.cGST = updatedValue;
                updatedData.gstAmount = prevData.iGST + updatedValue*2
            }

            if (name === "iGST") {
                if (prevData.cGST > 0 || prevData.sGST > 0) {
                    updatedData.iGST = 0;
                } else {
                    updatedData.gstAmount = updatedValue
                    updatedData.cGST = 0;
                    updatedData.sGST = 0;
                }
            }
    
            if (name === "cGST" || name === "sGST") {
                if (prevData.iGST > 0) {
                    updatedData.cGST = 0;
                    updatedData.sGST = 0;
                } else {
                    updatedData.iGST = 0;
                }
            }
    
    
            return updatedData;
        });
    };

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!employeeData.expenseAmount) {
            formErrors.expenseAmount = "Expense Amount is required";
            isValid = false;
        }
        // if (!employeeData.gstAmount && employeeData.isGSTInclusive) {
        //     formErrors.gstAmount = "GST Amount is required if GST is inclusive";
        //     isValid = false;
        // }
        if (!employeeData.varifiedBy) {
            formErrors.varifiedBy = "Verified By is required";
            isValid = false;
        }
        if (!employeeData.expenseDescriptionId) {
            formErrors.expenseDescriptionId = "Description is required";
            isValid = false;
        }
        if (!employeeData.spendingType) {
            formErrors.spendingType = "Spending Type is required";
            isValid = false;
        }
        if (!employeeData.expenseDate) {
            formErrors.expenseDate = "Expense Date is required";
            isValid = false;
        }
        if (!employeeData.dueDate) {
            formErrors.dueDate = "Due Date is required";
            isValid = false;
        }
        if (!employeeData.departmentId) {
            formErrors.departmentId = " Department is required";
            isValid = false;
        }
        if (!employeeData.vendorId) {
            formErrors.vendorId = " Vendor is required";
            isValid = false;
        }
        // if (!employeeData.expenseLedger) {
        //     formErrors.expenseLedger = "Ledger is required";
        //     isValid = false;
        // }
        if (!employeeData.invoiceNumber) {
            formErrors.invoiceNumber = "Invoice Number is required";
            isValid = false;
        }
        if (!employeeData.paymentMode && (employeeData?.paymentStatus === "paid" || employeeData?.paymentStatus === "advance")) {
            formErrors.paymentMode = "Payment mode is required";
            isValid = false;
        }
        if (!employeeData.transactionNumber && (employeeData?.paymentStatus === "paid" || employeeData?.paymentStatus === "advance")) {
            formErrors.transactionNumber = "Transaction Number is required";
            isValid = false;
        }
        if (!employeeData.comment) {
            formErrors.comment = "Comment is required";
            isValid = false;
        }
        console.log(formErrors)
        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        console.log(employeeData)
        e.preventDefault();
        if (validateForm()) {
            setLoader(true)
            const formattedDate = moment(employeeData.expenseDate).format('DD-MM-YYYY');
            const formattedDueDate = moment(employeeData.dueDate).format('DD-MM-YYYY');
            const formattedData = { ...employeeData, expenseDate: formattedDate,dueDate:formattedDueDate };
            console.log("Expense Data Saved", formattedData);

            saveExpenseData(formattedData, callback => {
                setLoader(false)
                if (callback.message === 'success') {
                    setPopup(true);
                    setPopupStatus('Data Saved Successfully!');
                    setViewType('list')
                } else {
                    setPopup(true);
                    setPopupStatus('Please try again later!');
                }
                removePopup();
            });
        }
    };

    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    useEffect(() => {
        if (editData.id) {
            setEmployeeData({
                expenseAmount: editData.expenseAmount || "",
                updatedBy: admin.emailId,
                expenseDescriptionId: editData?.expenseDescriptionId || '',
                expenseDate: editData.expenseDate ? moment(editData.expenseDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : "",
                dueDate: editData.dueDate ? moment(editData.dueDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : "",
                gstAmount: editData?.gstAmount || 0,
                isGSTInclusive: editData?.isGSTInclusive || false,
                verified: editData?.verified || false,
                varifiedBy: editData?.varifiedBy || "",
                spendingType: editData?.spendingType || "",
                paymentStatus: editData?.paymentStatus || "",
                departmentId: editData?.departmentId || '',
                vendorId: editData?.vendorId || '',
                // expenseLedger: '',
                iGST: editData?.iGST || 0,
                cGST: editData?.cGST || 0,
                sGST: editData?.sGST || 0,
                comment: editData?.comment || "",
                transactionNumber: editData?.transactionNumber || "",
                invoiceNumber: editData?.invoiceNumber || "",
                paymentMode: editData?.paymentMode || "",
                vendorId: editData?.vendorId || "",
                id: editData?.id || "",
                expenseId: editData?.expenseId || "",
            });
        } else {
            setEmployeeData({
                expenseAmount: '',
                updatedBy: "",
                expenseDescriptionId: '',
                expenseDate: defaultDate(),
                dueDate: defaultDate(),
                gstAmount: 0,
                isGSTInclusive: false,
                verified: false,
                varifiedBy: admin.emailId,
                spendingType: '',
                paymentStatus: "",
                departmentId: '',
                vendorId: '',
                // expenseLedger: '',
                iGST: 0,
                cGST: 0,
                sGST: 0,
                comment: '',
                transactionNumber: '',
                invoiceNumber: '',
                paymentMode: ''

            });

        }
    }, [editData]);

    const getDescriptionData = () => {
        setLoader(true)
        getAllDescription('notRequired', '', '', callback => {
            setLoader(false)
            if (callback && callback?.status == 200) {
                setDescriptionData(callback.data)
            } else {
                setPopup(true);
                setPopupStatus('Please try again later!');
                removePopup();
            }
        })
    }
    useEffect(() => {
        getDescriptionData()
        getVendorDetailsForExpense((callback) => {
            console.log(callback)
            if (callback && callback?.data && callback?.message == "success") {
                setVendorList(callback?.data)
            } else {
                setVendorList([])
            }
        })
        getAllDepartmentListOld((callback) => {
            // console.log(callback)
            if (callback && callback?.status == 200) {
                setDepartmentList(callback?.data)
            } else {
                setDepartmentList([])
            }
        })
    }, [])
    return (
        <div className="container">
            {sidePopup && <Popup popupStatus={popupStatus} closePopup={closePopup} />}
            {loader ? <Loader /> : ''}
            <div className="target-box">
                <h3>Add/Update Expense Information</h3>
                <hr style={{ borderTop: '1px solid #000' }} />
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Expense Date</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="date"
                                        name="expenseDate"
                                        value={employeeData.expenseDate}
                                        onChange={handleChange}
                                    />
                                    {errors.expenseDate && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.expenseDate}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '28px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Invoice Number <span style={{ color: 'red' }}>*</span></label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="text"
                                        name="invoiceNumber"
                                        value={employeeData.invoiceNumber}
                                        onChange={handleChange}


                                    />
                                    {errors.invoiceNumber && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.invoiceNumber}</span>
                                    )}
                                </div>
                            </div>

                            <div style={{ display: 'flex', marginTop: '28px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Department <span style={{ color: 'red' }}>*</span></label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <select className="form-control" name="departmentId" value={employeeData.departmentId} onChange={handleChange}>
                                        <option value="">Select Department</option>
                                        {
                                            departmentList && departmentList?.length > 0 ?
                                                departmentList?.map((value, index) => {
                                                    return (
                                                        <option key={index} value={value?.departmentId}>{value?.departmentName}</option>
                                                    )
                                                })
                                                : ''}
                                    </select>
                                    {errors.departmentId && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.departmentId}</span>
                                    )}
                                </div>
                            </div>
                            {/* <div style={{ display: 'flex', marginTop: '28px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Expense Ledger</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="text"
                                        name="expenseLedger"
                                        value={employeeData.expenseLedger}
                                        onChange={handleChange}


                                    />
                                    {errors.expenseLedger && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.expenseLedger}</span>
                                    )}
                                </div>
                            </div> */}
                            <div style={{ display: 'flex', marginTop: '28px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Vendor Name <span style={{ color: 'red' }}>*</span></label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <select
                                        className="form-control"
                                        name="vendorId"
                                        value={employeeData.vendorId}
                                        onChange={handleChange}>
                                        <option>Select Vendor Name</option>
                                        {
                                            vendorList && vendorList?.length > 0 ? vendorList?.map((value, index) => {
                                                return (
                                                    <option key={index} value={value.vendorId}>{value.legalEntityName}</option>
                                                )
                                            })
                                                : ''}
                                    </select>

                                    {errors.vendorId && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.vendorId}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Expense Amount <span style={{ color: 'red' }}>*</span></label>
                                    <label style={{ display: 'block', fontSize: '12px', color: '#6c6b6b' }}>(Total Invoice Value Excluding GST)</label>

                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="number"
                                        name="expenseAmount"
                                        value={employeeData.expenseAmount}
                                        onChange={handleChange}
                                    />
                                    {errors.expenseAmount && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.expenseAmount}</span>
                                    )}
                                </div>
                            </div>

                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>GST Amount</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="number"
                                        name="gstAmount"
                                        value={employeeData.gstAmount}
                                        onChange={handleChange}
                                        disabled
                                    />
                                    {errors.gstAmount && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.gstAmount}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Expense Head <span style={{ color: 'red' }}>*</span></label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <select name="expenseDescriptionId" onChange={handleChange}
                                        className="form-control" value={employeeData.expenseDescriptionId}>
                                        <option value=''>select Option</option>
                                        {
                                            descriptionData && descriptionData?.map((value, index) => {
                                                return (
                                                    <option value={value.expenseDescriptionId} key={index}>{value.expenseDescription}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    {errors.expenseDescriptionId && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.expenseDescriptionId}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Spending Type <span style={{ color: 'red' }}>*</span></label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <select name="spendingType" onChange={handleChange}
                                        className="form-control" value={employeeData.spendingType}>
                                        <option value=''>select Option</option>
                                        <option value='technical'>Technical</option>
                                        <option value='operational'>Operational</option>

                                    </select>
                                    {errors.spendingType && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.spendingType}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Due Date</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="date"
                                        name="dueDate"
                                        value={employeeData.dueDate}
                                        onChange={handleChange}
                                    />
                                    {errors.dueDate && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.dueDate}</span>
                                    )}
                                </div>
                            </div>  
                            {/* 
                            <div style={{ display: 'flex', marginTop: '28px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Verified By</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="text"
                                        name="varifiedBy"
                                        value={employeeData.varifiedBy}
                                        onChange={handleChange}
                                        readOnly

                                    />
                                    {errors.varifiedBy && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.varifiedBy}</span>
                                    )}
                                </div>
                            </div> */}




                        </div>
                        <div className="col-sm-6 col-xs-12">
                        
                            <div style={{ display: 'flex', marginTop: '25px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Verified</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid', marginTop: '15px' }}
                                        type="checkbox"
                                        name="verified"
                                        checked={employeeData.verified}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '25px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>GST Inclusive</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    {/* <input
                                        style={{ background: '#fff', border: '1px solid', marginTop: '15px' }}
                                        type="checkbox"
                                        name="isGSTInclusive"
                                        checked={employeeData.isGSTInclusive}
                                        onChange={handleChange}
                                    /> */}
                                    <select name="isGSTInclusive" onChange={handleChange} value={employeeData.isGSTInclusive} className="form-control">
                                        <option value="">Select GST Type</option>
                                        <option value={true}>Applicable</option>
                                        <option value={false}>Not Applicable</option>
                                    </select>
                                </div>
                            </div>

                            <div style={{ display: 'flex', marginTop: '40px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Bill Payment status</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    {/* <input
                                        style={{ background: '#fff', border: '1px solid', marginTop: '15px' }}
                                        type="checkbox"
                                        name="paid"
                                        checked={employeeData.paid}
                                        onChange={handleChange}
                                    /> */}
                                    <select className="form-control" name="paymentStatus" value={employeeData.paymentStatus} onChange={handleChange}>
                                        <option>Select Payment Status</option>
                                        <option value="paid">Paid</option>
                                        <option value="unPaid">Unpaid</option>
                                        <option value="advance">Advance</option>
                                    </select>
                                </div>
                            </div>
                            {employeeData.paymentStatus === "paid" || employeeData.paymentStatus === "advance" ?
                                <>
                                    <div style={{ display: 'flex', marginTop: '20px' }}>
                                        <div style={{ width: '50%' }}>
                                            <label style={{ marginTop: '10px' }}>Payment Mode  <span style={{ color: 'red' }}>*</span></label>
                                        </div>
                                        <div style={{ width: '50%' }}>
                                            <select name="paymentMode" onChange={handleChange}
                                                className="form-control" value={employeeData.paymentMode}>
                                                <option value=''>select Option</option>
                                                <option value='cash'>Cash</option>
                                                <option value='online'>online</option>

                                            </select>
                                            {errors.paymentMode && (
                                                <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.paymentMode}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '20px' }}>
                                        <div style={{ width: '50%' }}>
                                            <label style={{ marginTop: '10px' }}>Transactions Number  <span style={{ color: 'red' }}>*</span></label>
                                        </div>
                                        <div style={{ width: '50%' }}>
                                            <input
                                                style={{ background: '#fff', border: '1px solid' }}
                                                className="form-control"
                                                type="number"
                                                name="transactionNumber"
                                                value={employeeData.transactionNumber}
                                                onChange={handleChange}
                                            />
                                            {errors.transactionNumber && (
                                                <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.transactionNumber}</span>
                                            )}
                                        </div>
                                    </div>
                                </> : ""}

                            {/* { Boolean(employeeData.isGSTInclusive) == true ?
                            <>
                                <div style={{ display: 'flex', marginTop: '28px' }}>
                                    <div style={{ width: '50%' }}>
                                        <label style={{ marginTop: '10px' }}>iGST</label>
                                    </div>
                                    <div style={{ width: '50%' }}>
                                        <input
                                            style={{ background: '#fff', border: '1px solid' }}
                                            className="form-control"
                                            type="text"
                                            name="iGST"
                                            value={employeeData.iGST}
                                            onChange={handleChange}
                                            disabled={employeeData.sGST !== 0 || employeeData.cGST !== 0 ? false : true}

                                        />
                                        {errors.iGST && (
                                            <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.iGST}</span>
                                        )}
                                    </div>
                                </div>
                                 
                           
                                <div style={{ display: 'flex', marginTop: '28px' }}>
                                    <div style={{ width: '50%' }}>
                                        <label style={{ marginTop: '10px' }}>sGST</label>
                                    </div>
                                    <div style={{ width: '50%' }}>
                                        <input
                                            style={{ background: '#fff', border: '1px solid' }}
                                            className="form-control"
                                            type="text"
                                            name="sGST"
                                            value={employeeData.sGST}
                                            onChange={handleChange}
                                            disabled={employeeData.iGST !== 0 ? false : true}
                                        />
                                       
                                    </div>
                                </div>
                               
                                <div style={{ display: 'flex', marginTop: '28px' }}>
                                    <div style={{ width: '50%' }}>
                                        <label style={{ marginTop: '10px' }}>cGST</label>
                                    </div>
                                    <div style={{ width: '50%' }}>
                                        <input
                                            style={{ background: '#fff', border: '1px solid' }}
                                            className="form-control"
                                            type="text"
                                            name="cGST"
                                            value={employeeData.cGST}
                                            onChange={handleChange}
                                            disabled={employeeData.iGST !== 0 ? false : true}
                                        />
                                       
                                    </div>
                                </div>
                                </>: ""} */}

                            {(employeeData.isGSTInclusive) === "true" && (
                                <>
                                    <div style={{ display: 'flex', marginTop: '28px' }}>
                                        <div style={{ width: '50%' }}>
                                            <label style={{ marginTop: '10px' }}>iGST</label>
                                        </div>
                                        <div style={{ width: '50%' }}>
                                            <input
                                                style={{ background: '#fff', border: '1px solid' }}
                                                className="form-control"
                                                type="number"
                                                name="iGST"
                                                value={employeeData.iGST}
                                                onChange={handleChange}
                                                // disabled={employeeData.sGST !== 0 || employeeData.cGST !== 0}
                                            />

                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '28px' }}>
                                        <div style={{ width: '50%' }}>
                                            <label style={{ marginTop: '10px' }}>sGST</label>
                                        </div>
                                        <div style={{ width: '50%' }}>
                                            <input
                                                style={{ background: '#fff', border: '1px solid' }}
                                                className="form-control"
                                                type="number"
                                                name="sGST"
                                                value={employeeData.sGST}
                                                onChange={handleChange}
                                                // disabled={employeeData.iGST !== 0}
                                            />

                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '28px' }}>
                                        <div style={{ width: '50%' }}>
                                            <label style={{ marginTop: '10px' }}>cGST</label>
                                        </div>
                                        <div style={{ width: '50%' }}>
                                            <input
                                                style={{ background: '#fff', border: '1px solid' }}
                                                className="form-control"
                                                type="number"
                                                name="cGST"
                                                value={employeeData.cGST}
                                                onChange={handleChange}
                                                // disabled={employeeData.iGST !== 0}
                                            />

                                        </div>
                                    </div>
                                </>
                            )}


                        </div>

                        <div className="col-xs-12">
                            <div style={{ marginTop: '28px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Comment  <span style={{ color: 'red' }}>*</span></label>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <textarea
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="text"
                                        name="comment"
                                        value={employeeData.comment}
                                        onChange={handleChange}


                                    />
                                    {errors.comment && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.comment}</span>
                                    )}
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="text-center" style={{ marginTop: '40px' }}>
                        <button type="submit" className="btn btn-primary" >Save Expense Data</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditExpence;
