import React, { useState, useEffect } from 'react'
import { RepeatDataApi, disburseDataDownloadApi, IncomeBreakupDownloadApi, EmiModifyDownloadApi, EmiCollectionDownloadApi, EmiDailyHitDownloadApi, outstandingReportApi, getRejectionReportApi, dailyHitEmiReportApi, disbursedCreditReport, getEmiAmountReceivedDataApi, dailyAccountingDisbuseReport, excelSheetByChoiceNewforaccountsApi, excelSheetByChoiceDisburseforaccountsApi,AdminLeadsReportApi } from '../../../containers/Admin/AdminActionCreator.component';
import moment from 'moment';
import Loader from '../../../presentationals/Loader/DotLoader.component'
const PredefinedReportByChoiceNew = ({ downloadicon, admin }) => {
    const timestamp = Date.now();
    const date = new Date(timestamp);
    const formattedstartDate = `${date.getFullYear()}-${date.getMonth() >= 10 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)}-${date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()}`;
    const formattedendDate = `${date.getFullYear()}-${date.getMonth() >= 10 ? date.getMonth() : '0' + date.getMonth()}-${date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()}`;

    const [selectedBox, setSelectedBox] = useState(-1);
    const [loaderState, setLoader] = useState(false);

    const [popup, setpopup] = useState(false);
    const [popuptext, setpopuptext] = useState('');
    const [checkedbox, setcheckedbox] = useState(false);
    const [startDate, setStartDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [disbursedDate, setdisbursedDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [collectionDate, setcollectionDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [format, setformat] = useState(0);
    const [due, setdue] = useState(true);
    const [undue, setUndue] = useState(true);
    const [fileName, setfileName] = useState('');


    // const boxtext = [
    //     'Repeat Report',
    //     'Disbursed Report',
    //     'Income Report',
    //     'EMI Modify Report',
    //     'EMI Collection Report',
    //     'EMI Daily Hit Report',
    //     // 'Daily Collection Report',
    //     // 'Due EMI Report',
    //     // 'EMI Modify Report',
    // ];
    const [boxtext, setboxtext] = useState([]);

    useEffect(() => {
        if (admin?.emailId === 'SuperUser') {
            setboxtext([
                'Repeat Report',
                'Disbursed Report (Accounts)',
                'Income Report',
                'EMI Modify Report',
                'EMI Collection Report',
                'EMI Daily Hit Report',
                'Outstanding Due/Undue',
                'Rejection Report',
                'EMI Aligned Report',
                'Disbursed report (Credit)',
                'EMI Daily Received Report',
                'Daily Accounting Disbursement Report',
                'Apply Date Report',
                "Admin Lead Report",
                'Disburse Date Report'
            ]);
        } else {
            setboxtext([
                'Disbursed Report (Accounts)',
                // 'Income Report',
                // 'EMI Modify Report',
                // 'EMI Collection Report',
                'EMI Daily Hit Report',
                'Outstanding Due/Undue',
                'Rejection Report',
                'EMI Aligned Report',
                'Disbursed report (Credit)',
                'EMI Daily Received Report',
                'Daily Accounting Disbursement Report', 'Apply Date Report',
                "Admin Lead Report",
                'Daily Accounting Disbursement Report', 'Apply Date Report', 'Disburse Date Report'
            ]);
        }

    }, [])

    const handleBoxClick = (index, text) => {
        setSelectedBox(index);
        setpopup(true);
        setpopuptext(text)
    };

    const closePopup = () => {
        setpopup(false);
        setSelectedBox(-1);
        setStartDate(moment(new Date()).format('YYYY-MM-DD'))
        setEndDate(moment(new Date()).format('YYYY-MM-DD'))
    };
    const downloadReport = (type) => {
        console.log(type)
        if (type === 'Disbursed Report (Accounts)') {
            setLoader(true)
            closePopup()
            disburseDataDownloadApi(moment(startDate).format('DD-MM-YYYY'), moment(endDate).format('DD-MM-YYYY'), popuptext + '.xls', callback => {
                console.log(callback)
                setLoader(false)
            })
        }
        if (type === 'Repeat Report') {
            setLoader(true)
            closePopup()
            RepeatDataApi(admin.emailId, callBack => {
                console.log(callBack)
                setLoader(false)
            })

        }
        if (type === 'Income Report') {
            setLoader(true)
            closePopup()
            IncomeBreakupDownloadApi(moment(startDate).format('DD-MM-YYYY'), moment(endDate).format('DD-MM-YYYY'), popuptext + '.xls', callback => {
                console.log(callback)
                setLoader(false)
            })

        }
        if (type === 'EMI Modify Report') {
            setLoader(true)
            closePopup()
            EmiModifyDownloadApi(moment(startDate).format('DD-MM-YYYY'), moment(endDate).format('DD-MM-YYYY'), popuptext + '.xls', callback => {
                console.log(callback)
                setLoader(false)
            })

        }
        if (type === 'EMI Collection Report') {
            setLoader(true)
            closePopup()
            EmiCollectionDownloadApi(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), popuptext + '.xls', callback => {
                setLoader(false)
            })

        }
        if (type === 'EMI Daily Hit Report') {
            setLoader(true)
            closePopup()
            EmiDailyHitDownloadApi(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), popuptext + '.xls', callback => {
                setLoader(false)
            })

        }
        if (type === 'Outstanding Due/Undue') {
            setLoader(true)
            closePopup()
            let status = due && undue ? '' : due ? 'due' : undue ? 'undue' : ''
            let fileName = format === 0 ? 'summaryreport.xls' : 'detailedreport.xls'

            outstandingReportApi(moment(disbursedDate).format('DD-MM-YYYY'), moment(collectionDate).format('DD-MM-YYYY'), format, status, popuptext + '.xls', callback => {
                setLoader(false)
            })
        }
        if (type === 'Rejection Report') {
            setLoader(true)
            closePopup()
            getRejectionReportApi(moment(startDate).format('DD-MM-YYYY'), moment(endDate).format('DD-MM-YYYY'), popuptext + '.xls', callback => {
                setLoader(false)
            })

        }
        if (type === 'EMI Aligned Report') {
            setLoader(true)
            closePopup()
            dailyHitEmiReportApi(moment(startDate).format('DD-MM-YYYY'), moment(endDate).format('DD-MM-YYYY'), popuptext + '.xls', callback => {
                setLoader(false)
            })

        }
        if (type === 'Disbursed report (Credit)') {
            setLoader(true)
            closePopup()
            disbursedCreditReport(moment(startDate).format('DD-MM-YYYY'), moment(endDate).format('DD-MM-YYYY'), popuptext + '.xls', callback => {
                setLoader(false)
            })

        }
        if (type === 'EMI Daily Received Report') {
            setLoader(true)
            closePopup()
            getEmiAmountReceivedDataApi(moment(startDate).format('DD-MM-YYYY'), moment(endDate).format('DD-MM-YYYY'), popuptext + '.xls', callback => {
                setLoader(false)
            })

        }
        if (type === 'Daily Accounting Disbursement Report') {
            setLoader(true)
            closePopup()
            dailyAccountingDisbuseReport(moment(startDate).format('DD-MM-YYYY'), moment(endDate).format('DD-MM-YYYY'), popuptext + '.xls', callback => {
                setLoader(false)
            })

        }
        if (type === 'Apply Date Report') {
            if (fileName !== '') {
                setLoader(true)
                closePopup()
                excelSheetByChoiceNewforaccountsApi(moment(startDate).format('DD-MM-YYYY'), moment(endDate).format('DD-MM-YYYY'), admin.emailId, fileName, callback => {
                    setLoader(false)
                })
            } else {
                alert('Enter file Name')
            }

        }
        if (type === 'Disburse Date Report') {
            if (fileName !== '') {
                setLoader(true)
                closePopup()
                excelSheetByChoiceDisburseforaccountsApi(moment(startDate).format('DD-MM-YYYY'), moment(endDate).format('DD-MM-YYYY'), admin.emailId, fileName, callback => {
                    setLoader(false)
                })
            } else {
                alert('Enter file Name')
            }

        }
        if (type === "Admin Lead Report") {
           
                setLoader(true)
                closePopup()
                AdminLeadsReportApi(moment(startDate).format('DD-MM-YYYY'), moment(endDate).format('DD-MM-YYYY'),popuptext, callback => {
                    setLoader(false)
                })
            

        }
    }
    const removePopup = () => {
        setTimeout(() => {
            setLoader(false)
        }, 10000);
    }
    const downloanAccountReport = () => {

        setLoader(true)
        closePopup()
        excelSheetByChoiceNewforaccountsApi(moment(startDate).format('DD-MM-YYYY'), moment(endDate).format('DD-MM-YYYY'), admin.emailId, fileName, callback => {
            setLoader(false)
        })
    }
    return (
        <>
            {loaderState ?
                <Loader /> : ""}
            {console.log(moment(disbursedDate).format('DD-MM-YYYY'))}
            <div className='container-fluid generate-predefined-report'>
                <div className='row form-group'>
                    <h3 className='text-center blue-text'>Generate Predefined Report</h3>
                </div>
                <div className="row boxes">
                    {
                        boxtext.map((value, index) => {
                            return (
                                <>
                                    <Boxcols key={index}
                                        index={index}
                                        downloadicon={downloadicon}
                                        text={value}
                                        isSelected={selectedBox === index}
                                        handleBoxClick={handleBoxClick} />
                                </>
                            )
                        })
                    }
                </div>
                {
                    popup ?
                        <Popup
                            fileName={fileName}
                            setfileName={setfileName}
                            undue={undue}
                            due={due}
                            setdue={setdue}
                            setUndue={setUndue}
                            setformat={setformat}
                            format={format}

                            disbursedDate={disbursedDate}
                            collectionDate={collectionDate}
                            setdisbursedDate={setdisbursedDate}
                            setcollectionDate={setcollectionDate}
                            downloadReport={downloadReport} closePopup={closePopup} popuptext={popuptext}
                            popup={popup} setcheckedbox={setcheckedbox}
                            startDate={startDate} setStartDate={setStartDate}
                            endDate={endDate} setEndDate={setEndDate} />
                        : ''}
            </div>
        </>
    )
}
export default PredefinedReportByChoiceNew;

function Boxcols({ index, downloadicon, text, isSelected, handleBoxClick }) {
    return (

        <div className="col-sm-2 col-xs-12 fnbox">
            <div style={{ minHeight: '120px' }} className={`box ${isSelected ? 'activebtn' : ''}`} onClick={() => handleBoxClick(index, text)}>
                <div className="square">
                    <img src={downloadicon} alt="" />
                </div>
                <div style={{ fontSize: '14px', marginTop: '10px', fontWeight: '700' }}>{text}</div>
            </div>
        </div>

    )
}

function Popup({ undue, due, setdue, setUndue, setformat, format, setcollectionDate, setdisbursedDate, collectionDate, disbursedDate, downloadReport, closePopup, popuptext, popup, setcheckedbox, startDate, setStartDate, endDate, setEndDate, fileName, setfileName }) {
    return (
        <>
            <div className="background-blur" style={{ zIndex: '2' }}></div>
            {console.log(disbursedDate)}
            <div className={`modal ${popup === true ? 'animation-top' : ''}`} tabindex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="" style={{ textAlign: 'right' }}>
                            <button sty type="button" onClick={() => closePopup()}>
                                <i className="fa fa-times fs-1"></i>
                            </button>
                        </div>
                        {popuptext !== 'Disbursed Report (Accounts)' && popuptext !== 'Income Report' && popuptext !== 'EMI Modify Report' && popuptext !== 'EMI Collection Report' && popuptext !== 'EMI Daily Hit Report' && popuptext !== 'Outstanding Due/Undue' && popuptext !== 'Rejection Report' && popuptext !== 'EMI Aligned Report' && popuptext !== 'Disbursed report (Credit)' && popuptext !== 'EMI Daily Received Report' && popuptext !== 'Daily Accounting Disbursement Report' && popuptext !== 'Apply Date Report'&&popuptext!=='Disburse Date Report' && popuptext !== "Admin Lead Report" ?
                            < div className="modal-body">
                                <p>Are you Sure you want to Download {popuptext}</p>
                                <div className="inputgrp">
                                    {/* <input type="checkbox" onChange={(e) => setcheckedbox(true)} id="inputcheckboxs" className='inputtag' />
                                        <label htmlFor="inputcheckboxs" className='labeltag'>Send Email</label> */}
                                </div>
                                <button type="button" className="btn btn-primary" onClick={() => downloadReport(popuptext)}>Download Report</button>

                            </div>
                            : popuptext !== 'Outstanding Due/Undue' && popuptext !== 'Apply Date Report' && popuptext !== 'Disburse Date Report' ?
                                <div className="modal-body datefilter">
                                    <p>Download {popuptext}</p>
                                    <div className="inputss">
                                        <div className="grp">
                                            <label htmlFor="startDate">Start Date</label>
                                            <input type="date" id='startDate' className='form-control' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                        </div>
                                        <div className="grp">
                                            <label htmlFor="EndDate">End Date</label>
                                            <input type="date" id="EndDate" className='form-control' min={startDate} value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                        </div>
                                    </div>
                                    {/* <div className="inputgrp">
                                        <input type="checkbox" onChange={(e) => setcheckedbox(true)} id="inputcheckboxs" className='inputtag' />
                                        <label htmlFor="inputcheckboxs" className='labeltag'>Send Email</label>
                                    </div> */}
                                    <button type="button" className="btn btn-primary" onClick={() => downloadReport(popuptext)}>Download Report</button>
                                </div>
                                : ""}
                        {popuptext === 'Outstanding Due/Undue' ?
                            <div className="modal-body datefilter text-left" >
                                <p className='text-center'>Download {popuptext}</p>
                                <div className='row'>
                                    <div className='col-sm-6 col-xs-12' style={{ marginTop: '10px' }}>
                                        <label > As on Disburse Date</label>
                                        <input type="date" className='form-control' value={disbursedDate} onChange={(e) => setdisbursedDate(e.target.value)} />
                                    </div>
                                    <div className='col-sm-6 col-xs-12' style={{ marginTop: '10px' }}>

                                        <label htmlFor="EndDate">As on Collection Date</label>
                                        <input type="date" className='form-control' min={disbursedDate} value={collectionDate} onChange={(e) => setcollectionDate(e.target.value)} />

                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-6 col-xs-12' style={{ marginTop: '10px' }}>
                                        <label >Select Format</label>
                                        <select value={format} onChange={(e) => setformat(e.target.value)} className='form-control'>
                                            <option value={0}>Summary report</option>
                                            <option value={1}>Detailed report</option>
                                        </select>
                                    </div>
                                    {console.log(format)}
                                    {format == 1 ?
                                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '10px' }}>
                                            <label htmlFor="EndDate">Status</label>
                                            <br />
                                            <input type="checkbox" checked={due} onChange={(e) => setdue(e.target.checked)} />&nbsp;Due<br />
                                            <input type="checkbox" checked={undue} onChange={(e) => setUndue(e.target.checked)} />&nbsp;Undue
                                        </div>
                                        : ""}
                                </div>
                                {console.log(popuptext)}
                                <div className='text-center'>
                                    <button type="button" className="btn btn-primary" onClick={() => downloadReport(popuptext)}>Download Report</button>

                                </div>

                            </div>
                            : ""}
                        {popuptext === 'Apply Date Report' || popuptext === 'Disburse Date Report' ?
                            <div className="modal-body datefilter text-left" >
                                <p className='text-center'>Download {popuptext}</p>
                                <div className='row'>
                                    <div className='col-sm-6 col-xs-12' style={{ marginTop: '10px' }}>
                                        <label > Start Date</label>
                                        <input type="date" className='form-control' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                    </div>
                                    <div className='col-sm-6 col-xs-12' style={{ marginTop: '10px' }}>
                                        <label > End Date</label>
                                        <input type="date" className='form-control' value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                    </div>
                                    <div className='col-sm-6 col-xs-12' style={{ marginTop: '10px' }}>
                                        <label > File Name</label>
                                        <input type="text" className='form-control' value={fileName} onChange={(e) => setfileName(e.target.value)} />
                                    </div>

                                </div>
                                <div className='text-center'>
                                    <button type="button" className="btn btn-primary" onClick={() => downloadReport(popuptext)}>Download Report</button>

                                </div>

                            </div>
                            : ""}
                    </div>
                </div>
            </div >

        </>
    )
}
