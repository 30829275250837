import React, {useState,useEffect} from 'react'
import { getObligationcalculatorAPI } from '../../Analysis/analysisactionCreator'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import styled from "@emotion/styled";
import InfoIcon from '@mui/icons-material/Info';

export default function ObligationCalculator({handleShowObligationPopup,allDetail,obligationAPiAgain, setObligationAPiAgain}) {
    const [data,setData] =useState({})

    useEffect(()=>{
        getObligationcalculatorFunction()
        if(obligationAPiAgain == true){
            getObligationcalculatorFunction()
            setObligationAPiAgain(false)
        }
    },[obligationAPiAgain])

    const getObligationcalculatorFunction = ()=>{
        getObligationcalculatorAPI(allDetail.userId,allDetail.loanId,0,(callback)=>{
            console.log("callbackcallback",callback)
            if(callback){
                setData(callback)
            }else{
                setData({})
            }
        })
    }

    const CustomTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            fontSize: '2rem', // Adjust the font size as needed
        },
    }));


    return (
        <>
            <div style={{ padding: '10px' }}>
                <div style={{ background: '#DBE9FF', padding: '10px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px',display:'flex',justifyContent:'space-between' }}>
                    <h5><b>Obligation Calculator </b></h5>
                   {  data?.message === "Crif Data not found, Hit crif" ?
                            <div style={{ textAlign: 'end' }}>
                                <CustomTooltip title={data?.message || 'NA'} >
                                    <InfoIcon style={{ marginLeft: '8px',marginTop:'10px', cursor: 'pointer' }} />
                                </CustomTooltip>
                            </div>
                            : 
                    <i className='fa fa-edit' style={{ marginTop: '10px', cursor: 'pointer' }} onClick={() => handleShowObligationPopup(true)}></i>
                            }
                </div>

                <table className="scoring-table">
                    <tr>
                        <td style={{ borderTop: 'none', borderRight: 'none' }}>Total Monthly Obligation</td>
                        <td style={{ borderTop: 'none', borderLeft: 'none', textAlign: 'right' }}>{data?.calculatedEmi !== null && data?.calculatedEmi !== undefined && data?.calculatedEmi !== "" ? (Number(data?.calculatedEmi))?.toFixed(2)  : "-"}</td>
                    </tr>
                    <tr>
                        <td style={{ borderTop: 'none', borderRight: 'none', borderBottomLeftRadius: '10px' }}>Date of Data Fetch</td>
                        <td style={{ borderTop: 'none', borderLeft: 'none', textAlign: 'right',borderBottomRightRadius:'10px' }}>{data?.crifHitDate || "-"}</td>
                    </tr>
                    {/* <tr>
                        <td colSpan={2} style={{ borderTop: 'none',color:'red',fontSize:'12px',  borderBottomLeftRadius: '10px',borderBottomRightRadius:'10px' }}>*Data is fetched from {(data?.dataFetchFrom)?.toUpperCase()}</td>
                    </tr> */}


                </table>



            </div >
        </>
    )
}
