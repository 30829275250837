import React, { useEffect, useState } from "react";
import { getPaymentApi, getTxnBrreakDownApi, getAllPaymentModesApi, getAllPaymentChannelApi,UploadPaymentByVerifiedStatusLogs } from "../AdminActionCreator.component";
import Pagination from '@mui/material/Pagination';
import Loader from '../../../presentationals/Loader/Loader.component';
import NodataImage from '../../../../images/nodata.png';
import moment from "moment";
import EmiBreakUp from "./breakdownpopup";
import PopUp from "../../../presentationals/Popup/Popup.component";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const Allpayments = ({ admin }) => {
    const [startDate, setStartDate] = useState(moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [applicationId, setApplicationId] = useState('');
    const [processType, setProcessType] = useState('');
    const [mode, setMode] = useState('');
    const [objectData, setObjectData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(20);
    const [loaderState, setLoader] = useState(false);
    const [payData, setpayData] = useState('');
    const [showData, setshowData] = useState('');
    const [leadData, setLeadData] = useState('');
    const [popupStatus, setpopupStatus] = useState('No Data Found!');
    const [popupState, setpopupState] = useState(false);
    const [countData, setCounts] = useState('');
    const [filterDataDsec, setfilterDataDsec] = useState(false);
    const [APIData, setAPIData] = useState({})
    const [channelData, setChannelData] = useState([])
    const [modeData, setModeData] = useState([])
    const [uploadPopup, setUploadPopup] = useState(false)
    const [uploadedFile, setUploadedFile] = useState("")
    const detailspayment = (data) => {
        console.log(data)
        setLeadData(data)
        setLoader(true)
        getTxnBrreakDownApi(data?.payments?.providerTransId, data?.payments?.loanId, callback => {
            if (callback && callback?.message == "success" && callback?.status == 200) {
                console.log(callback)
                setpayData(callback?.data)
                setshowData(true)
                setLoader(false)
            } else {
                setpopupState(true)
                setLoader(false)
                removePopup()

            }
        })
    }
    const removePopup = () => {
        setTimeout(() => {
            setpopupState(false)
        }, 5000);
    }
    const closePopup = () => {
        setshowData(false)
        setpopupState(false)
    }

    useEffect(() => {
        getAllPaymentModesApi((callback) => {
            console.log("callback", callback)
            if (callback && callback?.status == 200 && callback?.message == "success") {
                setModeData(callback?.data)
            } else {
                setModeData([])
            }
        })
        getAllPaymentChannelApi((callback) => {
            console.log("callback", callback)
            if (callback && callback?.status == 200 && callback?.message == "success") {
                setChannelData(callback?.data)
            } else {
                setChannelData([])
            }
        })
    }, [])

    useEffect(() => {

        getPaymentFuntion('', '')

    }, [startDate, endDate]);

    const getPaymentFuntion = (channelValue, modeValue) => {
        setLoader(true);
        getPaymentApi(startDate !== '' ? moment(startDate).format('DD-MM-YYYY') : '', endDate !== '' ? moment(endDate).format('DD-MM-YYYY') : "", true, channelValue, modeValue, callback => {
            console.log(callback)
            if (callback && callback?.status == 200 && callback?.message == "success") {
                setAPIData(callback?.data)
                setCounts(callback?.data?.paymentBreakupStatsPO);
                handleAPIValues(callback?.data, filterDataDsec)

            } else {
                setCounts('');
                setObjectData([]);
                setFilteredData([])
                setAPIData({})
            }

            setLoader(false);
        });
    }

    const handleAPIValues = (data, filterValue) => {
        if (filterValue == true) {
            const sortedData = [];
            const dataList = data?.paymentBreakUpDetailsPOAscList;

            if (dataList) {
                for (let i = dataList.length - 1; i >= 0; i--) {
                    sortedData.push(dataList[i]);
                }
            }

            setObjectData(sortedData);
            setFilteredData(sortedData);

        } else {
            setObjectData(data?.paymentBreakUpDetailsPOAscList);
            setFilteredData(data?.paymentBreakUpDetailsPOAscList);
        }
    }

    useEffect(() => {
        const data = objectData && objectData?.length > 0 ? objectData?.filter(payment =>
            (applicationId ? payment.applicationId && payment.applicationId.includes(applicationId) : true)
            // &&
            // (processType ? payment?.payments?.addedBy && payment?.payments?.addedBy.includes(processType) : true) &&
            // (mode ? payment?.payments?.pymntMode && payment?.payments?.pymntMode.includes(mode) : true)
        ) : [];
        setFilteredData(data);
        setCurrentPage(1);
    }, [applicationId, objectData]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData && filteredData?.length > 0 ? filteredData?.slice(indexOfFirstItem, indexOfLastItem) : [];

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };
    const uniqueStatuses = objectData !== '' ? Array.from(new Set(objectData?.map(status => status?.payments?.pymntMode))) : ""
    const processUnique = objectData !== '' ? Array.from(new Set(objectData?.map(status => status?.payments?.addedBy))) : ""

    const handleAsecFilter = (value) => {
        setfilterDataDsec(value)
        handleAPIValues(APIData, value)

    }

    const handleChannelMode = (value, type) => {
        if (type === "channel") {
            setProcessType(value)
            getPaymentFuntion(value, mode)
        } else if (type === "mode") {
            setMode(value)
            getPaymentFuntion(processType, value)
        }
    }

    const handleUpload = () => {
        setUploadPopup(!uploadPopup)
    }

    const UploadFile =()=>{
        setLoader(true)
        console.log("uploadedFileuploadedFile",uploadedFile)
        if(uploadedFile){
            UploadPaymentByVerifiedStatusLogs(uploadedFile,admin.emailId,(callback)=>{
                setLoader(false)
                console.log(callback)
                if(callback && callback?.data == "success"){
                    setpopupState(true)
                    setpopupStatus("file Uploaded")

                }else{
                    setpopupState(true)
                    setpopupStatus("Error")
                }
            })
        } else{
            setpopupState(true)
            setpopupStatus("Please Select a File")
        } 
        removePopup()
    }

    return (
        <div className="container-fluid">
            {showData ?
                <EmiBreakUp leadData={leadData} payData={payData} close={closePopup} admin={admin} editable={false} />
                : ""}
            {popupState ?
                <PopUp closePopup={closePopup} popupStatus={popupStatus} /> : ""}
            {console.log(processType)}
            {loaderState ? <Loader /> : ""}
            <h3><b>Verified Payments</b></h3>

            <div className="row" style={{ marginTop: '20px' }}>
                <div className="col-sm-2 col-xs-12">
                    <label>Start Date </label>
                    <input
                        type="date"
                        className="form-control"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                </div>
                <div className="col-sm-2 col-xs-12">
                    <label>End Date: </label>
                    <input
                        type="date"
                        className="form-control"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </div>
                <div className="col-sm-2 col-xs-12">
                    <label>Application Id</label>
                    <input
                        type="text"
                        className="form-control"
                        value={applicationId}
                        onChange={(e) => setApplicationId(e.target.value)}
                    />
                </div>
                <div className="col-sm-2 col-xs-12">
                    <label>Channel </label>
                    {/* <input
                        type="text"
                        className="form-control"
                        value={processType}
                        onChange={(e) => setProcessType(e.target.value)}
                    /> */}
                    <select value={processType}
                        onChange={(e) => handleChannelMode(e.target.value, "channel")} className="form-control" >
                        <option value=''>All</option>
                        {channelData && channelData?.length > 0 ? channelData?.map((process, i) => (
                            <option key={i} value={process}>{process}</option>
                        )) : ''}
                    </select>
                </div>
                <div className="col-sm-2 col-xs-12">
                    <label>Mode</label>
                    <select value={mode}
                        onChange={(e) => handleChannelMode(e.target.value, "mode")} className="form-control" >
                        <option value=''>All</option>
                        {modeData && modeData?.length > 0 ? modeData?.map((mode, i) => (
                            <option key={i} value={mode}>{mode}</option>
                        )) : ''}
                    </select>
                    {/* <input
                        type="text"
                        className="form-control"
                        value={mode}
                        onChange={(e) => setMode(e.target.value)}
                    /> */}

                </div>
                <div className="col-sm-2 col-xs-12">
                    <button className="btn btn-primary" style={{ marginTop: '22px', padding: '11px 10px' }} onClick={() => handleUpload()}><CloudUploadIcon style={{ margin: '5px 0 -5px 0' }} /> &nbsp;Upload Report</button>
                </div>
            </div>
            {countData && (
                <>
                    {/* <div className='row'>
                        <div className='col-sm-2 col-xs-12'>
                            <div className='shadow-card' style={{ marginTop: '30px', fontWeight: '700', minHeight: '100px' }}>
                                Total Amount <br />
                                <h4 style={{ color: 'grey', fontWeight: '700' }}>
                                    <i className='fa fa-rupee'></i>&nbsp;
                                    {Number(countData.total_amount || 0).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </h4>
                            </div>
                        </div>
                        <div className='col-sm-2 col-xs-12'>
                            <div className='shadow-card' style={{ marginTop: '30px', fontWeight: '700', minHeight: '100px' }}>
                                Principal Amount <br />
                                <h4 style={{ color: 'grey', fontWeight: '700' }}>
                                    <i className='fa fa-rupee'></i>&nbsp;
                                    {Number(countData.total_principal || 0).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </h4>
                            </div>
                        </div>
                        <div className='col-sm-2 col-xs-12'>
                            <div className='shadow-card' style={{ marginTop: '30px', fontWeight: '700', minHeight: '100px' }}>
                                Interest Amount <br />
                                <h4 style={{ color: 'grey', fontWeight: '700' }}>
                                    <i className='fa fa-rupee'></i>&nbsp;
                                    {Number(countData.total_interest || 0).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </h4>
                            </div>
                        </div>
                        <div className='col-sm-2 col-xs-12'>
                            <div className='shadow-card' style={{ marginTop: '30px', fontWeight: '700', minHeight: '100px' }}>
                                Penalty Amount <br />
                                <h4 style={{ color: 'grey', fontWeight: '700' }}>
                                    <i className='fa fa-rupee'></i>&nbsp;
                                    {Number(countData.total_penalty || 0).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </h4>
                            </div>
                        </div>
                        <div className='col-sm-2 col-xs-12'>
                            <div className='shadow-card' style={{ marginTop: '30px', fontWeight: '700', minHeight: '100px' }}>
                                Penalty GST <br />
                                <h4 style={{ color: 'grey', fontWeight: '700' }}>
                                    <i className='fa fa-rupee'></i>&nbsp;
                                    {Number(countData.total_penaltyGst || 0).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </h4>
                            </div>
                        </div>
                        <div className='col-sm-2 col-xs-12'>
                            <div className='shadow-card' style={{ marginTop: '30px', fontWeight: '700', minHeight: '100px' }}>
                                Bounce Amount <br />
                                <h4 style={{ color: 'grey', fontWeight: '700' }}>
                                    <i className='fa fa-rupee'></i>&nbsp;
                                    {Number(countData.total_bounce || 0).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </h4>
                            </div>
                        </div>

                    </div> */}
                    <div className="paymentInsightsMain" style={{ margin: '20px 0px 40px 0px' }}>
                        <div className="paymentOverviewSection outerBoxWithPadding" style={{ padding: '25px' }}>
                            {/* <div className="heading" style={{display:'flex',justifyContent:'space-between'}}>
                                <h4>Overview</h4>
                                <h4 style={{fontSize:'17px'}}>Total Counts (0)</h4>
                            </div> */}
                            <div className="PaymentBoxes">
                                <div className="tabs">
                                    <div className="subtabs" style={{ backgroundColor: '#ffe2e6', height: window.innerWidth < 1200 ? '170px' : '156px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                                            <div className="circlesIcon" style={{ backgroundColor: '#fa5a7d' }}><i class="fa fa-cart-arrow-down" aria-hidden="true"></i></div>
                                            <h5 className="bolder-font">{(countData?.paymentCount || 0)?.toLocaleString("en-US")}</h5>

                                        </div>
                                        <h4>{'₹ ' + (Number(countData.paymentAmt || 0).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}</h4>

                                        <h5>Total Amount</h5>
                                    </div>
                                </div>
                                <div className="tabs">
                                    <div className="subtabs" style={{ backgroundColor: '#fff4de', height: window.innerWidth < 1200 ? '170px' : '156px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                                            <div className="circlesIcon" style={{ backgroundColor: '#ff947a' }}><i class="fa fa-money" aria-hidden="true"></i></div>
                                            <h5 className="bolder-font">{(countData?.paymentPrincipleCount || 0)?.toLocaleString("en-US")}</h5>

                                        </div>
                                        <h4>{'₹ ' + (Number(countData.paymentPrincipleAmount || 0).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}</h4>

                                        <h5>Principal Amount</h5>
                                    </div>
                                </div>
                                <div className="tabs">
                                    <div className="subtabs" style={{ backgroundColor: '#fff1c1', height: window.innerWidth < 1200 ? '170px' : '156px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                                            <div className="circlesIcon" style={{ backgroundColor: '#dbb328' }}><i class="fa fa-money" aria-hidden="true"></i></div>
                                            <h5 className="bolder-font">{(countData?.paymentInterestCount || 0)?.toLocaleString("en-US")}</h5>

                                        </div>
                                        <h4>{'₹ ' + (Number(countData.paymentInterestAmount || 0).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}</h4>

                                        <h5>Interest Amount</h5>
                                    </div>
                                </div>
                                <div className="tabs">
                                    <div className="subtabs" style={{ backgroundColor: '#dcfce7', height: window.innerWidth < 1200 ? '170px' : '156px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                                            <div className="circlesIcon" style={{ backgroundColor: '#3dd856' }}><i class="fa fa-credit-card-alt" aria-hidden="true"></i></div>
                                            <h5 className="bolder-font">{(countData?.paymentPenaltyCount || 0)?.toLocaleString("en-US")}</h5>
                                        </div>
                                        <h4>{'₹ ' + (Number(countData.paymentPenaltyAmount || 0).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}</h4>

                                        <h5>Penalty Amount</h5>
                                    </div>
                                </div>
                                <div className="tabs">
                                    <div className="subtabs" style={{ backgroundColor: '#f4e8ff', height: window.innerWidth < 1200 ? '170px' : '156px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                                            <div className="circlesIcon" style={{ backgroundColor: '#bf83ff' }}><i class="fa fa-credit-card" aria-hidden="true"></i></div>
                                            <h5 className="bolder-font">{(countData?.paymentPrincipleCount || 0)?.toLocaleString("en-US")}</h5>
                                        </div>

                                        <h4>{'₹ ' + (Number(0).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}</h4>

                                        <h5>Penalty GST</h5>
                                    </div>

                                </div>
                                <div className="tabs">
                                    <div className="subtabs" style={{ backgroundColor: '#fdd9fa', height: window.innerWidth < 1200 ? '170px' : '156px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                                            <div className="circlesIcon" style={{ backgroundColor: '#f976ee' }}><i class="fa fa-credit-card" aria-hidden="true"></i></div>
                                            <h5 className="bolder-font">{(countData?.paymentBounceCount || 0)?.toLocaleString("en-US")}</h5>
                                        </div>

                                        <h4>{'₹ ' + (Number(countData.paymentBounceAmount || 0).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}</h4>

                                        <h5>Bounce Amount</h5>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>
                </>
            )}

            {filteredData?.length > 0 ? (
                <div className="phocket-table-new">
                    <table>
                        <thead>
                            <tr>
                                <th className="text-center">Details</th>
                                <th className="text-center">Application Id</th>
                                <th className="text-center">Transaction Id</th>
                                <th className="text-center">Transaction Amount</th>
                                <th className="text-center">EMI Amount</th>
                                <th className="text-center">Overdue Amount</th>
                                <th className="text-center">Paid Amount</th>
                                <th className="text-center">Mode</th>
                                <th className="text-center">Channel</th>
                                <th className="text-center">Process Type</th>
                                <th className="text-center" onClick={() => handleAsecFilter(!filterDataDsec)}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        Added On
                                        <div style={{ position: 'relative' }}>
                                            {
                                                filterDataDsec == false ?
                                                    <ArrowDropUpIcon style={{ cursor: 'pointer', position: 'absolute', top: '-10px', fontSize: '20px', zIndex: '2' }} onClick={() => handleAsecFilter(true)} />
                                                    :
                                                    <ArrowDropDownIcon style={{ cursor: 'pointer', position: 'absolute', top: '-10px', fontSize: '20px', zIndex: '2' }} onClick={() => handleAsecFilter(false)} />
                                            }
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems?.map((data, index) => (
                                <tr key={index}>
                                    <td className="text-center"><i onClick={() => detailspayment(data)} className="fa fa-info" style={{ cursor: 'pointer' }}></i></td>
                                    <td className="text-center">{data?.applicationId}</td>
                                    <td className="text-center">{data?.payments?.providerTransId}</td>
                                    <td className="text-center">{(data?.payments?.amount || "-")?.toLocaleString("en-US")}</td>
                                    <td className="text-center">{(data?.paymentBreakUpDetailsDO?.emiAmount || "-")?.toLocaleString("en-US")}</td>
                                    <td className="text-center">{(data?.paymentBreakUpDetailsDO?.overdueAmount || "-")?.toLocaleString("en-US")}</td>
                                    <td className="text-center">{(data?.paymentBreakUpDetailsDO?.paidAmount || "-")?.toLocaleString("en-US")}</td>

                                    <td className="text-center">{data?.payments?.pymntMode}</td>
                                    <td className="text-center">{data?.payments?.addedBy}</td>
                                    <td className="text-center">{data?.payments?.processType}</td>
                                    <td className="text-center">{data?.payments?.addedOn}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div style={{ float: 'right', marginTop: '15px' }}>
                        <Pagination
                            count={Math.ceil(filteredData?.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </div>
                </div>
            ) : (
                <p style={{ marginTop: '100px' }} className="text-center">
                    <img src={NodataImage} style={{ width: '30%' }} />
                </p>
            )}

            {
                uploadPopup ?
                    <>
                        <div className="background-blur " onClick={()=>handleUpload()}></div>
                        <div
                            className="popup-main small-popup container-fluid dbl-border"
                            style={{ marginTop: '40px', width: '90%', maxWidth: '30%',top:'20%' }}
                        >
                            <div className="popup-content">
                            {loaderState ? <Loader /> : ""}
                                <div className="inputGrp">
                                    <label htmlFor="">Upload Report</label>
                                    <input type="file" onChange={(e)=>setUploadedFile(e.target.files[0])} style={{marginTop:'20px',background:'#267dff',padding:'15px',borderRadius:'8px',width:"100%"}} accept=".xlsx, .xls"/>

<div className="text-center">
                                    <button className="btn btn-primary" style={{ marginTop: '22px', padding: '8px' }} onClick={() => UploadFile()}><CloudUploadIcon style={{ margin: '5px 0 -5px 0',fontSize:'20px' }} /> &nbsp;Upload Report</button>
</div>
                                </div>
                            </div>

                        </div>
                    </> : ''}
        </div>
    );
};

export default Allpayments;
