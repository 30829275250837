import React, { useState, useEffect } from 'react'
import Nodataimg from '../../../../images/nodata.png'
import TablePopup from './TablePopup';
import EqualizerSharpIcon from '@mui/icons-material/EqualizerSharp';
import TableViewSharpIcon from '@mui/icons-material/TableViewSharp';
import moment from "moment";
import Pagination from '@mui/material/Pagination';
import { getMonthWiseOverdueStatsAPI, getMonthWiseOverdueStatsDetailedAPI,downloadOverDueStatsReport } from './DebtListActionCreator';
import Loader from '../../../presentationals/Loader/Loader.component';
import DownloadIcon from '@mui/icons-material/Download';

const itemsPerPage = 10;
const InitalDate = (type, filterType) => {

    if (filterType == 'date') {
        if (type === 'fromDate') {
            const year = new Date().getFullYear();
            const FirstDay = moment(`${year}-01-01`);
            const fromdate = FirstDay.format('YYYY-MM-DD');
            // const fromdate = moment().subtract(1, 'month').format('YYYY-MM-DD');
            return fromdate
        } else if (type === 'toDate') {
            const todate = moment(new Date).format('YYYY-MM-DD')
            return todate
        }
    } else if (filterType == 'month') {
        const date = new Date();
        const month = date.getMonth()
        const year = date.getFullYear()
        let newmonth = Number(month);
        if (type === 'fromDate') {
            newmonth = '1'
        }
        if (type == 'toDate') {
            newmonth = ++newmonth;
        }
        const currentMonth = `${year}-${newmonth < 10 ? '0' + newmonth : newmonth}`
        return currentMonth
    }
}


export default function DebtManagement({ nbfcList, userDetailHandler }) {
    const [filterType, setfilterType] = useState('date')
    const [loader, setLoader] = useState(false)
    const [fromDate, setFromDate] = useState(InitalDate('fromDate', filterType))
    const [toDate, setToDate] = useState(InitalDate('toDate', filterType))
    const [nbfcFilter, setnbfcFilter] = useState('')
    const [tablePopup, setTablePopup] = useState(false);
    const [tablePopupData, setTablePopupData] = useState([]);
    const [popupType, setPopupType] = useState('')
    const [tableData, setTableData] = useState({});
    const [overAllData, setoverAllData] = useState({});
    const [currentValue, setCurrentValue] = useState({});
    const [currentPage, setCurrentPage] = useState({});
    const [downloadPopup, setDownloadPopup] = useState(false)
    const [report, setreport] = useState({
        downloadFrom:InitalDate('fromDate', "date"),
        downloadTo:InitalDate('toDate', "date")
    })

    const handleChange = (e, type) => {
        if (type == 'filterType') {
            setfilterType(e.target.value)
            setFromDate(InitalDate('fromDate', e.target.value))
            setToDate(InitalDate('toDate', e.target.value))
        } else if (type === "fromDate") {
            setFromDate(e.target.value)
        } else if (type === "toDate") {
            setToDate(e.target.value)
        } else if (type === "nbfc") {
            setnbfcFilter(e.target.value)
        } else if (type == 'applyfilter') {
            getMonthWiseOverdueStatsFunction()
        }
    };

    const handleTablePopup = (month, data, type) => {
        setPopupType(type)
        if (type == 'table') {
            let StartDate = fromDate;
            let endDate = toDate;
            if (filterType == 'month') {
                StartDate = String(StartDate) + '-01';
                endDate = moment(endDate, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
            }
            const formattedFromDate = moment(StartDate).format('DD-MM-YYYY');
            const formattedToDate = moment(endDate).format('DD-MM-YYYY');
            setLoader(true)
            getMonthWiseOverdueStatsDetailedAPI(formattedFromDate, formattedToDate, month, nbfcFilter, '', (callback) => {
                setLoader(false)
                if (callback && callback?.status == 200) {
                    console.log(callback)
                    setTablePopup(true)
                    const obj = {
                        month: month,
                        data: callback?.data
                    }
                    setTablePopupData(obj)
                }
            })
        } else {
            const obj = {
                month: month,
                data: data,
                totalData: overAllData?.aggregated[month]
            }
            setTablePopupData(obj)
            setTablePopup(true)
        }
    }

    const closePopup = () => {
        setTablePopup(false)
        setTablePopupData([])
        setPopupType('')
    }

    useEffect(() => {
        getMonthWiseOverdueStatsFunction()
    }, [])

    const getMonthWiseOverdueStatsFunction = () => {
        let StartDate = fromDate;
        let endDate = toDate;
        if (filterType == 'month') {
            StartDate = String(StartDate) + '-01';
            endDate = moment(endDate, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
        }
        const formattedFromDate = moment(StartDate).format('DD-MM-YYYY');
        const formattedToDate = moment(endDate).format('DD-MM-YYYY');
        setLoader(true)
        getMonthWiseOverdueStatsAPI(formattedFromDate, formattedToDate, nbfcFilter, '', (callback) => {
            setLoader(false)
            console.log(callback)
            if (callback && callback?.status == 200) {
                setoverAllData(callback?.data)
                setTableData(callback?.data?.isolatedData)
                if (Object.keys(callback.data?.isolatedData)?.length > 0) {
                    Object.entries(callback.data?.isolatedData)?.forEach(([month]) => {
                        setCurrentValue((prev) => ({
                            ...prev,
                            [month]: 1,
                        }));
                    });
                }
            }
        })
    }

    const handlePageChange = (event, value, month) => {
        setCurrentPage((prev) => ({
            ...prev,
            [month]: value
        }));
    };

    const handleDownload = () => {
        setDownloadPopup(!downloadPopup)
    }

    const handleReportChange =(e,type)=>{
        if(type === "downloadFrom"){
            setreport((prev)=>({
                ...prev,
                "downloadFrom":e.target.value

            }))
        }else if(type === "downloadTo"){
            setreport((prev)=>({
                ...prev,
                "downloadTo":e.target.value

            }))
        }
    }

    const handleReportDownload =()=>{
        const formattedFromDate = moment(report.downloadFrom).format('DD-MM-YYYY');
        const formattedToDate = moment(report.downloadTo).format('DD-MM-YYYY');
        setLoader(true)
        downloadOverDueStatsReport(formattedFromDate,formattedToDate,"","",callback=>{
            setLoader(false)
            console.log(callback)
        })
    }
    return (
        <>

            <div className="DownloadReportSection">
                <div className="row">
                    <div className="col-sm-2 col-xs-12" style={{ float: 'right', textAlign: 'end' }}>
                        <button className="btn btn-primary" style={{ marginTop: '22px', padding: '11px 10px' }} onClick={() => handleDownload()}><DownloadIcon style={{ margin: '5px 0 -5px 0' }} /> &nbsp;Download Report</button>
                    </div>
                </div>
                {
                    downloadPopup ?
                        <>
                            <div className="background-blur " onClick={() => handleDownload()}></div>
                            <div
                                className="popup-main small-popup container-fluid dbl-border"
                                style={{ marginTop: '40px', width: '90%', maxWidth: '30%', top: '20%' }}
                            >
                                <div className="popup-content">
                                    {loader ? <Loader /> : ""}
                                    <div className="row inputGrp">
                                    <div className="col-sm-6 col-xs-12" >
                                        <div className="inputGrp" style={{ marginTop: '10px' }}>
                                            <label htmlFor="from" className='labeltag'>From:</label>
                                            <input type="date" id='customDateInput' className='form-control' name="downloadFrom" value={report.downloadFrom} onChange={(e) => handleReportChange(e, 'downloadFrom')} style={{ width: '100%' }} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                        <div className="inputGrp" style={{ marginTop: '10px' }}>
                                            <label htmlFor="from" className='labeltag'>To:</label>
                                            <input type="date" min={report.downloadFrom} id='customDateInput' className='form-control' name="downloadTo" value={report.downloadTo} onChange={(e) => handleReportChange(e, 'downloadTo')} style={{ width: '100%' }} />
                                        </div>
                                    </div>

                                    </div>
                                        <div className="text-center">
                                            <button className="btn btn-primary" style={{ marginTop: '22px', padding: '8px' }} onClick={() => handleReportDownload()}><DownloadIcon style={{ margin: '5px 0 -5px 0' }} /> &nbsp;Download Report</button>
                                        </div>
                                </div>

                            </div>
                        </> : ''}
            </div>
            <div className="DebtManagement">
                {
                    loader ?
                        <Loader />
                        : ''
                }
                <div className="MonthDateFilter">
                    <div className="row">
                        <div className="col-lg-2 col-md-3 col-xs-12">
                            <div className="inputGrp" style={{ marginTop: '10px' }}>
                                <label htmlFor="from" className='labeltag'>Filter Type</label>
                                <select name="filterType" value={filterType} onChange={(e) => handleChange(e, 'filterType')} style={{ width: '100%' }} className="MonthinputTag">
                                    <option value="date">Date Wise</option>
                                    <option value="month">Month Wise</option>
                                </select>
                            </div>
                        </div>
                        {
                            filterType == 'date' ?
                                <>
                                    <div className="col-lg-2 col-md-3 col-xs-12" >
                                        <div className="inputGrp" style={{ marginTop: '10px' }}>
                                            <label htmlFor="from" className='labeltag'>From</label>
                                            <input type="date" id='customDateInput' className='MonthinputTag' name="fromDate" value={fromDate} onChange={(e) => handleChange(e, 'fromDate')} style={{ width: '100%' }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-xs-12">
                                        <div className="inputGrp" style={{ marginTop: '10px' }}>
                                            <label htmlFor="from" className='labeltag'>To</label>
                                            <input type="date" min={fromDate} id='customDateInput' className='MonthinputTag' name="toDate" value={toDate} onChange={(e) => handleChange(e, 'toDate')} style={{ width: '100%' }} />
                                        </div>
                                    </div>
                                </>
                                : ''}
                        {
                            filterType == 'month' ?
                                <>
                                    <div className="col-lg-2 col-md-3 col-xs-12" >
                                        <div className="inputGrp" style={{ marginTop: '10px' }}>
                                            <label htmlFor="from" className='labeltag'>From</label>
                                            <input type="month" id='customDateInput' className='MonthinputTag' name="fromDate" value={fromDate} onChange={(e) => handleChange(e, 'fromDate')} style={{ width: '100%' }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-xs-12" >
                                        <div className="inputGrp" style={{ marginTop: '10px' }}>
                                            <label htmlFor="from" className='labeltag'>To</label>
                                            <input type="month" min={fromDate} id='customDateInput' className='MonthinputTag' name="toDate" value={toDate} onChange={(e) => handleChange(e, 'toDate')} style={{ width: '100%' }} />
                                        </div>
                                    </div>
                                </>
                                : ''}
                        <div className="col-lg-2 col-md-3 col-xs-12" >
                            <button className='FilterApply' style={{ marginTop: '34px' }} onClick={(e) => handleChange(e, 'applyfilter')}>Apply Filter</button>
                        </div>
                        <div className="col-lg-2 col-md-3 col-xs-12" style={{ float: window.innerWidth > 1200 ? 'inline-end' : 'none' }}>
                            <div className="inputGrp" style={{ marginTop: '10px' }}>
                                <label htmlFor="nbfc" className='labeltag'>NBFC</label>
                                <select className='MonthinputTag' id="nbfc" name="nbfc" value={nbfcFilter} onChange={(e) => handleChange(e, 'nbfc')} style={{ width: '100%' }} >
                                    <option value="">All</option>
                                    {
                                        nbfcList && nbfcList?.length > 0 ? nbfcList?.map((data, index) => {
                                            return (
                                                <option value={data?.nbfcId}>{data?.name}</option>
                                            )
                                        })
                                            : ''}
                                </select>
                            </div>
                        </div>

                    </div>
                </div>

                {
                    tableData && Object.keys(tableData)?.length > 0 ?
                        Object.entries(tableData)?.map(([month, data], index) => {
                            if (data?.length == 0) {
                                return
                            }
                            const currentPageForMonth = currentPage[month] || 1;
                            const paginatedData = data.slice(
                                (currentPageForMonth - 1) * itemsPerPage,
                                currentPageForMonth * itemsPerPage
                            );

                            return (
                                <div className="tables" key={index}>
                                    <div className="row">
                                        <div className="col-sm-2 col-xs-12" style={{ height: '250px' }}>
                                            <div className="LeftBox" style={{ height: window.innerWidth > 1800 ? '385px' : '235px' }}>
                                                <div className="subbox" style={{ height: window.innerWidth > 1800 ? '385px' : '235px' }}>
                                                    <h5>{month}</h5>
                                                </div>
                                                <div className="eyeicons" style={{ flexDirection: 'column' }}>
                                                    <div className="circle-eye" onClick={() => handleTablePopup(month, data, "table")} style={{ margin: 'auto', width: window.innerWidth > 1800 ? '80px' : '60px', height: window.innerWidth > 1800 ? '80px' : '60px' }}>
                                                        <TableViewSharpIcon style={{ width: window.innerWidth > 1800 ? '50px' : '30px', height: window.innerWidth > 1800 ? '50px' : '30px' }} />
                                                        {/* <i class="fa fa-eye fa-2x" aria-hidden="true" style={{color:'#fff'}}></i> */}
                                                    </div>
                                                    <div className="circle-eye" onClick={() => handleTablePopup(month, data, "graph")} style={{ margin: 'auto', width: window.innerWidth > 1800 ? '80px' : '60px', height: window.innerWidth > 1800 ? '80px' : '60px' }}>
                                                        <EqualizerSharpIcon style={{ width: window.innerWidth > 1800 ? '50px' : '30px', height: window.innerWidth > 1800 ? '50px' : '30px' }} />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-sm-10 col-xs-12">
                                            {
                                                paginatedData?.length > 0 ?
                                                    <div className="phocket-table-new newTable" style={{ height: window.innerWidth > 1850 ? '380px' : '230px' }}>
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th className='text-center'>Details</th>
                                                                    <th className='text-center'>Debtor Name</th>
                                                                    <th className='text-center'>Overdue Amount</th>
                                                                    <th className='text-center'>Days Overdue</th>
                                                                    <th className='text-center'>EMI Due Date</th>
                                                                    <th className='text-center'>paid</th>
                                                                    {/* <th>Estimated Payoff Date</th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {paginatedData?.map((entry, index) => (
                                                                    <tr key={index}>
                                                                        <td className='text-center'><i class="fa fa-info" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => userDetailHandler(entry)}></i></td>
                                                                        <td className='text-center'>{entry?.debtorName}</td>
                                                                        <td className='text-center'>{entry?.overdueAmount?.toLocaleString("en-IN")}</td>
                                                                        <td className='text-center'>{entry?.daysOverdue}</td>
                                                                        <td className='text-center'>{entry?.emiDueDate}</td>
                                                                        <td className='text-center'>{entry?.paid == true ? 'Yes' : 'No'}</td>
                                                                        {/* <td>{moment(entry.payoffDate).format('DD-MM-YYYY')}</td> */}
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    : ''}
                                        </div>
                                    </div>
                                    <div className='' style={{ display: 'flex', justifyContent: 'flex-end', marginTop: window.innerWidth > 1850 ? '20px' : '10px' }}>
                                        <Pagination
                                            count={Math.ceil(data.length / itemsPerPage)}
                                            page={currentPageForMonth}
                                            onChange={(event, value) => handlePageChange(event, value, month)}
                                            color="primary"
                                        />

                                    </div>
                                    <hr className="hrtag" style={{ display: index + 1 == Object.keys(tableData)?.length ? 'none' : '' }} />
                                </div>
                            )
                        })
                        :
                        <p style={{ marginTop: '100px' }} className="text-center">
                            <img src={Nodataimg} style={{ width: '30%' }} />
                        </p>
                }
                {
                    tablePopup ?
                        <TablePopup tablePopupData={tablePopupData} close={closePopup} tabName={popupType === 'table' ? 'DebtManagement' : 'DebtManagementGraph'} />
                        : ''}
            </div>
        </>
    )
}
