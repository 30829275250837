import React, { useEffect, useState } from "react";
import { getExpenseTarget,deleteExpenseTargetById } from "../../targetactioncreator";
import BarChartComponent from "../BarChart";
import NodataImage from '../../../../../../images/nodata.png'
import Loader from '../../../../../presentationals/Loader/Loader.component';
import Popup from '../../../../../presentationals/Popup/Popup.component';
import moment from "moment";
const TargetList = ({ editHandler,applyFilter,fromDate,toDate,ActiveMonthFilter,applyFilterState }) => {
    // const expenseData = {
    //     "categoryId": "123",
    //     "categoryName": "Utilities",
    //     "subCategoryId": "456",
    //     "subCategoryName": "Electricity",
    //     "targetAmount": 500.0,
    //     "targetPercentageASPerAUM": 5.0,
    //     "currentAmount": 450.0,
    //     "currentPercentageASPerAUM": 4.5,
    //     "deviationAmount": 50.0,
    //     "deviationPercentageASPerAUM": 0.5,
    //     "comment": "Regular monthly payment",
    //     "active": true,
    //     id: "2"
    // };    
    const [loader, setLoader] = useState(false);
    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [expenseData, setExepensedata] = useState('')
    const [showgraph,setshowGraph] =useState(false)
    const [graphData,setGraphData] =useState([])
    const [graphHeading,setGraphHeading] =useState('')


    const targetData = ()=>{ 
        const formattedFromDate = moment(fromDate).format('DD-MM-YYYY');
    const formattedToDate = moment(toDate).format('DD-MM-YYYY');
        setLoader(true)
        getExpenseTarget(formattedFromDate,formattedToDate,callback => {
            setLoader(false)
            if(callback && callback?.status == 200 && callback?.data?.length > 0){
                setExepensedata(callback.data)
            }else{
                setExepensedata([])
            }
        })
        applyFilterState()
    }


    useEffect(() => {
        if(applyFilter == true || ActiveMonthFilter !== ''){
            targetData()
        }
    }, [applyFilter,ActiveMonthFilter])


    const handleDelete = (data) => {
        setLoader(true)
        deleteExpenseTargetById(data?.id,(callback)=>{
            setLoader(false)
            if(callback?.status == 200){
                targetData()
            }else{
                setPopup(true);
                setPopupStatus('Please try again later!');
                removePopup();
            }
        })
    };
    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    const closePopup = () => {
        setPopup(false);
    };


    const handleSelectedData =(data)=>{
        setGraphData([
            {
                name:'Target Amount',
                amount: data?.targetAmount || 0
            },
            {
                name:'Current Amount',
                amount: data?.currentAmount || 0
            },
            {
                name:'Deviation Amount',
                amount: data?.deviationAmount || 0
            },
        ])
        setGraphHeading(`Target Amount - ${data?.targetAmount || 0}`)
        setshowGraph(!showgraph)
    }

    return (
        <div className="container-fluid Executor">
             {loader ? <Loader /> : ''}
                {sidePopup ?
                <Popup popupStatus={popupStatus} closePopup={closePopup} />
                : ""}
                 {expenseData!==''&&expenseData?.length>0?
            <div className="row">
                <div className={`${showgraph ? "col-md-6 col-xs-12" : 'col-xs-12'} transition-col`}>
                    <div
                        style={{
                            background: '#f2f2f2',
                            padding: '10px',
                            marginTop: '50px',
                            borderRadius: '5px',
                            overflow: 'auto',
                            // maxWidth: '1000px'
                        }}
                    >
                        <div>
                            <table className='table-logs' style={{ marginBottom: '10px', width: '100%', borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr>
                                        <th className="text-center">View</th>
                                        {/* <th>Sub Category Name</th> */}
                                        <th className="text-center">Target Amount</th>
                                        <th className="text-center">AUM</th>
                                        <th className="text-center">Target % AUM</th>
                                        <th className="text-center">Current Amount</th>
                                        <th className="text-center">Current % AUM</th>
                                        <th className="text-center">Deviation Amount</th>
                                        <th className="text-center">Deviation % AUM</th>
                                        <th className="text-center">Comment</th>
                                        <th className="text-center">Edit</th>
                                        <th className="text-center">Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {expenseData!==''&&expenseData.length>0?expenseData.map((data,i)=>{
                                        return(
                                            <tr style={{ background: '#fff', padding: '5px', borderRadius: '5px' }}>
                                            <td className="text-center"><h5 onClick={()=>handleSelectedData(data)} style={{cursor:'pointer'}}><b>i</b></h5></td>
                                            {/* <td>{data.subCategoryName}</td> */}
                                            <td className="text-center">{data.targetAmount}</td>
                                            <td className="text-center">{data.aum}</td>
                                            <td className="text-center">{data.targetPercentageASPerAUM}%</td>
                                            <td className="text-center">{data.currentAmount}</td>
                                            <td className="text-center">{data.currentPercentageASPerAUM}%</td>
                                            <td className="text-center" style={{color: data?.deviationAmount < 0 ? 'red' : 'green'}}>{data.deviationAmount}</td>
                                            <td className="text-center" style={{color: data?.deviationPercentageASPerAUM < 0 ? 'red' : 'green'}}>{data.deviationPercentageASPerAUM}%</td>
                                            <td className="text-center">{data.comment}</td>
                                            <td className="text-center">
                                                <i
                                                    className="fa fa-pencil-square-o fa-lg"
                                                    aria-hidden="true"
                                                    onClick={() => editHandler(data)}
                                                    style={{ cursor: 'pointer' }}
                                                    aria-label="Edit Expense"
                                                    title="Edit Expense"
                                                ></i>
                                            </td>
                                            <td className="text-center">
                                                <i
                                                    className="fa fa-trash-o fa-lg"
                                                    aria-hidden="true"
                                                    onClick={() => handleDelete(data)}
                                                    style={{ cursor: 'pointer' }}
                                                    aria-label="Delete Expense"
                                                    title="Delete Expense"
                                                ></i>
                                            </td>
                                        </tr>
                                        )
                                    }):""}
                                  
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {
                    showgraph ?
                    <div className="col-md-6 col-xs-12">
                    <BarChartComponent sectionType={'expenseTarget'} Data={graphData} ActiveMonthFilter={ActiveMonthFilter} Heading={graphHeading}/>
                </div>
                :''}
            </div>
            :
            <div className="text-center" style={{marginTop: '50px'}}>
            <img src={NodataImage}  width={'40%'}/>
            </div>
            }
        </div>
    );
};

export default TargetList;
